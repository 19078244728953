import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get API_URL(): string {
        return environment.appUrl + 'api/';

    }

    static FILE_UPLOAD(): string {
        return AppUrl.API_URL + 'file-upload';
    }


    static CAREER(id?): string {
        if (id) {
            return AppUrl.API_URL + 'career/' + id;
        } else {
            return AppUrl.API_URL + 'career';
        }
    }

    /*static CAREER(): string {
      return AppUrl.UNIVERSITY_URL + 'career';
    }*/

    static JOB_OPENINGS(): string {
        return AppUrl.API_URL + 'job-opening-active';
    }


    static CAREER_COUNT(): string {
        return AppUrl.API_URL + 'career-count';
    }

    static CAREER_DETAILS(id): string {
        return AppUrl.API_URL + 'career/' + id;
    }

    static CAREER_STATUS(id): string {
        return AppUrl.API_URL + 'career-status/' + id;
    }

    static CONTACT_US(id?): string {
        if (id) {
            return AppUrl.API_URL + 'contact-us/' + id;
        } else {
            return AppUrl.API_URL + 'contact-us';
        }
    }

    /*static CONTACT_US(): string {
      return AppUrl.UNIVERSITY_URL + 'contact-us';
    }*/

    static CONTACT_US_COUNT(): string {
        return AppUrl.API_URL + 'contact-us-count';
    }

    static CONTACT_US_DETAILS(id): string {
        return AppUrl.API_URL + 'contact-us/' + id;
    }

    static CONTACT_US_STATUS(id): string {
        return AppUrl.API_URL + 'contact-us-status/' + id;
    }

    static ID_CARD(unique_id?): string {
        if (unique_id) {
            return AppUrl.API_URL + 'id-card/' + unique_id;
        } else {
            return AppUrl.API_URL + 'id-card';
        }
    }

    static TEAM(): string {
        return AppUrl.API_URL + 'team-data';
    }

    static BLOG(): string {
        return AppUrl.API_URL + 'blogs';
    }

    /*static ID_CARD(): string {
      return AppUrl.API_URL + 'issues';
    }*/

    static ID_CARD_COUNT(): string {
        return AppUrl.API_URL + 'id-card-count';
    }

    static ID_CARD_DETAILS(unique_id): string {
        return AppUrl.API_URL + 'id-card/' + unique_id;
    }

    static BLOG_DEATILS(id): string {
        return AppUrl.API_URL + 'blogs/' + id;
    }

    static ID_CARD_STATUS(unique_id): string {
        return AppUrl.API_URL + 'id-card/' + unique_id;
    }

    static BLOGBYTITLE(title: any): string {
        return AppUrl.API_URL + 'blogsbytitle/' + title;
    }

    static REGISTER_REFER(): string {
        return AppUrl.API_URL + 'register';
    }
}
