<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-lg-5 pt-lg-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">VSync –</span>
                            Best Online Meeting and Video Conferencing System
                        </h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">Experience effortless connection
                            from anywhere, anytime, with all-new Volmint’s VSync—the best Video conferencing software
                            for online meetings and webinars. Whether it’s for a business meeting, virtual event, or
                            online lecture, VSync ensures crystal-clear audio, high-quality video, and seamless
                            connectivity. It's perfect for teams and individuals with customizable options, exclusive
                            features, and security. Stay connected with real-time communication and collaboration!

                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="card banner-bg-color md:mr-0 py-5 position-relative h-100 w-100 border-0 shadow-lg d-flex justify-content-center">
                        <img src="assets/img/landing-new/argus/argus1.png" alt="Exam Proctoring System"
                             class="img-fluid"/>
                    </div>
                    <!--        <div class="position-relative">
                                <img src="assets/img/landing-new/thumbnail/college%20Group%20management%20system%20banner.jpg"
                                     class="img-fluid rounded-4" alt="Exam Proctoring Systemr Image" id="video-thumbnail">
                                <button class="play-button position-absolute" (click)="playVideo()"></button>
                                <video #videoElement class="d-none rounded-4" controls>
                                    <source src="assets/img/landing-new/cgms/cgms.webm" type="video/webm">
                                    Your browser does not support the video tag.
                                </video>
                            </div>-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus2.png" class="img-fluid" alt="Exam Proctoring System">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder">About Volmint’s VSync
                    <span class="brand-primary-text-color"> Video Conferencing Software</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">We at Volmint Technologies designed the
                    next-generation video conferencing software VSync to redefine digital communication. Crafted for
                    numerous businesses, educators, and individuals, VSync offers HD video, crystal-clear audio, and
                    robust connectivity to ensure seamless virtual interactions. Moreover, it is a safe online meeting
                    platform and webinar solution that facilitates effective distance work, connection, and
                    collaboration. With our extremely secure video conferencing platform, you can plan online meetings,
                    schedule lectures, broadcast live webinars, and improve your remote collaboration experience right
                    now.

                </p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">VSync – A Unified Platform For Online Meetings and

                    <span class="brand-primary-text-color">Webinars</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">VSync is a unified platform that brings unmatched
                    convenience and efficiency to virtual interactions. Experience the high-definition video and
                    crystal-clear voice, ensuring real-time communication. Additionally, fully secure and encrypted
                    connections keep your data safe and private.

                </p>
                <!--                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">-->
                <!--                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"-->
                <!--                         *ngFor="let item of powerfullToUse">-->
                <!--                        {{ item.text }}-->
                <!--                    </div>-->
                <!--                </div>-->


                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none mt-4">
                    <div
                        class="my-2  py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                        *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>

                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Exam Proctoring System"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <!--                <img src="assets/img/landing-new/argus/argus3.png" class="img-fluid" alt="Exam Proctoring System"></div>-->
                <img src="assets/img/landing-new/argus/Argus%20Page.webp" class="img-fluid"
                     alt="Exam Proctoring System"></div>

        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Exclusive Features of VSync – </span> Video Conferencing
                    System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">VSync comes up with advanced features to
                    enhance your video conferencing experience. The software offers several dynamic features that make
                    every interaction engaging and flawless. The software is compatible across all devices, delivering a
                    consistent experience whether you’re on a smartphone, tablet, or desktop. The software’s intuitive
                    interface makes scheduling and managing meetings effortless, saving you time and effort.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="Exam Proctoring Systems" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <img src="assets/img/landing-new/argus/argus4.webp" alt="Exam Proctoring System" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    VSync Empowers <span class="brand-primary-text-color">to Connect, </span> Communicate, and
                    Collaborate</h2>


                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    <strong> Collaborate </strong> – Foster Trust and improve communication with your hybrid workforce
                    via smooth video interaction with your colleagues or students. Share screen, attendees, files, and
                    communicate faster.

                </p>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    <strong> Record and Share </strong> - You can record, playback, and distribute your web meeting
                    records to team members who were unable to attend. Additionally, download the recordings of your
                    webinars and online meetings for offline usage.

                </p>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    <strong> Customizable Conferencing </strong> - Use our virtual meeting software to host secure web
                    conferencing meetings and manage numerous teams, and classes. Assign particular duties or schedule
                    lectures or meetings.

                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus5.png" class="ms-2 img-fluid" alt="Exam Proctoring System"/>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus6.png" class="ms-2 mt-2" alt="Exam Proctoring System"></div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why VSync is
                    <span class="brand-primary-text-color">the Demand of the Day </span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text"> With the growing demand for remote or hybrid
                    work, it is essential to have a system that provides an efficient and seamless connection among the
                    users – without worrying about regional boundaries. A well-structured Video conferencing software
                    like VSync helps to create meetings, and webinars, or schedule online lectures, VSync is a great
                    tool to enable your team to stay in touch and work together in real-time. Additionally, it addresses
                    the growing need for seamless and reliable virtual communication across all devices. Whether for
                    businesses, educators, or individuals, VSYNC is designed to meet the demands of modern connectivity
                    effortlessly.
                </p>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h2 class="text-white text-center fs-1 mt-5 fw-bold justify-text">What Makes Our VSync Software Stand Out</h2>
        <p class="text-white text-center">
            VSync online meeting software is adaptable enough to accommodate expanding video conferencing requirements
            across sectors. Use our secure web conference software to connect, communicate, and ensure business growth.

        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div
                    class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}
                </div>
                <h3 class="text-white my-4 fw-bold text-center">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
        <div class="text-center">
            <button class="btn bg-white px-5 brand-primary-text-color rounded-0 border-0 shadow-lg fw-bold hover-effect"
                    [routerLink]="'/contact'">Contact Us
            </button>
        </div>

    </div>
</div>


<div class="container-fluid bg-purple pb-70 ">
    <div class="container">
        <div class="row py-5">
            <div class="offer_feature">
                <div class="container">
                    <div class="row ">

                        <div class="col-12 col-lg-4 d-flex">
                            <div class="feature_box d-flex border  w-100 h-75 align-self-end  " >
                                <div class="align-self-center">
                                    <h1>CONFERENCES & LECTURES</h1>
                                    <ul>
                                        <li>Start New Conferences</li>
                                        <li>Start New Lecture</li>
                                        <li>Schedule Meeting</li>
                                        <li>Recurring Meeting Options</li>
                                        <li>Group Video Conferences</li>
                                        <li>Meeting Invitations
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                        <div class="col-12 col-lg-4 pt-5 pb-100 ">
                            <div class="feature_box mt-3 h-75 d-flex justify-content-center">
                                <div class="align-self-center w-100 text-start">
                                    <h1 class="ms-5 text-start">COLLABORATION
                                    </h1>
                                    <ul>
                                        <li>Screen Sharing

                                        </li>
                                        <li>Download Chats
                                        </li>
                                        <li>File Sharing
                                        </li>
                                        <li>Live Chat Integration
                                        </li>
                                        <li>Lectures Recording
                                        </li>

                                    </ul>
                                </div>

                            </div>
                            <div class="feature_box mb-5">
                                <h1 class="ms-5 text-start">SECURITY</h1>
                                <ul>
                                    <li>End-to-End Encryption</li>
                                    <li> Secure Login Access</li>
                                    <li> Data Privacy Compliance</li>
                                    <li> Role-Based Permissions</li>
                                    <li> Meeting Lock Features</li>

                                </ul>
                            </div>

                        </div>


                        <div class="col-12 col-lg-4 pb-70 pt-md-0 mt-md-0 mt-5 pt-5">
                            <div class="feature_box ">
                                <h1 >ANALYTICS</h1>
                                <ul>
                                    <li> Real-Time Performance Reports</li>
                                    <li> Attendance Tracking</li>
                                    <li> Session Duration Analytics</li>
                                    <li> Engagement Metrics</li>
                                    <li> Usage Statistics</li>

                                </ul>
                            </div>
                            <div class="feature_box d-flex h-75">
                                <div class=" align-self-center">
                                    <h1 class="ms-4">USER EXPERIENCE</h1>
                                    <ul>
                                        <li> Cross-Platform Compatibility</li>
                                        <li>HD Video and Audio Quality</li>
                                        <li> Easy-to-Use Interface</li>
                                        <li> Customizable Backgrounds</li>
                                        <li> Multi-Language Support</li>

                                    </ul>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12 col-lg-7 align-self-center py-md-5">
                <h2 class=" fs-1 fw-bolder">Explore VSync Comprehensive Modules

                    <!--  <span class="brand-primary-text-color">Argus Modules </span>-->
                </h2>
                <p class=" fs-6 text-secondary text-wrap justify-text">VSync offers a robust suite of modules to
                    streamline communication and enhance productivity. Each module is designed to meet diverse user
                    needs, providing seamless functionality and a user-friendly experience for businesses, educators,
                    and individuals alike.
                </p>
                <div class="row  ">
                    <div class="col-lg-6 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item mx-auto d-flex align-items-center p-3 rounded shadow-lg">
                            <span class="offer-icon">
                                 <i class="fa-solid fa-check"></i>
                            </span>
                            <span class="ms-4 offer-text fs-6">
                                {{ item.text }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-5 text-center text-lg-end ">
                <img src="assets/img/Argus.png" class="" alt="Exam Proctoring System">
            </div>
        </div>
        <!--  <div class="row py-5 gx-5">
              <div class="col-md-6 py-md-5">
                  <h2 class=" fs-1 fw-bolder">
                      What Our Customers Says?
                  </h2>
                  <div class="card rounded-4 g-3 mt-5 p-5">
                      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-inner">
                              <div class="carousel-item active mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms2.png" alt="Exam Proctoring System"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Kunal Sharma</h4>
                                               <span>New Delhi</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Exam Proctoring System">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">Argus has been a game-changer
                                      for our
                                      institution. The automated scheduling and real-time monitoring features have
                                      significantly reduced our administrative workload. it's a robust and reliable tool
                                      that has greatly enhanced our exam management system.
                                  </p>
                              </div>
                              <div class="carousel-item mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms1.avif" alt="Exam Proctoring System"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Shreya Rana</h4>
                                               <span>Gurugram</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Exam Proctoring System">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">Setting up exams is now a
                                      breeze, and the
                                      integrity reports give us peace of mind knowing our exams are secure. The
                                      user-friendly interface is a big plus, making it easy for both staff and students to
                                      navigate. Highly recommended for any educational institution looking to streamline
                                      its exam processes!
                                  </p>
                              </div>
                              <div class="carousel-item mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms3.jpeg" alt="Exam Proctoring System"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Siddharth Sharma</h4>
                                               <span>Noida</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Exam Proctoring System">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">We have been working with
                                      Volmint
                                      Technologies since last year and have tried their software to manage our educational
                                      institute tasks. We must say they are very innovative and helpful! We have navigated
                                      and streamlined our online exam proctoring with Argus and find it incredibly unique,
                                      accurate and supportive.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <a class="carousel-control-prev bg-purple rounded-circle h-w-40 bottom-0"
                         href="#carouselExampleIndicators" role="button"
                         data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                      </a>
                      <a class="carousel-control-next bg-purple  rounded-circle h-w-40" href="#carouselExampleIndicators"
                         role="button"
                         data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden text-black">Next</span>
                      </a>
                  </div>
                  <div class="card brand-primary-bg py-5 mt-4">
                      <p class="fs-1 fw-bold text-center color-white pt-4">Any Questions?</p>
                      <div class="d-flex justify-content-center py-5">
                          <button class="btn bg-white rounded-0 py-3 fs-5 text-black w-50 rounded-4"
                                  (click)="goTo('/contact')">Contact Us
                          </button>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 pt-5">
                  <div class=" w-100 ps-md-5">
                      <h2 class=" fs-1 fw-bolder">
                          Book Appointment Now!
                      </h2>
                      <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
                          {{ successMessage }}
                      </div>
                      <form [formGroup]="contactForm" action="" class="mt-5 w-100 rounded-4 px-3 py-4 p-md-5"
                            style="background: #F4F4F4">
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="name" class="form-control" id="name" formControlName="name"
                                     placeholder="Enter Your Name">
                              <label for="name">Name</label>
                              <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                     class="text-enrolldanger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="email" class="form-control" id="email" formControlName="email"
                                     placeholder="name@example.com">
                              <label for="email">Email</label>
                              <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="number" class="form-control" id="contact" formControlName="contact"
                                     placeholder="Enter Your Phone Number">
                              <label for="contact">Phone Number</label>
                              <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="text" class="form-control" id="subject" formControlName="subject"
                                     placeholder="Subject">
                              <label for="subject">Subject</label>
                              <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-3 rounded-3 outline-0 shadow border-0">
                              <textarea class="form-control" placeholder="Leave a message here" id="message"
                                        style="resize: none;"
                                        formControlName="message"></textarea>
                              <label for="message">Message</label>
                              <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>

                          <button class="btn rounded-pill brand-primary-bg text-white mt-5 w-100 hover-white"
                                  (click)="submit()">Submit
                          </button>
                      </form>
                  </div>
              </div>
          </div>-->
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Exam Proctoring System"
                         class="img-fluid"/>
                    <h3 class="fs-1 fw-bold">Frequently Asked Questions</h3>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What is Volmint’s VSync?
</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                VSync is a video conferencing system designed and owned by Volmint Technologies. It
                                provides seamless connectivity for remote or online video meetings and lectures. In
                                addition to hearing what others are saying, it offers the advantage of allowing one to
                                see their facial expressions and responses.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Why VSync is essential for the educational institute?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                VSYNC transforms the way educational institutions operate by enabling seamless virtual
                                communication and collaboration. With its secure, feature-rich platform, it addresses
                                the evolving needs of modern education, making learning more accessible and efficient.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What are the advantages of using VSync?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Apart from seamless communication, VSync allows numerous advantages such as
                                customization options as per your need. Additionally, you can schedule meetings or
                                lectures from the software and download them as well.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What is the process to use VSync?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                It's simple to join a VSync. All you need is a gadget that has a camera, a microphone,
                                and a video conferencing software. After that, simply use your device to join the video
                                conference at the designated time when you receive an invitation.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div>What are the charges of VSync?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint’s products are very affordable with no compromise on quality. To know more about
                                the charges and other details regarding VSync, get in touch with our customer team.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12 fs-2 color-white fw-bold mb-3 mb-md-0">
                Ready to elevate your university management to next level?
            </div>
            <div class="col-md-6 col-12 text-center">
                <span class="fs-4 fw-bold  text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class=" shadow py-5 px-4 mt-2" style="background: #dff9fe">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3"
                                   id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="brand-primary-bg border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
