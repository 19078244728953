import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-argus',
    templateUrl: './argus.component.html',
    styleUrls: ['./argus.component.scss']
})
export class ArgusComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Communication'},
        {text: 'Task Automation'},
        {text: 'Optimization'},
        {text: 'Centralized Information'},
        {text: 'Collaboration'},
        {text: 'Security'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'AI-Powered Monitoring'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Reporting and Analytics'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Customizable Exam Creation'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'User-Friendly Interface'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Multi-Device Compatibility'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Hybrid Scheduling'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Automated Proctoring'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Scalability'}

    ];
    whyChoose = [
        {
            header: 'Enhance Academic Integrity',
            text: 'Proctoring features, including real-time intervention and AI monitoring, deter cheating, ensure exam validity, protect content, and verify identities with ID verification tools.'
        },
        {
            header: 'Simplify Exam Management',
            text: 'The intuitive interface simplifies exam creation, configuration, and deployment, while our secure, scalable cloud-based platform ensures reliable access and data protection.'
        },
        {
            header: 'Workflow Management',
            text: 'Automate exam delivery, schedule in advance, and receive instant auto-graded results. Create centralized detailed reports to refine assessments and improve learning outcomes.'
        },
        {
            header: 'Reduce Administrative Burden',
            text: 'Centralize exam management by automating tasks like scheduling, delivery, & grading, allowing for personalized student feedback & eliminating the need for multiple software programs.'
        },
        {
            header: 'Dedicated Support Team',
            text: 'The team of experts is available to address technical questions, ensure a smooth exam experience, and offer cost-effective and flexible EMS software compared to other providers.'
        },
        {
            header: 'Comprehensive Reporting',
            text: 'Gain valuable insights with our detailed analytics and reporting tools. Track performance, identify trends, and make informed decisions to improve exam outcomes.'
        },
    ];
    offerings = [
        {
            text: 'Accounting Module'
        },
        {
            text: 'Academic Team Management'
        },
        {
            text: 'Group Collaboration Software'
        },
        {
            text: 'Online Examination Platform'
        },
        {
            text: 'Mobile Application'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title: Title,
        private meta: Meta,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags();
        this.schema();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "ArgusPage",
            "name": "Volmint Technology - Enroll",
            "url": "https://www.volmint.com/enroll",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",
            "description": 'Argus - Secured Exam Management System Software From Volmint',


        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What is Volmint’s Argus?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   Argus is an innovative exam proctor software, designed to streamline every aspect of the\n" +
                            "                                examination process, from scheduling and proctoring to grading and reporting. With\n" +
                            "                                features like AI monitoring, real-time interaction, and comprehensive analytics, we\n" +
                            "                                ensure a secure and efficient exam experience."
                    }
                },
                {
                    "@type": "Question",
                    "name": " Why Argus is important for educational institutes?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Argus is essential for maintaining academic integrity and optimizing productivity,\n" +
                            "                                making it a must-have for educational institutions."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Why Choose the Argus system to integrate with your organization?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " We pride ourselves on delivering top-notch service with user-friendly interfaces and\n" +
                            "                                24/7 support, ensuring that your exam management is both stress-free and effective."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Is the software customizable?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Yes, one can customize the software to fit their needs and requirements. Also, the admin\n" +
                            " can assign an invigilator, check results, and monitor student behavior through an AI mechanism."
                    }
                },
                {
                    "@type": "Question",
                    "name": " What are the three key benefits of Exam Proctor?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " <ul><li>AI-Powered Monitoring</li><li>Comprehensive Reporting and Analytics</li><li>Customizable Exam Creation</li><li>User-Friendly Interface</li></ul>"
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle('Argus - Secured Exam Management System Software From Volmint ');
        this.meta.updateTag({
            name: 'description',
            content: 'Ensure a fair examination process with Argus, an advanced exam management software. Simplify proctoring, monitor exams, and safeguard academic integrity'
        });
        this.meta.updateTag({
            name: 'keyword',
            content: 'online exam proctoring system'
        });
    }
}
