<!--<section class="banner">-->
<!--    <div class="container-fluid bg-sea-green mt-5">-->
<!--        <div class="container mt-5 pt-5">-->
<!--            <div class="row g-5">-->
<!--                <div class="col-md-6 d-flex text-center text-md-start align-items-center">-->
<!--                    <div>-->
<!--                        <h1 class="fw-bolder font-56"><span-->
<!--                            class="brand-primary-text-color">Engage, Inspire, and Convert with </span>-->
<!--                            Tailored Content Marketing Solutions</h1>-->
<!--                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we-->
<!--                            specialize in delivering comprehensive content marketing strategies that captivate your-->
<!--                            audience and drive results. From crafting audience-centric content to optimizing it for SEO,-->
<!--                            we ensure that your message resonates across diverse formats—whether blogs, videos, or-->
<!--                            social media posts. Our expert team measures performance with precision, providing insights-->
<!--                            and analytics that refine your content for maximum impact. Elevate your digital presence-->
<!--                            with content that not only engages but converts.-->
<!--                        </p>-->
<!--                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"-->
<!--                                (click)="openModal()">View Demo <i-->
<!--                            class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                        </button>-->
<!--                        <ng-template #contactFormModal>-->
<!--                            <app-contact-form [isModalOpen]="isModalOpen"-->
<!--                                              (closeModal)="closeModal()"></app-contact-form>-->
<!--                        </ng-template>-->
<!--                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">-->
<!--                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">-->
<!--                    <div-->
<!--                        class=" md:mr-0 position-relative h-100 w-75 border-0  border-radius-10">-->
<!--                        <img src="assets/img/landing-new/content-marketing/intro-content-marketing.png"-->
<!--                             alt="Image"-->
<!--                             class="img-fluid border-radius-10 w-100"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<!--<div class="container-fluid">-->
<!--    <div class="container py-5">-->
<!--        <div class="row">-->
<!--            <div class="col-md-6 m-0 p-0 text-right ani">-->
<!--                <img src="assets/img/landing-new/content-marketing/about-content-marketing.png"-->
<!--                     class="img-fluid border-radius-10"-->
<!--                     alt="Image">-->
<!--            </div>-->
<!--            <div class="col-md-6 ps-md-5 py-md-5 py-4">-->
<!--                <h2 class=" fs-1 fw-bolder"> Elevate Your Brand with-->
<!--                    <span class="brand-primary-text-color"> Complete Content Marketing Excellence</span></h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Elevate your brand to new heights with-->
<!--                    Volmint Technologies' complete content marketing excellence. Our tailored approach focuses on-->
<!--                    creating impactful, audience-driven content that resonates with your target market. From SEO-->
<!--                    optimization to diverse content formats and in-depth performance analytics, we ensure your brand's-->
<!--                    message reaches the right audience, driving engagement, trust, and conversions. Let us help you-->
<!--                    build a powerful digital presence that sets you apart and fosters lasting connections with your-->
<!--                    customers.</p>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="row mt-md-5">-->
<!--            <div class="col-md-6 py-md-5">-->
<!--                <h2 class=" fs-1 fw-bolder">Fuel Your Business Growth with-->
<!--                    <span class="brand-primary-text-color">  Dynamic Content Strategies</span></h2>-->
<!--                <p class="fs-6 text-secondary text-wrap justify-text">Fuel your business growth with Volmint-->
<!--                    Technologies' dynamic content strategies that are designed to captivate, engage, and convert. We-->
<!--                    craft tailored content that speaks directly to your audience, aligning with your brand’s voice and-->
<!--                    objectives. From SEO-driven articles to interactive media and analytics-based refinements, our-->
<!--                    approach ensures maximum reach and impact. Elevate your brand's presence with content that not only-->
<!--                    informs but drives measurable growth and long-term success. Let us empower your business with-->
<!--                    content that delivers results.-->
<!--                </p>-->
<!--                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">-->
<!--                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"-->
<!--                         *ngFor="let item of powerfullToUse">-->
<!--                        {{ item.text }}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12 d-block d-lg-none mt-4">-->
<!--                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img-->
<!--                        src="assets/img/project/check.png"-->
<!--                        alt="Image"-->
<!--                        class="img-fluid"> {{ item.text }}-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5">-->
<!--                <img src="assets/img/landing-new/content-marketing/business-content-marketing.png"-->
<!--                     class="img-fluid w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid bg-purple">-->
<!--    <div class="container">-->
<!--        <div class="row py-5">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                    Discover the game-changing features of <span class="brand-primary-text-color"> our content marketing services</span>-->
<!--                </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Our content marketing services offer-->
<!--                    game-changing features tailored to captivate your audience and elevate your brand. We deliver-->
<!--                    custom-crafted, SEO-driven content across diverse formats, ensuring it aligns with your brand's-->
<!--                    voice and resonates with your target audience. By focusing on audience needs and utilizing-->
<!--                    data-driven insights, we optimize performance to drive real engagement, increase visibility, and-->
<!--                    fuel measurable business growth. Let us transform your brand’s digital presence with content-->
<!--                    strategies that deliver impactful results.-->
<!--                </p>-->
<!--                <div class="row g-3">-->
<!--                    <div class="col-sm-4 col-6 px-2 my-2"-->
<!--                         *ngFor="let item of features">-->
<!--                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">-->
<!--                               <span>-->
<!--                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">-->
<!--                        </span>-->
<!--                            <span class="ms-2 text-secondary pt-2">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6  justify-content-md-end">-->
<!--                <img src="assets/img/landing-new/content-marketing/discover-content-marketing.png" alt="Image"-->
<!--                     class="img-fluid w-75 border-radius-10">-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                    Targeted Content Creation<span class="brand-primary-text-color">  Tailored to Your Audience</span>-->
<!--                </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">-->
<!--                    At Volmint Technologies, we specialize in targeted content creation that is meticulously crafted to-->
<!--                    resonate with your audience. Our approach goes beyond generic messaging; we delve deep into-->
<!--                    understanding your audience's preferences, pain points, and interests. By developing content that-->
<!--                    speaks directly to their needs, we foster genuine connections and enhance engagement. Our team-->
<!--                    ensures that every piece of content, whether it’s articles, videos, or social media posts, reflects-->
<!--                    your brand’s unique voice while delivering value to your audience. Experience the power of content-->
<!--                    that not only attracts but also retains and converts your target market.-->
<!--                </p>-->
<!--                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"-->
<!--                        (click)="openModal()">Book Appointment-->
<!--                    <i-->
<!--                        class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/content-marketing/Targeted-Advertising.png"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->

<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"-->
<!--                     class="w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--            <div class="col-md-6 py-5 ps-md-5">-->
<!--                <h2 class="fs-1 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Comprehensive SEO Strategies for </span> Maximum Online-->
<!--                    Visibility</h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we implement-->
<!--                    comprehensive SEO strategies designed to maximize your online visibility and drive targeted traffic-->
<!--                    to your website. Our expert team conducts thorough keyword research, optimizes on-page elements, and-->
<!--                    develops high-quality backlinks to enhance your search engine rankings. By staying abreast of the-->
<!--                    latest SEO trends and algorithm updates, we ensure your content is not only discoverable but also-->
<!--                    relevant to your audience's search intent. With our tailored approach, we focus on delivering-->
<!--                    sustainable results that boost your brand's presence, attract qualified leads, and ultimately-->
<!--                    contribute to your business growth.-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color">  Varied Content Formats for </span>-->
<!--                    Enhanced Engagement</h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">-->
<!--                    At Volmint Technologies, we understand that engaging your audience requires a diverse range of-->
<!--                    content formats. Our expertise spans various media, including compelling articles, captivating-->
<!--                    videos, eye-catching infographics, and interactive social media posts. By leveraging these varied-->
<!--                    content formats, we cater to different preferences and consumption habits, ensuring your message-->
<!--                    resonates across all platforms. This multi-faceted approach not only enhances audience engagement-->
<!--                    but also strengthens your brand's narrative, making it more relatable and impactful. Let us help you-->
<!--                    create a dynamic content strategy that captivates and retains your audience’s attention.-->
<!--                </p>-->
<!--                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"-->
<!--                        (click)="openModal()">Book Appointment-->
<!--                    <i-->
<!--                        class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/customer-engagment.jpg"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->

<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/valuable-insights.jpg"-->
<!--                     class="w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--            <div class="col-md-6 py-5 ps-md-5">-->
<!--                <h2 class="fs-1 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Insightful Performance Measurement and  </span> Analytics for-->
<!--                    Data-Driven Decisions-->
<!--                </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we believe that-->
<!--                    informed decisions drive success. Our insightful performance measurement and analytics services-->
<!--                    provide you with a comprehensive understanding of your content's effectiveness. By utilizing-->
<!--                    advanced tracking tools and metrics, we analyze audience engagement, conversion rates, and overall-->
<!--                    campaign performance. This data-driven approach allows us to identify what resonates with your-->
<!--                    audience and refine strategies accordingly, ensuring optimal results. With our expertise, you can-->
<!--                    make strategic decisions that enhance your content marketing efforts, maximize ROI, and propel your-->
<!--                    business growth.-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color">  The Crucial Role of Content Marketing </span>-->
<!--                    in Driving Business Success </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Content marketing plays a crucial role in-->
<!--                    driving business success by establishing a strong online presence and fostering meaningful-->
<!--                    connections with your target audience. By delivering valuable, relevant content, businesses can-->
<!--                    enhance brand awareness, build trust, and position themselves as industry leaders. Effective content-->
<!--                    marketing not only attracts and engages potential customers but also nurtures existing-->
<!--                    relationships, leading to increased loyalty and conversions. In today's digital landscape, a-->
<!--                    well-executed content marketing strategy is essential for staying competitive and achieving-->
<!--                    sustainable growth. By prioritizing content marketing, businesses can unlock new opportunities,-->
<!--                    optimize customer engagement, and drive long-term success.-->
<!--                </p>-->
<!--                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"-->
<!--                        (click)="openModal()">Book Appointment-->
<!--                    <i-->
<!--                        class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid brand-primary-bg mt-5">-->
<!--    <div class="container py-5">-->
<!--        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Distinguishes Our Content Marketing-->
<!--            Services? </h1>-->
<!--        <p class="text-white text-center">What distinguishes our content marketing services at Volmint Technologies is-->
<!--            our commitment to delivering tailored solutions that align with your unique business objectives. We combine-->
<!--            creativity and strategy, leveraging in-depth market research and audience insights to craft compelling-->
<!--            content that resonates. Our dedicated team of experts utilizes the latest tools and techniques to optimize-->
<!--            every piece of content for maximum impact, ensuring it reaches the right audience at the right time.-->
<!--            Additionally, our focus on measurable results allows us to continually refine and enhance your content-->
<!--            strategy, driving sustained growth and engagement. With our personalized approach, we empower your brand to-->
<!--            stand out in a competitive landscape and achieve lasting success.-->
<!--        </p>-->
<!--        <div class="row gy-5 py-5">-->
<!--            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">-->
<!--                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>-->
<!--                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>-->
<!--                <p class="text-white justify-text">{{-->
<!--                        item.text-->
<!--                    }}</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid py-5">-->
<!--    <div class="container">-->
<!--        <div class="row py-5 gx-5">-->
<!--            <div class="col-md-6 py-md-5">-->
<!--                <h2 class=" fs-1 fw-bolder"> Explore Our All-Encompassing-->
<!--                    <span class="brand-primary-text-color"> Content Marketing Services </span>-->
<!--                </h2>-->
<!--                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Explore our all-encompassing content-->
<!--                    marketing services at Volmint Technologies, where we offer a holistic approach to enhancing your-->
<!--                    brand's digital presence. Our comprehensive suite of services includes tailored content creation,-->
<!--                    strategic SEO optimization, and diverse content formats that engage your audience effectively. We-->
<!--                    leverage data-driven insights to measure performance and refine strategies, ensuring that your-->
<!--                    content not only resonates but also drives tangible results. With our expert team by your side, you-->
<!--                    can unlock the full potential of content marketing to boost brand awareness, foster customer-->
<!--                    loyalty, and achieve sustainable business growth. Let us partner with you to create impactful-->
<!--                    content that elevates your brand and drives success.</p>-->
<!--                <div class="row g-3 mt-5">-->
<!--                    <div class="col-12 px-0 my-3 "-->
<!--                         *ngFor="let item of offerings">-->
<!--                        <span>-->
<!--                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">-->
<!--                        </span>-->
<!--                        <span class="ms-2 fw-bold text-secondary">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5  justify-content-end">-->
<!--                <img src="assets/img/landing-new/content-marketing/explore-content-marketing.png"-->
<!--                     class="img-fluid border-radius-10"-->
<!--                     alt="Image">-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-md-6">-->
<!--                <div class="col-12">-->
<!--                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>-->
<!--                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>-->
<!--                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>-->
<!--                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"-->
<!--                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>-->
<!--                    </p>-->
<!--                    <button type="submit"-->
<!--                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"-->
<!--                            (click)="goTo('/faq')">-->
<!--                        View All Faqs-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6 mt-4">-->
<!--                <div class="accordion" id="faqAccordion">-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingOne">-->
<!--                            <button class="accordion-button" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                          <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                          </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                        What is content marketing, and how can it benefit my business?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                Content marketing involves creating and distributing valuable, relevant content to-->
<!--                                attract and engage your target audience. It can enhance brand awareness, build trust,-->
<!--                                improve customer loyalty, and ultimately drive conversions and sales.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingTwo">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">-->
<!--                        <span-->
<!--                            class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                             <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                 <div class="col-11">-->
<!--                                   How do you determine the right content strategy for my business?-->
<!--                                 </div>-->
<!--                             </div>-->
<!--                            </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                We begin by conducting a thorough analysis of your business goals, target audience, and-->
<!--                                industry trends. This research allows us to create a tailored content strategy that-->
<!--                                aligns with your objectives and resonates with your audience.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingThree">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                           What types of content formats do you offer?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                We provide a diverse range of content formats, including blog posts, articles, videos,-->
<!--                                infographics, social media posts, and more. This variety ensures we effectively engage-->
<!--                                your audience across different platforms and preferences.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingFour">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                          How do you measure the success of your content marketing efforts?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                We use a combination of analytics tools and key performance indicators (KPIs) to track-->
<!--                                content performance. Metrics such as website traffic, engagement rates, conversion-->
<!--                                rates, and ROI help us evaluate the effectiveness of our strategies and make data-driven-->
<!--                                adjustments.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingFive">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon">-->
<!--                                        <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                            How long does it take to see results from content marketing?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                Content marketing is a long-term strategy, and results may vary based on several-->
<!--                                factors, including industry competitiveness and current market conditions. Generally,-->
<!--                                you can expect to see noticeable improvements in brand visibility and engagement within-->
<!--                                a few months, with more significant results developing over time.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid ums-bottom-bg ps-5 mt-5">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center py-5">-->
<!--            <div class="col-md-10 col-12">-->
<!--                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>-->
<!--            </div>-->
<!--            <div class="col-md-2 col-12">-->
<!--                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"-->
<!--                        (click)="goTo('/contact')">Contact Us-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<section class="wave-section">
    <div class=" py-5 bg-sea-green ">
        <div class="container">
            <div class="row flex-column-reverse flex-md-row ">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56 d-none d-md-block"><span
                            class="text-primary"> Craft Stories That Transform </span>
                            Your Brand With Content Marketing Services
                        </h1>


                        <p class="my-4 fs-6 text-white text-wrap f-20 justify-text d-none d-md-block">
                            Create, Connect, and captivate your audience with result-oriented Content Marketing Services
                            designed to elevate your brand voice. Strengthen your online presence, and drive fruitful
                            engagement that fuels business growth.
                            . .
                        </p>
                        <h1 class="fw-bolder fs-3 d-md-none"><span
                            class="text-primary"> Craft Stories That Transform </span>
                            Your Brand With Content Marketing Services</h1>
                        <p class=" text-white text-wrap f-20 justify-text d-md-none"> Create, Connect, and captivate
                            your audience with result-oriented Content Marketing Services designed to elevate your brand
                            voice. Strengthen your online presence, and drive fruitful engagement that fuels business
                            growth.
                            .
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">Get Started <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  py-5 d-flex ">
                    <div
                        class="py-5 position-relative d-flex justify-content-md-end justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/content-marketing/intro-content-marketing.png"
                             alt="Image"
                             class="img-fluid border-radius-10 d-none d-md-block w-50"/>
                        <img src="assets/img/landing-new/content-marketing/intro-content-marketing.png"
                             alt="Image"
                             class="img-fluid w-50 mx-auto d-md-none "/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wave">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="white" fill-opacity="1"
                  d="M0,32L17.1,53.3C34.3,75,69,117,103,144C137.1,171,171,181,206,197.3C240,213,274,235,309,224C342.9,213,377,171,411,154.7C445.7,139,480,149,514,138.7C548.6,128,583,96,617,69.3C651.4,43,686,21,720,32C754.3,43,789,85,823,128C857.1,171,891,213,926,213.3C960,213,994,171,1029,154.7C1062.9,139,1097,149,1131,144C1165.7,139,1200,117,1234,122.7C1268.6,128,1303,160,1337,192C1371.4,224,1406,256,1423,272L1440,288L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
        </svg>
    </div>
</section>
<section class="" style="overflow-x:hidden;">
    <div class="container pb-4">
        <div class="row g-5 justify-content-center">
            <div class=" align-self-center">
                <div class=" p-3 md-mt-5 h-100">
                    <h2 class="text-primary md-mt-5 fw-bold">Why Content Marketing is Important for Your Business</h2>
                    <p class=" fs-6 text-secondary text-wrap justify-text">Content marketing is key to building a strong
                        online presence. By creating and sharing valuable, relevant, and engaging content, businesses
                        can attract, educate, and convert their target audience. It helps establish authority, build
                        trust, and drive sustainable growth. Whether you're looking to increase website traffic, improve
                        customer retention, or boost brand awareness, content marketing strategy plays a vital role in
                        achieving your goals.
                    </p>
                    <div style="height:10px;" class="rounded border w-25 bg-gradient"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative  pink-box p-3 text-center d-flex align-items-center justify-content-center  ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class=""> Brand Awareness</span>
                        </h3>
                        <p class="text-secondary">
                            Content marketing experts create engaging, value-driven content that builds trust, enhances
                            audience connection, and drives consistent growth, increases brand credibility and
                            recognition.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative blue-box p-3 text-center d-flex align-items-center justify-content-center ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-5.png" alt="">

                        <h3 class="fs-5 mt-3 fw-bolder">
                                            <span
                                                class="">Strengthen Online Presence  </span></h3>
                        <p class="text-secondary"> Content marketing professionals create copies by following the proper
                            keyword research and optimization to meet the SEO criteria and boost visibility on Google
                            Analytics. Content marketing professionals are creating and distributing valuable SEO-
                            friendly content that helps your business remain constant and rank high on Google Analytics,
                            ensuring a strong brand’s online presence.
                        </p>
                    </div>

                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center orange-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-64.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">Build Brand Authority, Credibility & Trust</span></h3>
                        <p class="text-secondary">Content marketing experts create informative and educational content
                            that helps develop trust and credibility among the audience, position your business as a
                            trusted authority. </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center green-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-2.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class=""> Enhance Customer Experience </span></h3>
                        <p class="text-secondary"> As a content marketing service provider, we create informative
                            content that addresses customer queries, while interactive elements keep your audience
                            engaged and connected.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative yellow-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-3.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">Boost SEO and Lead Generation </span></h3>
                        <p class="text-secondary">
                            Quality content optimized for search engines enhances your visibility, driving organic
                            traffic to your website to provide potential leads and fulfill sales targets.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative red-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class=""> Supports Long-Term Growth  </span></h3>
                        <p class="text-secondary">
                            Consistent content marketing creates lasting value, remains with the latest business trends,
                            and establishes a strong foundation for ongoing business success.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="container-fluid pt-4">
    <div class="container rounded-5 mt-5">
        <div class="row ">
            <div class="col-md-3 align-self-md-center text-center">
                <img src="assets/img/landing-new/seo/business-growth-seo.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-8 py-5">
                <h2 class="text-center  fs-1 fw-bolder">
                    <span class="text-primary">Content Marketing Services</span></h2>
                <p class=" text-secondary ">Content Marketing Strategy & Consulting, Website Content Development, Video
                    Content Writing, Blog Writing, Social Media Content Writing, Ad Copywriting, Content Optimization,
                    Email Content Writing

                </p>
                <!-- <p class="text-secondary">
                     We offer comprehensive SEO strategies based on business needs and goals. We make sure that every
                     element of your website is optimized to rank higher on search engines, from technical SEO and link
                     building to keyword research and on-page optimization. Our data-driven strategy helps you find
                     important possibilities, increase the exposure of your website, and attract more targeted visitors.
                 </p>
                 <p class="text-secondary">
                     We are not only improving your rankings, we are helping you to achieve sustainable growth and
                     success in this challenging online world. Let us handle your SEO while you are craving your business
                     credibility.

                 </p>-->
            </div>
            <!--   <div class="col-md-1 text-md-end d-flex  align-items-center ">
                   <img src="assets/img/landing-new/seo/marketing-5.png" alt="" class="img-fluid">
               </div>-->
        </div>
    </div>
</div>


<div class="container-fluid my-5">
    <div class="container ">
        <div class="row g-4 pb-4 justify-content-center mx-auto">
            <div class=" align-self-center py-4">
                <h2 class="my-2 fs-1 fw-bolder">
                                             <span
                                                 class="text-primary">Drive Engagement with Our Content Marketing Services
 </span>
                </h2>
                <p class="text-secondary">At Volmint, we offer a range of content marketing services designed to elevate
                    your brand’s presence and drive measurable results. Our expert team and right content planning
                    deliver high-quality content that engages audiences, boosts conversions, and enhances your overall
                    online visibility.
                </p>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/on-page-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Content Marketing Strategy & Consulting</h4>
                    <p class="text-secondary">We develop customized content strategies that align with your business
                        goals, ensuring consistent and improved audience engagement.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/off-page-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Website Content Development </h4>
                    <p class="text-secondary"> From homepage to product pages, we create relevant website content that
                        captivates visitors and enhances your brand’s authority.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/local-seo.png" alt="">

                    <h4 class="fw-bold mt-2 text-center ">Video Content Writing</h4>
                    <p class="text-secondary"> Our video content writing services produce engaging scripts and
                        compelling narratives that capture attention and drive conversions.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/technical-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Blog Writing</h4>
                    <p class="text-secondary"> We create insightful blogs following the best SEO practices to inform,
                        entertain, and build stronger connections with your audience.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/off-page-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Social Media Content Writing </h4>
                    <p class="text-secondary">  We develop creative posts, stories, and ads that inspire a call to action and connect with your audience highlighting your brand’s strengths.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/local-seo.png" alt="">

                    <h4 class="fw-bold mt-2 text-center ">Ad Copywriting</h4>
                    <p class="text-secondary">  We offer Ad copywriting services that design ads that connect with your target audience on every platform, turning interest into loyal customers.
                    </p>
                </div>
            </div>


            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/local-seo.png" alt="">

                    <h4 class="fw-bold mt-2 text-center ">Content Optimization</h4>
                    <p class="text-secondary"> We are using proven SEO and conversion rate optimization (CRO) tactics, creating meta titles, descriptions, and on-page text for better content optimization.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg-gradient">
    <div class="container py-5">
        <h2 class="text-white text-center fw-bold justify-text"> Blueprint of Volmint’s Content Marketing Strategy

        </h2>
        <div class="row gy-5 py-3">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container ">
        <div class="row justify-content-center py-5">
            <h2 class=" fs-1 fw-bolder  text-center">
                Popular Content Marketing Tools
            </h2>
            <p class="text-secondary">  <strong
                class="text-primary ">WordPress</strong> is the most preferred Content Management System (CMS) that supports many websites worldwide. It is known for its customizable themes, user-friendly interface, and wide range of plugins.
            </p>
            <p class="text-secondary">  <strong
                class="text-primary ">HubSpot</strong>  HubSpot is your all-in-one solution for all marketing needs, offers inbound marketing, sales, CRM, and customer service. It has several features such as email marketing, SMM, content creation, and more.

            </p>

            <p class="text-secondary">  <strong
                class="text-primary ">Grammarly</strong>  This is the most popular tool that helps to improve written content by providing spelling, punctuation, style, and grammar suggestions. It is an AI-based writing assistant and can be integrated with other tools.
           </p>



            <p class="text-secondary">  <strong
                class="text-primary ">Google Docs</strong> Google has its own cloud-based edition platform as part of G Suite. It allows users to create, edit, and collaborate on documents with other users in real-time making it ideal for remote work.

            </p>
            <p class="text-secondary">  <strong
                class="text-primary ">Yoast</strong>  Yoast is one of the most popular SEO plugins for WordPress websites. It has a number of features that help users optimize their content for search engines and improve their visibility in SERPs.


            </p>

            <p class="text-secondary">  <strong
                class="text-primary ">Google Analytics</strong> To analyze website traffic, user behavior, and other crucial metrics, Google Analytics is termed the most powerful tool for website owners. It offers pageviews, bounce rate, and conversion tracking.

            </p>

        </div>
    </div>
</div>
<div class="container-fluid ">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 col-md-6 align-self-center">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image"
                         class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="text-primary fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline text-primary">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 ">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                  <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                  </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                      What types of content are most effective for small businesses?
                                  </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Content including SEO-friendly blog posts, videos, and infographics performs well. Small businesses often find that informative, relatable, and engaging content resonates best with their audience.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span
                                                    class="fs-6 fw-bold">
                                                     <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                         <div class="col-11 text-primary">
                       How is content marketing different from traditional advertising?

                                                         </div>
                                                     </div>
                                                    </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Content marketing focuses on creating valuable, informative content to attract and engage audiences, while traditional advertising promotes products directly.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                    Why is content marketing important for SEO?

                                                                </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Content marketing helps improve SEO by creating relevant, high-quality content that attracts organic traffic. It targets specific keywords, enhances search rankings, and increases visibility, driving more users to your website.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                      How do I know what type of content my audience wants?
                                </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Conducting audience research through surveys, social media insights, and content analytics helps understand the audience’s preferences. Using tools like content planning and keyword analysis ensures you create relevant, valuable content that resonates with your audience.


                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon">
                                                                <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                       How often should I publish new content?

                                                                </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Consistency is key. Depending on your audience and content marketing strategy, publishing 2-3 times a week ensures regular engagement without overwhelming your audience.


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-3 p-lg-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to Take Your Business Growth to the Next Level?
            </div>
            <div class="col-md-6 align-self-center col-12 text-md-end">
                <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                        (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
