<section class="banner">
    <div class="container-fluid bg mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56">
                            <span class="brand-primary-text-color">LMS-</span>
                            Streamline Your Institution's Learning Efficiently</h1>
                        <p class="my-4 fs-6 text-secondary f-20 justify-text">The purpose of the cloud-based Learning Management System is
                            to
                            facilitate e-learning and increase the efficiency and productivity of academic management
                            for
                            institutions by keeping thorough records of classroom activities and improving
                            teacher-student
                            collaboration. LMS can easily develop, administer, deliver, and track online courses using
                            its
                            user-friendly interface.</p>
                        <button class="btn bg-white border-0 px-5 rounded-0 fw-bold hover-effect" (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/lms-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Learning Management System" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/lms/lms.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row ">
            <div class="col-md-6 ps-md-5 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Centralized Panel </span> To Manage Your Institute</h2>
                <p class="my-2 fs-6 text-secondary justify-text">An LMS saves organizations time and money by allowing students to
                    complete coursework at their convenience, eliminating the need for trainers, training sessions,
                    course materials, travel costs, and venue hire.</p>
                <div class="d-flex justify-content-between flex-wrap g-2">
                    <div class="my-2  px-5 py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                         *ngFor="let item of centralizedPanel">
                        <h4 class="fs-5 fw-bold text-white">{{ item.text }}</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/ims/ims5.png" alt="Learning Management System" class="img-fluid"/>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-6 mt-2 text-right">
                <img src="assets/img/landing-new/ims/ims-2.png" class="img-fluid" alt="Learning Management System">
            </div>
            <div class="col-md-6">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features</span> Of Learning Management System</h2>
                <p class="my-2 fs-6 text-secondary justify-text">A variety of businesses, including universities and higher education
                    institutions, benefit from LMS. The main purpose is knowledge management, which is collecting,
                    arranging, exchanging, and evaluating an organization's knowledge concerning its assets, records,
                    and human capital.</p>


                <div class="row g-3 pt-3">
                    <div class="col-sm-6 col-12 px-0 my-2"
                         *ngFor="let item of features">
                        <span>
                            <img src="{{item.image}}" alt="Learning Management System" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 py-5 mt-5 pt-5">
                <h2 class=" fs-1 fw-bolder">We Follow
                    <span class="brand-primary-text-color ">Four Quadrant Approach</span> In Our LMS</h2>
                <p class="my-4 fs-6 text-secondary justify-text">At Volmint Technologies, our Learning Management System (LMS)
                    utilizes a comprehensive Four Quadrant Approach to deliver a balanced and effective learning
                    experience</p>
                <button class="btn brand-primary-border bg-light-pink px-5 rounded-0 my-5 hover-white"
                        (click)="goTo('/contact')">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 mt-5 px-0 d-flex justify-content-md-end">
                <div>
                    <img src="assets/img/landing-new/lms/lms7.png" class="mt-2 img-fluid" alt="Learning Management System">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 mb-5">
    <div class="container">
        <h2 class=" fs-1 fw-bolder text-center">Fully
            <span class="brand-primary-text-color">Digital Systems</span> Incubated For You</h2>
        <p class="my-4 fs-6 text-secondary text-center justify-text">At Volmint Technologies, our Learning Management System (LMS) is
            meticulously crafted to meet your unique needs. Experience a learning environment that's perfectly tailored
            to support and elevate your educational journey.</p>
        <div class="row g-5 d-flex justify-content-center">
            <div class="mt-5 ms-5 col-md-3 col-12 bg-light-pink shadow dynamic-container" *ngFor="let item of digital">
                <p class="fs-5 text-center fw-bold brand-primary-text-color mb-0 ">{{ item.text }}</p>
                <hr class="brand-primary-text-color mt-0">
                <img src="{{item.image}}" alt="Learning Management System" class="">
            </div>
        </div>
    </div>
</div>

<section class="mt-5 pt-md-5">
<div class="container-fluid brand-primary-bg pt-5">
    <div class="container">
        <h2 class="fs-1 text-white text-center mt-5 fw-bold">What Makes Our Learning Management System Stand Out</h2>
        <p class="text-white text-center text-secondary justify-text">Volmint Technologies is a reputable ERP provider
            offering
            tailored solutions for academic and non-academic educational processes. The learning management system
            provides high security for student activities and data, with an easy-to-use interface and secure backup
            options on a cloud platform.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4 col-12" *ngFor="let item of whyChoose; let i = index">
                <!-- Number Circle -->
                <div
                    class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}
                </div>

                <!-- Heading -->
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>

                <!-- Description -->
                <p class="text-white text-secondary justify-text">
                    {{ item.text }}
                </p>
            </div>
        </div>

    </div>
</div>
</section>

<div class="container-fluid mt-5 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/lms/lms14.png" alt="Learning Management System" class="img-fluid"/>
            </div>
            <div class="col-md-6 col-12">
                <h2 class=" fs-1 fw-bolder">We provide Instant
                    <span class="brand-primary-text-color">Support 24/7</span></h2>
                <p class="text-secondary mt-2 text-wrap justify-text">At Volmint Technologies, we’re committed to ensuring your
                    learning experience is smooth and hassle-free with our round-the-clock support. Whether you need
                    assistance day or night, our team is ready to help.
                </p>
                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of support">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="Learning Management System" class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row gx-5">
            <div class="col-md-7 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Complimentary Offerings </span> From Volmint Technologies
                </h2>
                <p class=" fs-6 text-secondary text-wrap justify-text">Volmint Technology is striving to simplify the educational
                    system via the
                    convenience of innovation, to empower the future with the essence of technology. We are delivering
                    our clients and consumers complimentary services that improve user experience and efficiency in line
                    with our goal and vision.</p>
                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="Learning Management System" class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/LMS.jpg" class="img-fluid" alt="Learning Management System" style="max-width: 100%; max-height: 80%;">

            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Learning Management System" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com" class="text-decoration-underline primary-blue">support@volmint.com</a></p>
                    <button type="submit" class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is a Learning Management System (LMS) and how can it benefit my institution?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                An LMS is a cloud-based platform that streamlines e-learning, academic management, and
                                collaboration between teachers and students. It improves efficiency by managing online
                                courses, tracking progress, and centralizing resources, saving time and costs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  How does the Four Quadrant Approach enhance the learning experience?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our Four Quadrant Approach integrates e-tutorials, e-content, assessments, and
                                discussions, providing a comprehensive and balanced learning environment that caters to
                                diverse educational needs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Can I access the LMS on mobile devices?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, our LMS is optimized for mobile learning, allowing students and educators to access
                                course materials, participate in discussions, and track progress from their smartphones
                                and tablets.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What kind of support is available for LMS users?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We offer 24/7 support with quick responses, expert assistance, and reliable help to
                                ensure a smooth and uninterrupted learning experience for all users.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Are there any additional services or benefits included with the LMS?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, we provide complimentary services such as lifetime maintenance, tailored
                                assistance, regular updates, training, and tool integration to enhance your LMS
                                experience and support your institution’s needs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 ums-bottom-bg ps-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12">
                <h1 class="text-white" style="color: blue;">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-6 col-12 text-center">
                <span class="fs-4 fw-bold mb text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class="bg-light-pink shadow py-5 mt-2 px-4">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3" id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="bg-color-blue border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

