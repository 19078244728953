import {Component, ElementRef, HostListener, Renderer2, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
    selector: 'app-server-management',
    templateUrl: './server-management.component.html',
    styleUrls: ['./server-management.component.scss']
})
export class ServerManagementComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    service1 = [
        {text: 'Architecting & Designing'},
        {text: 'Disaster Recovery and Backups'},
        {text: 'Cloud Assessment'},
        {text: 'Integration'},
        {text: 'Administration'},
        {text: 'Provide 24/7 support'},
        {text: 'Security'}
    ];
    service2 = [
        {text: 'Architecting & Designing'},
        {text: 'Extended Orchestration'},
        {text: 'Continuous Integration'},
        {text: 'Continuous Deployment'},
        {text: 'SAST Integration'},
        {text: 'Pipeline integration'},
        {text: 'Validation'}
    ];
    service3 = [
        {text: 'Kubernetes'},
        {text: 'Docker'},
        {text: 'Openshift'},
        {text: 'Elastic Container Service'},
        {text: 'Google Kubernetes Engine'},
        {text: 'AWS Elastic Container Service'},
        {text: 'Azure Kubernetes Service'}
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Server Setup and Configuration'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Performance Monitoring'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Security Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Backup and Disaster Recovery'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Updates and Patch Management'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Load Balancing'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Scalability'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Remote Management'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Resource Allocation'}

    ];
    whyChoose1 = [
        {
            header: 'Architecting & Designing',
            text: 'Our cloud architects at Volmint Technologies specialize in designing cloud environments that are both scalable and secure. We tailor cloud architectures to meet your business needs, ensuring optimal performance, cost efficiency, and flexibility. From selecting the right cloud platform to designing high-availability systems, we create solutions that allow seamless integration and future scalability, empowering your business to grow effortlessly.'
        },
        {
            header: 'Disaster Recovery and Backups',
            text: 'We implement comprehensive Disaster Recovery (DR) and backup solutions to protect your critical data and applications. In the event of a system failure, natural disaster, or cyberattack, our backup strategies ensure that your data is recoverable, and your business operations can resume quickly with minimal downtime. By leveraging advanced cloud-based DR techniques, we provide automated, scalable, and cost-effective protection, keeping your infrastructure resilient and prepared for any unexpected incidents.'
        },
        {
            header: 'Cloud Assessment',
            text: 'Our Cloud Assessment services provide a thorough evaluation of your current IT environment to determine its readiness for cloud migration. We analyze performance, security, cost, and scalability to develop a roadmap that aligns with your business goals. Whether you are new to cloud computing or looking to optimize an existing infrastructure, our detailed assessments ensure you get the most out of your cloud investment.'
        },
        {
            header: 'Integration',
            text: 'We enable seamless Cloud Integration by connecting your cloud-based applications and services with your existing IT infrastructure. Our integration solutions facilitate smooth data exchange and communication between different platforms, improving efficiency and operational workflows. Whether it’s integrating cloud solutions with on-premises systems or third-party applications, our expertise ensures that your business runs efficiently without data silos or compatibility issues.'
        },
        {
            header: 'Administration',
            text: 'Our Cloud Administration services cover the ongoing management, monitoring, and optimization of your cloud infrastructure. We handle everything from configuring cloud resources to managing user access, performance tuning, and system updates. With continuous oversight, we ensure your cloud environment operates smoothly, with maximum uptime and minimum operational complexity, allowing your team to focus on core business functions',
        },
        {
            header: 'Provide 24/7 Support',
            text: 'At Volmint Technologies, we offer 24/7 Cloud Support to keep your operations running smoothly around the clock. Our dedicated support team is available at any time to address issues, resolve problems, and provide expert guidance. Whether it’s a performance issue, security concern, or system update, we’re here to ensure your cloud environment is always operational and performing at its best, minimizing downtime and disruption to your business.'
        },
        {
            header: 'Security',
            text: 'Security is at the forefront of our Managed Cloud Services. We implement robust cloud security measures to protect your data and applications from unauthorized access, breaches, and other threats. Our comprehensive security protocols include encryption, identity and access management (IAM), firewall configuration, and regular security audits. We ensure compliance with industry standards and regulations, providing your business with a secure, resilient cloud environment.'
        },
    ];
    whyChoose2 = [
        {
            header: 'Architecting & Designing',
            text: 'Our Architecting & Designing services focus on creating robust and scalable DevOps architectures tailored to your organization\'s needs. We work closely with your team to understand your goals and develop a blueprint that integrates development, operations, and quality assurance processes. By leveraging best practices and modern tools, we design workflows that enhance collaboration, streamline processes, and facilitate continuous delivery, ensuring your applications can adapt quickly to changing demands.'
        },
        {
            header: 'Extended Orchestration',
            text: 'Our Extended Orchestration services streamline and automate your software development lifecycle. By integrating various tools and processes, we enable seamless coordination between development, testing, and deployment teams. Our orchestration solutions facilitate real-time monitoring, automation of repetitive tasks, and better resource management, allowing your teams to focus on delivering value rather than getting bogged down in manual processes.'
        },
        {
            header: 'Continuous Integration',
            text: 'With our Continuous Integration (CI) services, we establish a robust framework that encourages developers to frequently integrate their code changes into a shared repository. This practice ensures that any code changes are automatically tested, allowing teams to identify and resolve issues quickly. By adopting CI, we help you enhance code quality, reduce integration problems, and accelerate the development process, leading to faster releases and improved software performance.'
        },
        {
            header: 'Continuous Deployment',
            text: 'Our Continuous Deployment (CD) services automate the release process, ensuring that every change that passes automated tests is automatically deployed to production. This allows for rapid delivery of new features and fixes to your users, enhancing responsiveness to market demands. With CD, we ensure that your software is always in a deployable state, significantly reducing the time between development and deployment, while maintaining high-quality standards.'
        },
        {
            header: 'SAST Integration',
            text: 'We offer Static Application Security Testing (SAST) Integration to identify vulnerabilities in your code during the development phase. By embedding security testing into the CI/CD pipeline, we help you detect and address potential security flaws early in the development lifecycle. Our SAST solutions enhance your application’s security posture, reduce risks, and ensure compliance with industry standards, allowing your teams to build secure applications with confidence.'
        },
        {
            header: 'Pipeline Integration',
            text: 'Our Pipeline Integration services streamline and enhance your CI/CD workflows by integrating various tools and platforms into a cohesive pipeline. We configure and optimize your development, testing, and deployment processes, ensuring smooth transitions between stages. By automating and integrating your pipelines, we improve efficiency, reduce errors, and enable faster delivery of high-quality software, empowering your teams to innovate without barriers.'
        },
        {
            header: 'Validation',
            text: 'Our Validation services ensure that your applications meet predefined quality and performance standards before reaching production. We implement automated testing strategies, including unit, integration, and user acceptance testing, to validate your software at each stage of the development lifecycle. By conducting thorough validation, we minimize the risk of defects, enhance user satisfaction, and ensure that your applications deliver the intended value and functionality.'
        },
    ];
    whyChoose3 = [
        {
            header: 'Kubernetes',
            text: 'Kubernetes is an open-source container orchestration platform that automates the deployment, scaling, and management of containerized applications. It provides a robust framework for running distributed systems resiliently, allowing developers to manage their applications across a cluster of machines easily. Kubernetes handles the scheduling of containers, provides service discovery, load balancing, scaling, and self-healing capabilities, ensuring that your applications are always available and running optimally.'
        },
        {
            header: 'Docker',
            text: 'Docker is a platform that enables developers to build, ship, and run applications in containers. Containers are lightweight, portable units that encapsulate an application and its dependencies, ensuring consistency across various environments. Docker simplifies the development lifecycle by allowing developers to create isolated environments for their applications, making it easier to manage dependencies and reduce conflicts. With Docker, teams can streamline their workflow, enhance collaboration, and accelerate deployment times.'
        },
        {
            header: 'OpenShift',
            text: 'OpenShift is a comprehensive Kubernetes-based platform developed by Red Hat that provides a complete container application platform. It offers enhanced developer experience, integrated CI/CD pipelines, and built-in security features. OpenShift simplifies the management of Kubernetes clusters by providing a user-friendly interface and tools for monitoring and scaling applications. With features like source-to-image builds, automated scaling, and multi-cloud support, OpenShift empowers teams to develop, deploy, and manage applications efficiently.'
        },
        {
            header: 'Elastic Container Service (ECS)',
            text: 'Amazon Elastic Container Service (ECS) is a fully managed container orchestration service provided by AWS. It allows users to run, manage, and scale Docker containers on the AWS cloud. ECS integrates seamlessly with other AWS services, making it easy to deploy microservices architectures and manage containerized applications. With ECS, users can take advantage of features like automatic scaling, load balancing, and service discovery while enjoying the benefits of a serverless experience with AWS Fargate, which allows for running containers without having to manage the underlying infrastructure.'
        },
        {
            header: 'Google Kubernetes Engine (GKE)',
            text: 'Google Kubernetes Engine (GKE) is a managed Kubernetes service provided by Google Cloud. GKE simplifies the deployment and management of Kubernetes clusters while offering powerful features like automated upgrades, integrated monitoring, and logging. It allows users to focus on building applications rather than managing the underlying infrastructure. GKE is optimized for performance and scalability, enabling organizations to run their containerized applications reliably and efficiently. With seamless integration into the Google Cloud ecosystem, GKE provides powerful tools for developers to streamline their workflows.'
        },
        {
            header: 'AWS Elastic Container Service (ECS)',
            text: 'AWS Elastic Container Service (ECS) is a highly scalable and high-performance container management service that supports Docker containers. It allows users to easily run applications on a managed cluster of EC2 instances or using AWS Fargate for serverless execution. ECS provides deep integration with the AWS ecosystem, including monitoring through Amazon CloudWatch, networking through Amazon VPC, and security via IAM roles. This service simplifies application deployment, scaling, and management while providing flexibility in container orchestration.'
        },
        {
            header: 'Azure Kubernetes Service (AKS)',
            text: 'Azure Kubernetes Service (AKS) is a managed Kubernetes service offered by Microsoft Azure. It simplifies the deployment and management of Kubernetes clusters, providing integrated monitoring, scaling, and security features. AKS allows organizations to deploy containerized applications on Azure with ease, leveraging features like auto-scaling, continuous integration and deployment (CI/CD), and built-in logging and monitoring through Azure Monitor. With AKS, teams can focus on building applications rather than managing the infrastructure, making it an excellent choice for enterprises looking to adopt Kubernetes in their cloud strategy.'
        },
    ];
    offerings = [
        {
            text: 'Holistic Approach'
        },
        {
            text: 'Modular Flexibility'
        },
        {
            text: 'Real-Time Insights'
        },
        {
            text: 'Enhanced Security'
        },
        {
            text: 'Scalability and Adaptability'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.schema();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "ServerManagementPage",
            "name": "Volmint Technology - Server-Management",
            "url": "https://www.volmint.com/server-management",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };


        // FAQ Schema
        const faqSchema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [
                {
                    '@type': 'Question',
                    name: 'What are Cloud & DevOps Services?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' Cloud & DevOps Services encompass a set of practices and technologies that enable\n' +
                            '                                organizations to automate and optimize their software development and deployment\n' +
                            '                                processes while leveraging cloud infrastructure for improved scalability, flexibility,\n' +
                            '                                and efficiency.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' How can Managed Cloud Services benefit my organization?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' Managed Cloud Services provide expert management of your cloud infrastructure, ensuring  optimal performance, security, and cost-effectiveness. This allows your team to focus on   core business activities while we handle cloud architecture, integration,  administration, and ongoing support.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'What is the role of DevOps in software development?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' DevOps bridges the gap between development and operations teams, fostering collaboration  and continuous communication. This approach accelerates the software development   lifecycle, enhances product quality, and enables quicker release cycles, allowing  businesses to respond rapidly to market demands.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' What are containers, and why should we use them?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: '  Containers are lightweight, portable units that package applications and their   dependencies, ensuring consistent performance across different environments. Using  container technologies like Docker and Kubernetes enables easier deployment,   scalability, and management of applications, streamlining your development processes.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' How do you ensure the security of our cloud environment?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: '   We implement robust security measures, including encryption, firewalls, and access\n' +
                            '                                controls, to protect your cloud infrastructure. Our security protocols are continuously\n' +
                            '                                updated to address emerging threats, ensuring the integrity and confidentiality of your\n' +
                            '                                data.'
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const elements = document.querySelectorAll('.animate-on-scroll');
        const scrollPosition = window.innerHeight + window.scrollY;

        elements.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            if (scrollPosition >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });

        const elements1 = document.querySelectorAll('.animate-on-scroll-right');
        const scrollPosition1 = window.innerHeight + window.scrollY;
        elements1.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            if (scrollPosition1 >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });
    }

}
