<div class="container-fluid bg-pink mt-5">
    <div class="container py-3">
        <div class="row g-5 flex-column-reverse flex-md-row">
            <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                <div>
                    <h1 class="fw-bolder font-56">
                        <span class="brand-primary-text-color">V-calling</span>
                        Revolutionizing Your Communication Experience
                    </h1>
                    <p class="my-4 fs-6 text-secondary f-20 justify-text">Welcome to the future of seamless
                        communication with
                        V-Calling, the latest innovation from Volmint Technologies. Designed to redefine the way you
                        connect, V-Calling is your all-in-one solution for crystal-clear voice calls,
                        integrating cutting-edge technology with user-friendly features. Whether you’re looking to
                        enhance team collaboration, streamline customer interactions, or simply enjoy a high-quality
                        call experience, V-Calling provides unmatched reliability and performance.
                    </p>
                    <button class="btn bg-white border px-5 rounded-0 fw-bold border-0 shadow-lg hover-effect"
                            (click)="openModal()">View Demo <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                    </button>
                    <ng-template #contactFormModal>
                        <app-contact-form [isModalOpen]="isModalOpen" (closeModal)="closeModal()"></app-contact-form>
                    </ng-template>
                    <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                        <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6 align-self-center">
                <div class="card md:mr-0 overflow-hidden position-relative">
                    <video src="assets/img/landing-new/vcalling/vcalling.mp4" autoplay loop></video>
<!--                    <img src="assets/img/landing-new/vcalling/vcalling3.png" alt="VoIP Phone System" class="img-fluid"/>-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-5 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-12" *ngFor="let item of switch">
                <div class="card border-0 image-container shadow" style="">
                    <img src="{{item.image}}" class="card-img-top" alt="VoIP Phone System">
                    <div class="card-body">
                        <h4 class="fs-6 card-title fw-bold text-center">{{ item.title }}</h4>
                        <p class="card-text text-secondary text-wrap justify-text">{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-12 col-md-6 d-flex align-items-center">
                <img src="assets/img/landing-new/vcalling/vcalling3.1.jpg" alt="VoIP Phone System" class="img-fluid"/>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <h2 class="fs-1 fw-bolder mb-4 ">
                    Get Started with V-Calling in
                    <span class="brand-primary-text-color">4 Easy Steps</span>
                </h2>
                <div class="row gx-3 gy-4">
                    <div
                        class="col-12 step-card py-2 d-flex align-items-center shadow-sm"
                        *ngFor="let item of steps">
                        <span class="step-icon-container me-3">
                            <i [ngClass]="item.image" class="step-icon "></i>
                        </span>
                        <div>
                            <h3 class="fs-5 fw-bold mb-2">
                                {{ item.heading }}
                            </h3>
                            <p class="text-secondary text-wrap  mb-0">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-5 pt-md-5">
    <div class="container brand-primary-bg p-5 border-0 shadow-lg">
        <div class="row mt-md-5">
            <div class="col-md-6 col-12">
                <h2 class="fs-1 color-white fw-bold">Sign up now!</h2>
                <p class="color-white fs-6 fw-bold justify-text">Experience V-Calling with no upfront commitment—sign up
                    now
                    for a free trial and explore our features risk-free.
                </p>
                <p class="color-white my-4 fs-6 fw-bold" (click)="goTo('/contact')"><u>Start Trial <i
                    class="fas fa-arrow-right"></i></u></p>
            </div>
            <div class="col-md-6 col-12 position-relative d-none d-md-block">
                <div class="image-position">
                    <img src="assets/img/landing-new/vcalling/girl.png" alt="VoIP Phone System" class="img-fluid"/>
                </div>
            </div>
            <div class="col-md-6 col-12 d-block d-md-none text-center">
                <img src="assets/img/landing-new/vcalling/girl.png" alt="VoIP Phone System" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>
<section class="solutions">
    <div class="container-fluid mt-3 pt-md-5">
        <div class="container">
            <div class="d-flex justify-content-center">
                <video #videoElement class=" rounded-4" style="height: 141px;" autoplay muted playsinline>
                    <source src="assets/img/project/project-logo/v-calling.mp4" type="video/webm">
                    Your browser does not support the video tag.
                </video>
            </div>
            <h2 class="fs-1 fw-bolder text-center">Transform Your Lead Management and Support
                <span class="brand-primary-text-color"> with V-Calling</span>
            </h2>
            <div class="row button-row mb-4">
                <div *ngFor="let button of buttons" class="col-md-2 col-12 p-3 button-container"
                     (click)="changeDesign(button.key)">
                    <div class="row flex-grow-1">
                        <div class="col-9 text-start">
                            <button class="fw-bold rounded-3 btn-border btn-hover p-2 shadow-lg">{{ button.label }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-5 d-flex justify-content-center" *ngFor="let item of currentDesign; let i = index">
                <div class="col-md-6 col-12">
                    <img src="{{item.image}}" alt="VoIP Phone System" class="img-fluid rounded-4"/>
                </div>
                <div class="col-md-6 col-12 ">
                    <div class=" mt-md-5 pt-lg-5">
                        <p class="fs-5 text-secondary mt-md-4 justify-text">{{ item.description }}</p>
                    </div>
                    <p class="fs-6 mt-5 brand-primary-text-color fw-bold hover-effect" (click)="goTo('/contact')">Learn
                        more <i class="fa fa-arrow-right"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid mt-5">
    <div class="container">
        <div class="row g-4">
            <div class="col-md-6 col-12 bg-integrate p-5">
                <div class="d-flex justify-content-start">
                    <img src="assets/img/project/project-logo/v-calling.png" alt="VoIP Phone System"
                         class="img-fluid h-25 w-25"/>
                </div>
                <h4 class="fs-3 fw-bold my-5 justify-text">Seamlessly Integrate V-Calling with Your CRM and Ticketing
                    Systems</h4>
            </div>
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/vcalling/vcalling10.jpg" alt="VoIP Phone System"
                     class="img-fluid h-100"/>
            </div>
        </div>
    </div>
</div>
<section class="testimonial">
    <div class="container-fluid mt-5 pt-md-5">
        <h2 class="fs-1 fw-bolder text-center">Testimonials From Our Satisfied Customers</h2>
        <div class="container brand-primary-bg mt-4 p-4 shadow-lg border-0">
            <div class="row">
                <div class="col-md-6 col-12">
                    <img src="assets/img/landing-new/vcalling/vcalling11.png" alt="VoIP Phone System"
                         class="img-fluid w-75"/>
                </div>
                <div class="col-md-6 col-12 mt-lg-5">
                    <div id="testimonialCarousel" class="carousel slide" data-bs-ride="carousel"
                         data-bs-interval="3000">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="stars mt-lg-5">
                                    <div class="col-lg-4 col-12">
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                    </div>
                                </div>
                                <p class="fs-5 color-white mt-4 justify-text">V-Calling has transformed our customer
                                    support
                                    experience! The Cloud Contact Center integration makes managing inquiries from
                                    multiple channels effortless, and the performance analytics help us continually
                                    improve. Highly recommended!
                                </p>
                                <p class="mt-4 color-white fs-6">Chethan K.</p>
                                <p class="color-white fs-6">CEO</p>
                            </div>

                            <div class="carousel-item">
                                <div class="stars mt-lg-5">
                                    <div class="col-lg-4 col-12">
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                    </div>
                                </div>
                                <p class="fs-5 color-white mt-4  justify-text">We’ve seen a significant boost in our
                                    sales
                                    productivity thanks to the Auto Sales Dialer. The seamless integration with our CRM
                                    has streamlined our outreach process, making it easier to hit our targets.
                                </p>
                                <p class="mt-4 color-white fs-6">Ashutosh P.</p>
                                <p class="color-white fs-6">Customer Relations Manager</p>
                            </div>

                            <div class="carousel-item">
                                <div class="stars mt-lg-5">
                                    <div class="col-lg-4 col-12">
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                        <img src="assets/img/landing-new/vcalling/vcalling-star.webp"
                                             alt="VoIP Phone System"
                                             class="img-fluid"/>
                                    </div>
                                </div>
                                <p class="fs-5 color-white mt-4 justify-text">The Conversational AI feature is
                                    impressive! It
                                    handles customer queries with human-like interaction, freeing up our team to focus
                                    on more complex issues. V-Calling’s intuitive interface and reliability make it a
                                    top choice for our business.
                                </p>
                                <p class="mt-4 color-white fs-6">Karthik R.</p>
                                <p class="color-white fs-6">Director</p>
                            </div>
                        </div>
                        <div class="d-none d-lg-block">
                            <button class="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel"
                                    data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#testimonialCarousel"
                                    data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid mt-5 pt-md-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="VoIP Phone System" class="img-fluid"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>

                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #6752A9;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does V-Calling improve my team’s communication?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                V-Calling enhances team communication by offering crystal-clear voice and audio calls,
                                easy integration with CRM systems, and a user-friendly interface. Its advanced features,
                                like the VoIP Phone System and Cloud Contact Center, ensure seamless connectivity and
                                efficient collaboration.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Can I try V-Calling before committing?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes! We offer a free trial of V-Calling so you can explore its features and see how it
                                fits your needs without any upfront cost. Sign up now to start your risk-free trial!
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How do I integrate V-Calling with my existing CRM and ticketing systems?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Integrating V-Calling with your CRM and ticketing tools is straightforward. Our system
                                supports seamless integration, allowing you to synchronize contacts and streamline
                                workflows effortlessly.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div>  &nbsp;What types of businesses can benefit from V-Calling?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                V-Calling is designed to support a wide range of businesses and sectors. Whether you’re
                                in sales, or customer support, or need cloud telephony solutions, V-Calling offers
                                tailored features like the Auto Sales Dialer and Conversational AI to enhance your
                                operations.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How do I track and optimize my communication performance with V-Calling?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                V-Calling provides comprehensive analytics tools that help you monitor call performance
                                and user engagement. Use these insights to make data-driven decisions and continuously
                                improve your communication strategies.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 ums-bottom-bg ps-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12">
                <h2 class="fw-bolder font-56   color-blue">
                    Ready to elevate your university management to next level?
                </h2>

            </div>
            <div class="col-md-6 col-12 text-center">
                      <span class="fs-4 fw-bold    color-blue">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class="bg-light-pink shadow py-5 px-4">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3"
                                   id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="bg-color-blue border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
