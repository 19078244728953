import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {ReferService} from '../../../shared/services/refer.service';
import {GlobalService} from '../../../shared/services/global.service';
import {environment} from "../../../../environments/environment";
import {StorageService} from "../../../shared/services/storage.service";

@Component({
    selector: 'app-refer-and-earn',
    templateUrl: './refer-and-earn.component.html',
    styleUrls: ['./refer-and-earn.component.scss']
})
export class ReferAndEarnComponent implements OnInit {
    starGroups = [
        {text: 'Promote more, earn more—unlock limitless possibilities!', stars: 5},
        {text: 'Unlimited earning potential awaits you', stars: 4},
        {text: 'Awesome products for promotion', stars: 3}
    ];
    isModalVisible = false;
    userForm: FormGroup;
    showLoginBtn = false;
    user: any;

    constructor(private fb: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private alertService: AlertService,
                private referService: ReferService,
                private globalService: GlobalService) {
        this.userForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            email: ['', [Validators.required, Validators.email]],
            contact: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
            address: ['', [Validators.required]],
            referred_by_code: [''],
        });
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        console.log(this.user?.user_type);
        this.route.params.subscribe(params => {
            if (params?.referred_by_code) {
                this.userForm.patchValue({referred_by_code: params.referred_by_code});
                this.openModal();
            }
        });
    }

    openModal() {
        this.isModalVisible = true;
        setTimeout(() => {
            document.querySelector('.modal-overlay')?.classList.add('show');
        }, 10);
    }

    closeModal() {
        document.querySelector('.modal-overlay')?.classList.remove('show');
        setTimeout(() => {
            this.isModalVisible = false;
        }, 300);
    }


    onSubmit() {
        if (this.userForm.valid) {
            console.log('Form Submitted!', this.userForm.value);
            const params = {
                name: this.userForm && this.userForm.getRawValue() && this.userForm.getRawValue().name && this.userForm.getRawValue().name,
                email: this.userForm && this.userForm.getRawValue() && this.userForm.getRawValue().email && this.userForm.getRawValue().email,
                contact: this.userForm && this.userForm.getRawValue() && this.userForm.getRawValue().contact && this.userForm.getRawValue().contact,
                address: this.userForm && this.userForm.getRawValue() && this.userForm.getRawValue().address && this.userForm.getRawValue().address,
                referred_by_code: this.userForm && this.userForm.getRawValue() && this.userForm.getRawValue().referred_by_code && this.userForm.getRawValue().referred_by_code,
            };
            this.referService.add(params).subscribe((data: any) => {
                if (data && data?.user_type === 'REFERRAL') {
                    this.showLoginBtn = true;
                    this.closeModal();
                    this.alertService.showSuccess({title: 'Success', message: 'User registered successfully'});
                    this.goTo(`${environment.adminWebUrl}auth/login`);
                }
            }, error => {
                if (error && error?.error && error?.error.errors && error?.error.errors.name) {
                    this.alertService.showErrors(error?.error.errors.name[0]);
                } else if (error && error?.error && error?.error.errors && error?.error.errors.email) {
                    this.alertService.showErrors(error?.error.errors.email[0]);
                } else if (error && error?.error && error?.error.errors && error?.error.errors.contact) {
                    this.alertService.showErrors(error?.error.errors.contact[0]);
                } else {
                    this.alertService.showErrors('SomeThing went wrong');
                }
            });

        } else {
            console.log('Form is invalid!');
        }
    }

    goTo(url: any) {
        window.open(url, '_blank');
    }

    protected readonly environment = environment;
}
