<div class="page-title-area item-bg1">
    <app-background-animation></app-background-animation>
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">Contact Us</p>
            <h1 class="fs-1 color-white text-center fw-bold glowing-sub-heading">Reach Out to Us</h1>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row gx-4">
            <div class="col-12 col-md-6 mt-md-2">
                <div class="row">
                    <img src="assets/img/landing-new/contact-us/contact.jpg" alt="Reach out" class="img-fluid rounded-5"/>
                </div>
                <div class="row mt-3">
                    <div class="col-5">
                        <img src="assets/img/landing-new/contact-us/contact-two.jpg" alt="Reach out"
                             class="img-fluid rounded-4 h-75 w-100"/>
                    </div>
                    <div class="col-7">
                        <img src="assets/img/landing-new/contact-us/contact-three.png" alt="Reach out"
                             class="img-fluid w-100 h-75 rounded-4"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h2 class="fs-1 fw-bold">We’re here —reach out with your issues and let’s solve them together!</h2>
                <p class="text-secondary text-wrap mb-4">Volmint Technologies is eager to help transform tech
                    challenges
                    into triumphs by offering innovative solutions, support, and collaboration opportunities.
                </p>
                <hr/>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/contact-four.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="align-self-center col-8">
                                <h5 class="fs-6 text-secondary">Quick Response Rate</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/contact-five.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="align-self-center col-8">
                                <h5 class="fs-6 text-secondary">100% Success Rate</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/contact-six.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="align-self-center col-8">
                                <h5 class="fs-6 text-secondary">Solution Oriented Approach</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/contact-seven.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="align-self-center col-8">
                                <h5 class="fs-6 text-secondary">24/7 Availability</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-md-4">
    <div class="container">
        <div class="row g-4">
            <div class="col-12 col-md-4 bg-light-pink rounded-4 p-4">
                <h2 class="fw-bold fs-1 mt-2">Let's Get in Touch</h2>
                <p><span class="text-secondary">You can reach us anytime via:</span> <a
                    href="mailto:support@volmint.com" class="text-decoration-underline primary-blue fw-bold">support@volmint.com</a>
                </p>
                <div *ngIf="successMessage" class="alert alert-success mb-4 fw-bold">
                    {{ successMessage }}
                </div>
                <form [formGroup]="contactForm" class="">
                    <div class="mb-4">
                        <input type="name" class="form-control bg-white rounded-3 text-secondary py-3" id="name"
                               formControlName="name"
                               placeholder="Enter Your Name" required>
                        <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-4">
                        <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                               id="email" formControlName="email"
                               placeholder="Enter Your Email" required>
                        <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-4">
                        <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                               id="contact" formControlName="contact"
                               placeholder="Enter Your Phone No." required>
                        <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-3">
                        <input type="text" id="subject"
                               class="form-control rounded-3 bg-white text-secondary py-3" placeholder="Subject"
                               formControlName="subject" required>
                        <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-4">
                        <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                  formControlName="message"
                                  rows="4" style="resize: none;"
                                  placeholder="Your Message" required></textarea>
                        <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="text-center mt-4">
                        <button type="submit"
                                class="bg-color-blue border-0 rounded-4 color-white px-5 py-2 w-100 fs-6 fw-bold hover-effect"
                                (click)="submit()">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-12 col-md-2 bg-light-pink rounded-4 offset-md-1 p-4">
                <div class="row h-100">
                    <div class="col-12">
                        <p class="fs-1 text-center fw-bold">2Y+</p>
                        <p class="primary-blue text-center mb-2 fw-bold">Experience</p>
                        <hr/>
                    </div>
                    <div class="col-12">
                        <p class="fs-1 text-center fw-bold">15+</p>
                        <p class="primary-blue text-center mb-2 fw-bold">Products</p>
                        <hr/>
                    </div>
                    <div class="col-12">
                        <p class="fs-1 text-center fw-bold">2Y+</p>
                        <p class="primary-blue text-center mb-2 fw-bold">Services</p>
                        <hr/>
                    </div>
                    <div class="col-12">
                        <p class="fs-1 text-center fw-bold">10+</p>
                        <p class="primary-blue text-center mb-2 fw-bold">Expert Team</p>
                        <hr/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 bg-light-pink rounded-4 offset-md-1 p-4">
                <h2 class="fs-1 fw-bold">Contact Info</h2>
                <div class="row h-75">
                    <div class="col-12 mt-4">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/location.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="col-8">
                                <p class="fs-5 fw-bold">Address:</p>
                                <a target="_blank" href="https://www.google.com/maps/place/Volmint+Technologies/@28.6064602,77.0809699,15z/data=!4m6!3m5!1s0x390d1bf854143ecb:0x40321da1ddfa8234!8m2!3d28.6064602!4d77.0809699!16s%2Fg%2F11tc4n56__?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D">
                                    <p class="text-secondary">29/2, Vijay Enclave, Dwarka-Palam Road,
                                        Delhi-110045</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/email.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="col-8">
                                <p class="fs-5 fw-bold">Email:</p>
                                <p class="text-secondary">
                                    <a href="mailto:support@volmint.com">support@volmint.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div class="row">
                            <div class="col-2">
                                <img src="assets/img/landing-new/contact-us/contact.png" alt="Reach out"
                                     class="img-fluid"/>
                            </div>
                            <div class="col-8 mb-5">
                                <p class="fs-5 fw-bold">Phone Number:</p>
                                <a href="tel:+91 9310828501" class="text-secondary">
                                    <p>+91 9310828501</p>
                                </a>

                            </div>
                        </div>
                        <hr class="custom-hr text-secondary"/>
                    </div>
                    <div class="col-12 ">
                        <div class="row">
                            <div class="col-6">
                                <div class="row ">
                                    <div class="col-5 d-flex justify-content-end">
                                        <a href="https://www.facebook.com/VolmintTechnologies?mibextid=ZbWKwL"
                                           target="_blank">
                                            <img src="assets/img/landing-new/contact-us/facebook.png" alt="Reach out"
                                                 class=""/>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.facebook.com/VolmintTechnologies?mibextid=ZbWKwL"
                                           target="_blank">
                                            <p class="fs-6">Facebook</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-5 d-flex justify-content-end">
                                        <a href="https://www.linkedin.com/company/volmint-technologies/"
                                           target="_blank">
                                            <img src="assets/img/landing-new/contact-us/linkedin.png" alt="Reach out"
                                                 class=""/>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.linkedin.com/company/volmint-technologies/"
                                           target="_blank">
                                            <p class="fs-6">LinkedIn</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-5 d-flex justify-content-end">
                                        <a href="https://www.instagram.com/volminttechnologies/" target="_blank">
                                            <img src="assets/img/landing-new/contact-us/insta.png" alt="Reach out"
                                                 class=""/>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.instagram.com/volminttechnologies/" target="_blank">
                                            <p class="fs-6">Instagram</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-5 d-flex justify-content-end">
                                        <a href="https://twitter.com/TechVolmint" target="_blank">
                                            <img src="assets/img/landing-new/contact-us/twitter.png" alt="Reach out"
                                                 class=""/>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://twitter.com/TechVolmint" target="_blank">
                                            <p class="fs-6">Twitter</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Reach out" class="img-fluid"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline primary-blue">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How can I get in touch with Volmint Technologies?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                You can reach us through multiple channels: fill out the contact form on our website,
                                call us directly at +91 9310828501, or email us at support@volmint.com. Our team is
                                ready to assist you Monday through Friday from 9:30 AM to 6:30 PM.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What should I include in my message when contacting support?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                To help us assist you more effectively, please include a detailed description of your
                                issue or inquiry, any relevant account or project information, and your preferred
                                contact method. The more details you provide, the faster we can resolve your concerns.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  How long does it typically take to get a response?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We strive to respond to all inquiries within 24 hours on business days. For more urgent
                                matters, please indicate the urgency in your message, and we’ll prioritize accordingly.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  Can I schedule a consultation or demo?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! If you’re interested in learning more about our services or products, you
                                can request a consultation or demo by contacting us through our form, mail, or by phone.
                                We'll arrange a convenient time to discuss your needs and demonstrate how we can help.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What if I need support outside of regular business hours?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                For urgent issues that arise outside of our regular business hours, please reach out
                                through our support email, and we’ll make every effort to address your needs as quickly
                                as possible.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5" style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-12 fs-1 color-white fw-bold mb-3 mb-md-0 text-center">
                Ready to take your Business’s IT to the next level?
            </div>
        </div>
    </div>
</div>

