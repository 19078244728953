<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">Crafting Innovative </span>
                            Mobile Solutions for Seamless Business Growth</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            specialize in delivering cutting-edge mobile application development services tailored to
                            meet the unique needs of businesses. Our expert team combines creativity with the latest
                            technology to build user-friendly, high-performance apps for both iOS and Android platforms.
                            Whether you need a custom solution for enhancing customer engagement or an innovative mobile
                            app to streamline business operations, we ensure seamless functionality and exceptional user
                            experiences that drive growth and success in the mobile-first world.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="card banner-bg-color md:mr-0 position-relative h-100 w-100 border-0 shadow-lg d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/mobile-development/mobile-development.png" alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/mobile-development/about-mobile-application.png" class="img-fluid"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> Mobile Application Development</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our mobile
                    application development services are designed to help businesses thrive in the digital landscape. We
                    create innovative, scalable, and user-centric mobile solutions tailored to your specific needs. From
                    concept to deployment, our team of experts ensures each app is crafted with precision, delivering
                    seamless performance across iOS and Android platforms. With a focus on cutting-edge technology and
                    exceptional user experience, we empower businesses to engage customers, streamline operations, and
                    drive growth through mobile innovation.</p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Empowering Business Growth Through Innovative Mobile App Development
                    <span class="brand-primary-text-color">Mobile Application Development</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">Unlock your business’s potential with our expert
                    mobile app development services. At Volmint Technologies, we create powerful, custom mobile
                    solutions designed to enhance customer engagement, streamline operations, and drive sustainable
                    growth. Our cutting-edge apps help you stay ahead in today’s fast-paced digital world, ensuring
                    seamless user experiences that fuel business success.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/mobile-development/collage-mobile-development.png"
                     class="img-fluid w-100" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features of</span> Mobile Application Development</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we deliver custom
                    mobile app solutions tailored to your business needs, with expertise in developing high-performance
                    apps for both iOS and Android platforms. Our apps are designed to scale with your business,
                    featuring future-ready architectures that adapt to evolving requirements. We prioritize exceptional
                    user experience, ensuring intuitive interfaces and seamless performance, while implementing advanced
                    security protocols to protect your data. With ongoing support and maintenance, we ensure your app
                    stays updated, secure, and ready to meet the demands of your growing business.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end">
                <img src="assets/img/landing-new/mobile-development/key-feature-mobile-application.png" alt="Image"
                     class="img-fluid border-radius-10">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Efficiently <span class="brand-primary-text-color">Manage Multiple Operations </span> Through a
                    Single, Streamlined Application</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Our innovative mobile application empowers
                    businesses to efficiently manage multiple operations from a single, streamlined platform. By
                    integrating various functionalities into one application, we eliminate the need for disparate
                    systems, allowing for seamless coordination and enhanced productivity. Users can easily oversee
                    different activities, track performance metrics, and make informed decisions, all while enjoying a
                    cohesive user experience. This holistic approach not only saves time and resources but also fosters
                    greater collaboration across teams, driving your business towards success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/mobile-development/operations-mobile-development.jpg"
                     class="ms-2 img-fluid" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/mobile-development/need-mobile-development.jpg"
                     class="ms-2 mt-2 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Do You Need <span class="brand-primary-text-color">Mobile Applications</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In today’s fast-paced digital landscape,
                    having a mobile application is essential for businesses looking to stay competitive and engage with
                    their customers effectively. A mobile app enhances your brand's visibility, providing a direct
                    communication channel that fosters customer loyalty and satisfaction. With features like push
                    notifications, personalized experiences, and easy access to your products or services, a mobile app
                    can significantly boost user engagement and drive sales. Investing in a mobile application not only
                    streamlines operations but also positions your business as modern and customer-centric, ensuring you
                    meet the demands of today’s consumers. Let Volmint Technologies help you unlock this potential with
                    our expert mobile app development services.
                </p>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Sets Our Mobile Applications Apart from the
            Rest</h1>
        <p class="text-white text-center">At Volmint Technologies, our mobile applications are distinguished by their
            exceptional quality, innovative features, and unwavering commitment to excellence. We focus on user-centric
            design, creating intuitive interfaces that ensure an engaging and seamless experience. Our apps are
            optimized for performance, delivering rapid response times and reliability across both iOS and Android
            platforms. We prioritize security with robust measures to safeguard user data, and our scalable architecture
            allows for easy integration of future enhancements. With a dedication to leveraging the latest technologies
            and providing ongoing support, we empower businesses to excel in a dynamic marketplace. Choose our mobile
            applications for a transformative experience that propels your brand forward.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-7 py-md-5">
                <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive
                    <span class="brand-primary-text-color"> Mobile Application Modules </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a holistic
                    approach to mobile application development through our comprehensive modules designed to address
                    every aspect of the process. Our structured framework ensures seamless integration, enhanced
                    functionality, and a superior user experience.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/landing-new/mobile-development/discover-mobile-development.jpg" class="img-fluid"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What platforms do you develop mobile applications for?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We develop mobile applications for both iOS and Android platforms, ensuring your app
                                reaches a wide audience and provides a seamless experience across all devices.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How long does it take to develop a mobile application?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The development timeline varies based on the complexity and features of the application.
                                On average, a basic mobile app can take between 3 to 6 months, while more complex
                                applications may require additional time for design, development, and testing.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Will my mobile application be secure?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! We prioritize security throughout the development process by implementing
                                the latest security protocols and best practices to protect your app and user data from
                                potential threats.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Can I update my mobile application after it’s launched?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, you can! We provide ongoing support and maintenance services, allowing you to
                                update your app with new features, enhancements, and security patches as needed to keep
                                it current and effective.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How much does it cost to develop a mobile application?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The cost of developing a mobile application depends on various factors, including
                                complexity, design, features, and the platforms chosen. We offer customized solutions
                                tailored to your budget and requirements, and we're happy to provide a detailed proposal
                                after discussing your specific needs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
