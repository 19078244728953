import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {

  constructor(  private router: Router, ) { }

  ngOnInit(): void {
  }
    goTo(url) {
        this.router.navigateByUrl(url);
    }


}
