import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AboutComponent} from './components/pages/about/about.component';
import {TeamComponent} from './components/pages/team/team.component';

import {ServicesComponent} from './components/pages/services/services.component';

import {BlogComponent} from './components/pages/blog/blog.component';


import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {FaqComponent} from './components/pages/faq/faq.component';

import {ContactComponent} from './components/pages/contact/contact.component';


import {ProjectsComponent} from './components/pages/projects/projects.component';

import {HomeComponent} from './components/pages/home/home.component';

import {ApplyNowComponent} from './components/pages/apply-now/apply-now.component';
import {CareerComponent} from './components/pages/career/career.component';

import {MediaComponent} from './components/pages/media/media.component';
import {HowWeWorkComponent} from './components/pages/how-we-work/how-we-work.component';
import {TermsAndConditionComponent} from './components/pages/terms-and-condition/terms-and-condition.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {AdGalleryComponent} from './components/pages/experience/ad-gallery/ad-gallery.component';
import {FunFactsComponent} from './components/pages/experience/fun-facts/fun-facts.component';
import {VolmintLogoComponent} from './components/pages/experience/volmint-logo/volmint-logo.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {CrmComponent} from './components/pages/services-details/crm/crm.component';
import {
    CompanyManagementSystemComponent
} from './components/pages/services-details/company-management-system/company-management-system.component';
import {
    ExaminationPlatformComponent
} from './components/pages/services-details/examination-platform/examination-platform.component';
import {
    TaxationFilingSystemComponent
} from './components/pages/services-details/taxation-filing-system/taxation-filing-system.component';
import {
    AccountManagementSystemComponent
} from './components/pages/services-details/account-management-system/account-management-system.component';
import {
    UniversityManagementSystemComponent
} from './components/pages/services-details/university-management-system/university-management-system.component';
import {IdCardComponent} from './components/pages/id-card/id-card.component';
import {BlogDetailComponent} from './components/pages/blog-detail/blog-detail.component';
import {AmsComponent} from './components/pages/ams/ams.component';
import {CareerDetailsComponent} from './components/pages/career/career-details/career-details.component';
import {
    RefundAndCancellationPolicyComponent
} from './components/pages/refund-and-cancellation-policy/refund-and-cancellation-policy.component';
import {UmsComponent} from './components/product-list/ums/ums.component';
import {CgmsComponent} from './components/product-list/cgms/cgms.component';
import {CmsComponent} from './components/product-list/cms/cms.component';
import {ImsComponent} from './components/product-list/ims/ims.component';
import {ArgusComponent} from './components/product-list/argus/argus.component';
import {LmsComponent} from './components/product-list/lms/lms.component';
import {EClinicComponent} from './components/product-list/e-clinic/e-clinic.component';
import {AcricentComponent} from './components/product-list/acricent/acricent.component';
import {VcallingComponent} from './components/product-list/vcalling/vcalling.component';
import {EnrollComponent} from './components/product-list/enroll/enroll.component';
import {
    MobileApplicationDevelopmentComponent
} from './components/service-list/mobile-application-development/mobile-application-development.component';
import {
    IosApplicationDevelopmentComponent
} from './components/service-list/ios-application-development/ios-application-development.component';
import {WebsiteDevelopmentComponent} from "./components/service-list/website-development/website-development.component";
import {ServerManagementComponent} from "./components/service-list/server-management/server-management.component";
import {
    SearchEngineOptimizationServiceComponent
} from "./components/service-list/search-engine-optimization-service/search-engine-optimization-service.component";
import {
    SocialMediaMarketingComponent
} from "./components/service-list/social-media-marketing/social-media-marketing.component";
import {ContentMarketingComponent} from "./components/service-list/content-marketing/content-marketing.component";
import {AdvertisingComponent} from "./components/service-list/advertising/advertising.component";
import {
    DbDevopsServerlessComponent
} from "./components/service-list/db-devops-serverless/db-devops-serverless.component";
import {
    ServerMigrationMonitoringComponent
} from "./components/service-list/server-migration-monitoring/server-migration-monitoring.component";
import {CloudDbCdnComponent} from "./components/service-list/cloud-db-cdn/cloud-db-cdn.component";
import {CaseStudyComponent} from "./components/pages/case-study/case-study.component";
import {ReferAndEarnComponent} from "./components/pages/refer-and-earn/refer-and-earn.component";
import {
    FrontendWebsiteDevelopmentComponent
} from "./components/service-list/frontend-website-development/frontend-website-development.component";
import {UgmsComponent} from "./components/product-list/ugms/ugms.component";
import {VsyncComponent} from "./components/product-list/vsync/vsync.component";

const routes: Routes = [
    /*{ path: '', component: HomeComponent },*/
    {path: '', component: HomeComponent},
    {path: 'home-4', component: HomeComponent},

    {path: 'about', component: AboutComponent},
    /*{path: 'team', component: TeamComponent},*/

    /*{path: 'services', component: ServicesComponent},*/
    {path: 'volmint-disciplined-process', component: HowWeWorkComponent},
    {path: 'projects', component: ProjectsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-and-conditions', component: TermsAndConditionComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'blog-details/:blogName', component: BlogDetailComponent},
    {path: 'ad-gallery', component: AdGalleryComponent},
    {path: 'careers', component: CareerComponent},
    {path: 'careers/careers-details', component: CareerDetailsComponent},
    {path: 'apply-now', component: ApplyNowComponent},

    {path: 'contact', component: ContactComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'media', component: MediaComponent},
    {path: 'fun-facts', component: FunFactsComponent},
    {path: 'volmint-logo', component: VolmintLogoComponent},
    {path: 'id-card/:unique_id', component: IdCardComponent},
    {
        path: 'profile/:unique_id',
        component: ProfileComponent
    },
    /*{ path: 'career', component: CareerComponent },*/
    // Here add new pages component
    {path: 'product/customer-relationship-management', component: CrmComponent},
    {path: 'product/company-management-system', component: CompanyManagementSystemComponent},
    {path: 'product/examination-platform', component: ExaminationPlatformComponent},
    {path: 'product/taxation-filing-system', component: TaxationFilingSystemComponent},
    {path: 'product/account-management-system', component: AccountManagementSystemComponent},
    {path: 'product/university-management-system', component: UniversityManagementSystemComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'refund-and-Cancellation-Policy', component: RefundAndCancellationPolicyComponent},
    {path: 'terms-and-conditions', component: TermsAndConditionComponent},
    {path: 'ams', component: AmsComponent},
    {path: 'college-management-system', component: CmsComponent},
    // {path: 'education-mitra', component: EducationMitraComponent},
    {path: 'university-management-system', component: UmsComponent},
    {path: 'university-and-college-management-system', component: UgmsComponent},
    {path: 'college-group-management-system', component: CgmsComponent},
    {path: 'institute-management-system', component: ImsComponent},
    {path: 'exam-proctoring-system-argus', component: ArgusComponent},
    // {path: 'enroll', component: EnrollComponent},
    {path: 'admission-management-system-enroll', component: EnrollComponent},
    {path: 'learning-management-system', component: LmsComponent},
    {path: 'clinic-management-system', component: EClinicComponent},
    {path: 'acricent', component: AcricentComponent},
    {path: 'lead-management-system-vcalling', component: VcallingComponent},
    {path: 'vsync', component: VsyncComponent},
    {path: 'mobile-application-development', component: MobileApplicationDevelopmentComponent},
    {path: 'ios-application-development', component: IosApplicationDevelopmentComponent},
    {path: 'website-development', component: WebsiteDevelopmentComponent},
    {path: 'frontend-website-development', component: FrontendWebsiteDevelopmentComponent},
    {path: 'search-engine-optimization-service', component: SearchEngineOptimizationServiceComponent},
    {path: 'social-media-marketing-service', component: SocialMediaMarketingComponent},
    {path: 'content-marketing', component: ContentMarketingComponent},
    {path: 'advertising-service', component: AdvertisingComponent},
    {path: 'server-management', component: ServerManagementComponent},
    {path: 'database-devops-serverless', component: DbDevopsServerlessComponent},
    {path: 'server-migration-monitoring', component: ServerMigrationMonitoringComponent},
    {path: 'cloud-db-cdn', component: CloudDbCdnComponent},
    {path: 'refer-and-earn', component: ReferAndEarnComponent},
    {path: 'refer-and-earn/:referred_by_code', component: ReferAndEarnComponent},
    {
        path: 'case-study',
        loadChildren: () => import('../app/components/pages/case-study/case-study.module').then(m => m.CaseStudyModule)
    },

    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
