import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Optimization'},
        {text: 'Communication'},
        {text: 'Integration'},
        {text: 'Information Control'},
        {text: 'Digitalization'},
        {text: 'Centralization'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/project/admissions.png', text: 'Admissions'},
        {icon: 'assets/img/project/enrollment.png', text: 'Enrollment'},
        {icon: 'assets/img/project/examination.png', text: 'Examinations'},
        {icon: 'assets/img/project/finance.png', text: 'Finances'},
        {icon: 'assets/img/project/Recognitions.png', text: 'Recognitions'},
        {icon: 'assets/img/project/student-tracking.png', text: 'Student Tracking'},
        {icon: 'assets/img/project/notifications.png', text: 'Notifications'},
        {icon: 'assets/img/project/branch-icon.png', text: 'Branch Management'}

    ];
    whyChoose = [
        {
            header: 'Advanced Web-Mobile Platforms',
            text: ' Volmint College Management System offers cutting-edge web and mobile platforms. It ensures a seamless user experience with advanced features accessible across devices, enhancing convenience and flexibility in administration.'
        },
        {
            header: 'Role-based Login Access',
            text: 'Volmint CMS prioritizes data security through role-based login access, granting specific permissions to users based on their roles and responsibilities. It ensures confidentiality, integrity, and controlled access to sensitive information and data.'
        },
        {
            header: 'Monitoring & Accurate Information Analysis',
            text: ' The system enables real-time monitoring and precise analysis of data, which offers administrators valuable insights for informed decision-making. The feature enhances operational efficiency and strategic planning within the college.'
        },
        {
            header: 'Document Management and Generation',
            text: 'Volmint CMS facilitates comprehensive document management. Thus, ensuring organized storage and efficient retrieval of essential records. It also simplifies the generation of various documents, streamlining administrative tasks and reducing paperwork.'
        },
        {
            header: 'On-time Notifications',
            text: 'The platform ensures timely communication by sending notifications through multiple channels such as SMS, WhatsApp, and email. The feature enhances communication efficiency, keeping stakeholders informed promptly about important updates and events.'
        },
        {
            header: '24*7 Onsite & Online Service Support',
            text: 'Volmint CMS provides round-the-clock onsite and online service support, ensuring that technical issues are addressed promptly. This commitment to continuous support enhances the reliability and functionality of the system for uninterrupted operations.'
        },
    ];

    offerings = [
        {
            text: 'Lifetime Free Maintenance'
        },
        {
            text: 'Personalized Assistance'
        },
        {
            text: 'Lifetime Free Maintenance Updates'
        },
        {
            text: 'Discounts on Extra Modules'
        },
        {
            text: 'Feedback and Feature Request'
        },
        {
            text: 'Third-Party Tool Integration'
        },
        {
            text: 'Consultation and Best Practices'
        },
        {
            text: 'Training Sessions'
        },
        {
            text: 'Resolution within 48 hours'
        },
        {
            text: 'Free Server'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private meta: Meta,
        private title: Title,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags();
        this.schema();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "CmsPage",
            "name": "Volmint Technology - Cms",
            "url": "https://www.volmint.com/cms",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What can the Volmint College Management System (CMS) do for my college?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " The Volmint CMS is designed to streamline and optimize every aspect of college\n" +
                            "                                administration. It manages student information, attendance, e-learning, exams, and more,\n" +
                            "                                all from a single, centralized system. It enhances efficiency, promotes better\n" +
                            "                                communication, and simplifies daily tasks, making college management easier and more\n" +
                            "                                productive."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How does Volmint CMS enhance the student experience?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Volmint CMS focuses on creating a student-centric environment by offering features like  collaborative e-learning, real-time access to academic data, and personalized notifications. This empowers students with greater self-sufficiency, improved communication with faculty, and a seamless learning experience."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Is the Volmint CMS secure? How does it protect sensitive information?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Yes, the Volmint CMS prioritizes security with role-based login access, ensuring that  only authorized users can access sensitive data. This feature maintains confidentiality and data integrity, preventing unauthorized access and protecting important academic and  administrative records."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How can Volmint CMS help with admissions management?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Volmint CMS simplifies the entire admissions process with its specialized Admission  Management Module (AMM). It handles everything from application processing to enrollment, making the admissions workflow more efficient and reducing manual work for  the staff."
                    }
                },
                {
                    "@type": "Question",
                    "name": " What kind of support does Volmint offer for its CMS users?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Volmint provides 24/7 onsite and online service support, promptly addressing any\n" +
                            "                                technical issues—our commitment to continuous support guarantees that your college\n" +
                            "                                operations run smoothly without any interruptions."
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle('Manage College Operations with College Management System');
        this.meta.updateTag({name: 'description', content: 'Transform college operations with Volmint’s College Management System. Simplify admissions, attendance, fees, and more for seamless campus management'});
        this.meta.updateTag({name: 'keyword', content: 'College Management System'});
    }
}
