<section class="banner">
    <div class="container-fluid bg mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-lg-6 col-12 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56">
                            CMS <span class="brand-primary-text-color"> Best Solution</span>
                            To Manage Colleges
                        </h1>
                        <p class="my-4 fs-6 text-secondary f-20 justify-text">A College Management System (CMS) is a comprehensive
                            software
                            solution designed to streamline and optimize the administrative and academic functions
                            within an
                            educational institution. The cloud-based software caters to the needs of faculty members,
                            learners, parents, and other departmental staff.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 fw-bold border-0 hover-effect" (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/cms-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="College Management Systemr Image" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/cms/cms.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row ">
            <div class="col-md-6 ps-md-5 py-5">
                <h2 class="fs-1 fw-bolder">Glimpse of
                    <span class="brand-primary-text-color">Volmint’s </span>College Management System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">CMS software streamlines college tasks, including student
                    information, e-learning, attendance tracking, and resource allocation. It enhances transparency,
                    accountability, and collaboration by providing real-time access to academic and administrative data.
                    It also manages research projects, evaluates exams, integrates hybrid learning, and tracks expenses.
                    All-and-all it is a must-have product to add for managing colleges effortlessly and increasing
                    productivity.
                </p>
                <img src="assets/img/project/cms-two.png" class="mt-5" alt="College Management System">
            </div>
            <div class="col-md-6 pt-5 text-right d-flex justify-content-center">
                <img src="assets/img/project/cms-2.png" class="mt-2" alt="College Management System">
            </div>
        </div>

        <div class="row mt-md-5 g-5">
            <div class="col-md-6 text-start">
                <img src="assets/img/project/ums-2.png" class="mt-2" alt="College Management System">
            </div>
            <div class="col-md-6 ps-md-5">
                <h2 class="fs-1 fw-bolder">Optimize Campus Efficiency with Our
                    <span class="brand-primary-text-color">Robust CMS</span></h2>
                <p class="my-2 fs-6 text-secondary text-wrap justify-text">College Management System or CMS software integrates every
                    aspect of college
                    administration into a single and centralized system. It includes every task that is performed daily
                    in an academic institution, including course management, grade tracking, attendance tracking,
                    enrollment, admission application, financial aid, etc.
                </p>
<!--                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">-->
<!--                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width-span text-center"-->
<!--                         *ngFor="let item of powerfullToUse">-->
<!--                        {{ item.text }}-->
<!--                    </div>-->
<!--                </div>-->

                <div class="d-flex justify-content-between flex-wrap g-2">
                    <div class="my-2  px-5 py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>

                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img src="assets/img/project/check.png"
                                                                                     alt="College Management System"
                                                                                     class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
        <div class="row py-5">
            <div class="col-md-6 pt-md-5 text-right">
                <img src="assets/img/project/cms-3.png" class="w-75" alt="College Management System">
            </div>
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features of</span> College Management System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap  justify-text">A College Management System or CMS is designed to enhance
                    the experience of
                    students, college administration, and faculty. It helps reduce manual work by automating countless
                    tasks and eases the management system.
                </p>
<!--                <div class="row g-3 mt-2">-->
<!--                    <div class="col-sm-6 col-12 px-0 my-2"-->
<!--                         *ngFor="let item of features">-->
<!--                        <span>-->
<!--                            <img src="assets/img/project/check.png" alt="College Management System" class="img-fluid">-->
<!--                        </span>-->
<!--                        <span class="ms-2 text-secondary">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="row g-3">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xxl-4 col-6 px-0 my-2 "
                         *ngFor="let item of features">
                        <span>
                            <img src="{{item.icon}}" alt="College Management System" class="img-fluid">
                        </span>
                        <span class="ms-2 fs-sm-5 text-secondary key_features">
                            {{ item.text }}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="container-fluid ">
    <div class="container rounded-3 mb-5 px-5 py-5 bg-purple ">
        <h2 class="text-center fs-1 mt-5 fw-bold">What Makes Our College Management System Stand Out</h2>
        <p class="text-secondary text-wrap text-center justify-text">Volmint College Management System (CMS) is a comprehensive,
            cloud-based solution created to transform the administration of colleges by simplifying processes, promoting
            effective communication, and giving students greater self-sufficiency. Volmint provides various benefits and
            customized features in CMS to deliver the best to our clients.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div
                    class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}
                </div>
                <h3 class="my-2 fs-5 fw-bold">{{ item.header }}</h3>
                <p class="text-secondary text-wrap justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
    <div class="container text-center pt-5">
        <div class="row g-4">
            <h2 class="fs-1 fw-bolder">
                <span class="brand-primary-text-color">Modules We</span> Provide In Our CMS</h2>
            <p class="my-4 fs-6 text-secondary text-wrap justify-text text-center">An education ERP system called a UMS facilitates information
                sharing between
                educators, learners, parents, outside vendors, and learning providers, among others, by providing a
                platform for communication.</p>
            <div class="row py-4 justify-content-between g-4">
                <div class="col-md-4 d-flex justify-content-center">
                    <div class="card border-0 shadow rounded-4 p-4 bg-white fixed-height-card">
                        <h4 class="fw-bold">Admission Management Module (AMM)</h4>
                        <p class="text-secondary text-wrap justify-text">The AMM streamlines admission processes, including marking,
                            lateral entries, credit transfers,
                            student re-registration, follow-ups, reminders, and multiple admissions. It integrates
                            social
                            media leads, manages multiple branches, and offers accounting and reporting panels.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card border-0 shadow rounded-4 p-4 bg-white fixed-height-card">
                        <h4 class="fw-bold">Student Management Module (SMM)</h4>
                        <p class="text-secondary text-wrap justify-text">The SMM provides a comprehensive and robust learning
                            management
                            system, including advanced chatbot integrations, streamlined admission forms, social media
                            integration, real-time notifications, customizable reminders, interactive video sessions,
                            live
                            lectures, personalized support services, and detailed examination and documentation
                            management
                            for students.
                        </p>
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="card border-0 shadow rounded-4 p-4 bg-white fixed-height-card">
                        <h4 class="fw-bold">Management Administrative Module (MAM)</h4>
                        <p class="text-secondary text-wrap justify-text"> The MAM system manages admission centers, sessions, student
                            lead inquiries, enrollment ID
                            generation, accounting, reporting, Learning Management System, EMS, EMS, online/offline
                            examinations, email notifications, question paper upload, DMS, and support services.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row gx-5">
            <div class="col-md-7 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Complimentary Offerings </span> From Volmint Technologies
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Volmint Technology is on a mission to Empower the future
                    with the essence of Technology, we are working to streamline the education system with the ease of
                    innovation. Following our mission and vision, we are providing complimentary offerings to our
                    clients and customers that enhance user experience and efficiency.</p>


                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="College Management System " class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/CMS.jpg" class="img-fluid" alt="College Management System">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="College Management System" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com" class="text-decoration-underline primary-blue">support@volmint.com</a></p>
                    <button type="submit" class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What can the Volmint College Management System (CMS) do for my college?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The Volmint CMS is designed to streamline and optimize every aspect of college
                                administration. It manages student information, attendance, e-learning, exams, and more,
                                all from a single, centralized system. It enhances efficiency, promotes better
                                communication, and simplifies daily tasks, making college management easier and more
                                productive.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  How does Volmint CMS enhance the student experience?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint CMS focuses on creating a student-centric environment by offering features like
                                collaborative e-learning, real-time access to academic data, and personalized
                                notifications. This empowers students with greater self-sufficiency, improved
                                communication with faculty, and a seamless learning experience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  Is the Volmint CMS secure? How does it protect sensitive information?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, the Volmint CMS prioritizes security with role-based login access, ensuring that
                                only authorized users can access sensitive data. This feature maintains confidentiality
                                and data integrity, preventing unauthorized access and protecting important academic and
                                administrative records.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How can Volmint CMS help with admissions management?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint CMS simplifies the entire admissions process with its specialized Admission
                                Management Module (AMM). It handles everything from application processing to
                                enrollment, making the admissions workflow more efficient and reducing manual work for
                                the staff.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What kind of support does Volmint offer for its CMS users?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint provides 24/7 onsite and online service support, promptly addressing any
                                technical issues—our commitment to continuous support guarantees that your college
                                operations run smoothly without any interruptions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12 fs-2 color-white fw-bold mb-3 mb-md-0">
                Ready to elevate your university management to next level?
            </div>
            <div class="col-md-6 col-12 text-center">
                <span class="fs-4 fw-bold mb text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class="bg-light-pink shadow py-5 mt-2 px-4">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3" id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="bg-color-blue border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

</div>
