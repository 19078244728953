import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-advertising',
    templateUrl: './advertising.component.html',
    styleUrls: ['./advertising.component.scss']
})
export class AdvertisingComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [

        {text: 'Search Engine Advertising (SEA)'},
        {text: 'Display Advertising'},
        {text: 'Social Media Advertising'},
        {text: 'Content Marketing'},
        {text: 'Email Marketing'},
        {text: 'Continuous Optimization'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Custom-Tailored Content'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'SEO-Driven Results'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Multi-Format Expertise'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Focused on Your Audience'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Data-Backed Insights'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Boost Engagement & Conversions'},

    ];
    whyChoose = [
        {
            header: 'Set Clear Goals',
            text: 'Our first step includes understanding the essence of your business, audience, and objectives. It helps define measurable goals that are in line with your expectations of quantity and transform them into clear action plans.'
        },
        {
            header: 'Research & Strategy',
            text: 'Through deep analysis and comprehensive competitor research, we obtain necessary data that can be interpreted into a successful marketing plan suitable to meet your business goals.'
        },
        {
            header: 'Campaign creation',
            text: ' We employ a combination of creativity and data-driven insights to create effective advertising campaigns, ensuring engagement, awareness, and positive results.'
        },
        {
            header: 'Targeted Execution',
            text: 'We manage when and where your ads will be published using specific targeting to ensure that they are seen by the most relevant audience.'
        },
        {
            header: 'Real-Time Tracking',
            text: 'Live tracking of performance indicators so that we can determine the rate of success, possible areas of improvement, and generation of reports for evaluation.'
        }
    ];
    offerings = [
        {
            text: 'Diverse Strategy Portfolio'
        },
        {
            text: 'Data-Driven Approach'
        },
        {
            text: 'Tailored Campaigns'
        },
        {
            text: 'Enhanced Brand Visibility'
        },
        {
            text: 'Expertise and Innovation'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title: Title,
        private meta: Meta,
        private renderer: Renderer2

    ) {
    }

    ngOnInit(): void {
        this.updateMetaTags();
        this.refresh();
        this.schema();
    }
    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "advertising-servicePage",
            "name": "Volmint Technology - Sdvertising-Service",
            "url": "https://www.volmint.com/advertising-service",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": " What types of online advertising do you offer?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " We offer a range of advertising services, including ads on google (search engine ads), youtube (video ads), facebook, instagram, linkedin and twitter (social media ads). We further provide other PPC advertising services including search ads, display ads, shopping, ads and video ads."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How do I know which advertising agency is right for my business?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   There are a number of advertising agencies available, to choose the most appropriate one, follow some basic steps such as checking your budget and goal first and shortlist the agency accordingly. Moreover, go through their previous work, client reviews, area of expertise, market status, etc. Ensure your chosen advertising agency is genuine, has appropriate customer service, and communication, and offers customized solutions that fit your unique audience base."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How much does it cost to run an online ad campaign?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Usually, various SMM and SEM advertising agencies charge different fees. However, at Volmint, the charges depend on your budget, campaign goals, and platform. We provide customized plans to ensure effective results, within your budget."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How can I increase my return on investment (ROI) from online ads?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Target the right audience, use compelling content and track performance. At Volmint - the best advertising agency, we continuously optimize campaigns to maximize ROI for your business."
                    }
                },



            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle('Grow With Volmint - Best Advertising Company in India');
        this.meta.updateTag({name: 'description', content: 'Volmint Technologies, an Advertising company in India helps your brand reach a targeted audience, drive conversions, and achieve impactful results.'});
        this.meta.updateTag({name: 'keyword', content: 'Advertising Company In India'});
    }
}
