import {Component, ElementRef, OnInit, Renderer2, ViewChild, AfterViewInit, OnDestroy, HostListener} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

    constructor(
        public fb: FormBuilder,
        public alertService: AlertService,
        private router: Router,
        private elRef: ElementRef,
        private meta: Meta,
        private titles: Title,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        this.updateMetaTags();
        this.schema();
    }

    schema() {
        const corporationSchema = {
            '@context': 'https://schema.org',
            '@type': 'AboutPage',
            name: 'About Volmint',
            url: 'https://www.volmint.com/about',
            logo: 'https://www.volmint.com/assets/img/white-volmint-logo.png',
            description: 'Volmint Technologies - Your All-in-One Technical Assistant',

            sameAs: [
                'https://www.facebook.com/VolmintTechnologies',
                'https://x.com/TechVolmint',
                'https://www.instagram.com/volminttechnologies/',
                'https://www.youtube.com/@volminttechnologies/videos',
                'https://www.linkedin.com/company/volmint-technologies/'
            ]
        };

        // FAQ Schema


        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema

    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');

        if (video) {
            video.classList.remove('d-none');
            video.style.display = 'block';
            video.play();

            if (thumbnail) {
                thumbnail.style.display = 'none';
            }

            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    updateMetaTags() {
        this.titles.setTitle('Volmint Technologies - Your All-in-One Technical Assistant');
        this.meta.updateTag({
            name: 'description',
            content: `Learn more about Volmint Technologies, a fastest-growing IT solution provider, strengthening education and business through secure, advanced, and reliable software solutions.`
        });
        this.meta.updateTag({name: 'keyword', content: `Volmint Technologies`});
    }

}
