import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {BaseService} from "./base.service";
import {AppUrl} from "../constants/app-url";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ReferService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    add(params: any): Observable<any> {
        return this.postRequest(AppUrl.REGISTER_REFER(), params);
    }
}
