<!-- <section class="banner">
   <div class="container-fluid bg-sea-green mt-5">
       <div class="container mt-5 pt-5">
           <div class="row g-5">
               <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                   <div>
                       <h1 class="fw-bolder font-56"><span
                           class="brand-primary-text-color">Maximize Your Reach with </span>
                           Expert Advertising Solutions</h1>
                       <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                           specialize in delivering comprehensive advertising services that encompass every aspect of
                           digital marketing. From Search Engine Advertising and Display Advertising to Social Media
                           Advertising and Email Marketing, our multifaceted approach ensures your brand is effectively
                           positioned in the digital landscape. We create targeted campaigns that engage your audience,
                           drive traffic, and boost conversions. With a focus on data-driven strategies and innovative
                           techniques, we help you maximize your reach and achieve your marketing goals. Partner with
                           us to elevate your brand and turn your advertising investment into measurable results.
                       </p>
                       <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                               (click)="openModal()">View Demo <i
                           class="ms-3 fa-solid fa-arrow-right"></i>
                       </button>
                       <ng-template #contactFormModal>
                           <app-contact-form [isModalOpen]="isModalOpen"
                                             (closeModal)="closeModal()"></app-contact-form>
                       </ng-template>
                       <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                           <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                       </div>
                   </div>
               </div>
               <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                   <div
                       class=" md:mr-0 position-relative h-100 w-75 border-0  border-radius-10">
                       <img src="assets/img/landing-new/advertising-service/intro-advertising.jpg"
                            alt="Image"
                            class="img-fluid border-radius-10 w-100"/>
                   </div>
               </div>
           </div>
       </div>
   </div>
</section>

<div class="container-fluid">
   <div class="container py-5">
       <div class="row">
           <div class="col-md-6 m-0 p-0 text-right">
               <img src="assets/img/landing-new/advertising-service/about.abvertising.jpg"
                    class="img-fluid border-radius-10"
                    alt="Image">
           </div>
           <div class="col-md-6 ps-md-5 py-md-5 py-4">
               <h2 class=" fs-1 fw-bolder"> Comprehensive Advertising Services
                   <span class="brand-primary-text-color"> Drive Results with Volmint Technologies</span></h2>
               <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a robust
                   suite of advertising services designed to empower businesses in the digital landscape. With the
                   rapid evolution of online marketing, leveraging effective advertising strategies is crucial for
                   reaching and engaging your target audience. Our advertising services cover all facets of digital
                   marketing, including Search Engine Advertising, Display Advertising, Social Media Advertising,
                   Content Marketing, Email Marketing, and more.

               </p>
           </div>
       </div>

       <div class="row mt-md-5">
           <div class="col-md-6 py-md-5">
               <h2 class=" fs-1 fw-bolder"> Ignite Your Business Growth with
                   <span class="brand-primary-text-color">  Strategic Advertising Solutions</span></h2>
               <p class="fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we believe that strategic
                   advertising is the catalyst for driving sustainable business growth. Our innovative advertising
                   solutions are meticulously crafted to align with your unique goals and target audience, ensuring
                   maximum impact across all digital platforms. By leveraging data-driven insights and creative
                   strategies, we enhance your brand visibility and engagement, turning potential customers into loyal
                   advocates. Let us empower your business with tailored advertising campaigns that not only capture
                   attention but also drive meaningful results, propelling you toward long-term success in a
                   competitive marketplace.
               </p>
               <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                   <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                        *ngFor="let item of powerfullToUse">
                       {{ item.text }}
                   </div>
               </div>
               <div class="col-12 d-block d-lg-none mt-4">
                   <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                       src="assets/img/project/check.png"
                       alt="Image"
                       class="img-fluid"> {{ item.text }}
                   </p>
               </div>
           </div>
           <div class="col-md-6 pt-5">
               <img src="assets/img/landing-new/advertising-service/business-advertising.jpg"
                    class="img-fluid w-100 border-radius-10" alt="">
           </div>
       </div>
   </div>
</div>

<div class="container-fluid bg-purple">
   <div class="container">
       <div class="row py-5">
           <div class="col-md-6 py-5">
               <h2 class=" fs-1 fw-bolder">
                   Explore the Transformative Features of <span class="brand-primary-text-color">  Our Advertising Services</span>
               </h2>
               <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our advertising
                   services are designed to revolutionize your marketing approach and deliver exceptional results. With
                   a focus on innovation and effectiveness, we offer a range of transformative features that set us
                   apart. Our data-driven targeting strategies ensure that your campaigns reach the right audience at
                   the right time, maximizing engagement and conversion rates. We harness cutting-edge technologies and
                   creative content to craft compelling advertisements that resonate with your target market. From
                   comprehensive analytics to continuous optimization, our services provide the insights and
                   flexibility needed to adapt to the ever-changing digital landscape. Discover how our advertising
                   solutions can elevate your brand and drive sustainable growth in today's competitive environment.
               </p>
               <div class="row g-3">
                   <div class="col-sm-4 col-6 px-2 my-2"
                        *ngFor="let item of features">
                       <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                              <span>
                           <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                       </span>
                           <span class="ms-2 text-secondary pt-2">
                           {{ item.text }}
                       </span>
                       </div>
                   </div>
               </div>
           </div>
           <div class="col-md-6  justify-content-md-end my-2">
               <img src="assets/img/landing-new/advertising-service/explore-adversing.jpg" alt="Image"
                    class="img-fluid border-radius-10">
           </div>
       </div>
   </div>
</div>

<div class="container-fluid">
   <div class="container">
       <div class="row gx-4">
           <div class="col-md-6 py-5">
               <h2 class=" fs-1 fw-bolder">
                   Precision Advertising Customized<span class="brand-primary-text-color">   for Your Audience</span>
               </h2>
               <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                   At Volmint Technologies, we specialize in precision advertising that is meticulously customized to
                   resonate with your specific audience. Our approach leverages advanced data analytics and consumer
                   insights to create targeted campaigns that engage potential customers at every stage of their
                   journey. By understanding the unique preferences and behaviors of your audience, we craft compelling
                   messages and select the optimal channels to deliver them. This ensures that your advertising efforts
                   not only capture attention but also drive meaningful interactions and conversions. Trust us to
                   enhance your marketing strategy with precision-targeted advertising that maximizes your brand's
                   impact in a competitive marketplace.
               </p>
               <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                       (click)="openModal()">Book Appointment
                   <i
                       class="ms-3 fa-solid fa-arrow-right"></i>
               </button>
           </div>
           <div class="col-md-6 pt-5 text-right">
               <img src="assets/img/landing-new/advertising-service/Customized-advertising.jpg"
                    class="ms-2 img-fluid border-radius-10" alt="Image"/>
           </div>

           <div class="col-md-6 pt-5 text-right">
               <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"
                    class="w-100 border-radius-10" alt="">
           </div>
           <div class="col-md-6 py-5 ps-md-5">
               <h2 class="fs-1 fw-bolder">
                   <span
                       class="brand-primary-text-color">Holistic Advertising Strategies for </span> Enhanced Online
                   Visibility</h2>
               <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer holistic
                   advertising strategies designed to enhance your online visibility and elevate your brand in the
                   digital landscape. Our comprehensive approach integrates various advertising channels, including
                   search engine marketing, social media campaigns, and content marketing, to create a cohesive
                   presence that resonates with your target audience. By leveraging data-driven insights and innovative
                   techniques, we ensure that your message reaches the right people at the right time, maximizing
                   engagement and driving conversions. Our commitment to continuous optimization means that your
                   advertising efforts are always aligned with evolving market trends, providing you with sustained
                   visibility and growth in a competitive environment. Partner with us to unlock the full potential of
                   your brand through effective and strategic advertising solutions.
               </p>
           </div>
       </div>
   </div>
</div>

<div class="container-fluid">
   <div class="container">
       <div class="row gx-4">
           <div class="col-md-6 py-5">
               <h2 class=" fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color"> The Essential Role of Advertising</span>
                   in Fueling Business Success</h2>
               <p class="my-4 fs-6 text-secondary text-wrap justify-text">Advertising plays a pivotal role in driving
                   business success by connecting brands with their target audiences and effectively communicating
                   their value propositions. At Volmint Technologies, we understand that strategic advertising not only
                   enhances brand awareness but also fosters customer engagement and loyalty. In today's competitive
                   marketplace, a well-crafted advertising strategy can differentiate your business from competitors,
                   ensuring your message stands out. By leveraging data-driven insights and innovative techniques, we
                   help businesses optimize their advertising efforts, reaching the right customers at the right time.
                   Ultimately, effective advertising serves as the backbone of growth, enabling companies to expand
                   their market reach, increase sales, and achieve long-term success.
               </p>
               <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                       (click)="openModal()">Book Appointment
                   <i
                       class="ms-3 fa-solid fa-arrow-right"></i>
               </button>
           </div>
           <div class="col-md-6 pt-5 text-right">
               <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"
                    class="ms-2 img-fluid border-radius-10" alt="Image"/>
           </div>
       </div>
   </div>
</div>

<div class="container-fluid brand-primary-bg mt-5">
   <div class="container py-5">
       <h1 class="text-white text-center mt-5 fw-bold justify-text">What Sets Our Advertising Marketing Services
           Apart? </h1>
       <p class="text-white text-center">At Volmint Technologies, our advertising marketing services stand out due to
           our unwavering commitment to delivering tailored solutions that drive measurable results. What distinguishes
           us is our holistic approach, integrating advanced data analytics, creative strategies, and industry
           expertise to develop campaigns that resonate with your target audience. We prioritize collaboration and
           communication, ensuring that our strategies align seamlessly with your business goals. Additionally, our
           focus on continuous optimization allows us to adapt and refine campaigns in real time, maximizing their
           effectiveness. With a dedicated team of professionals passionate about your success, we empower your brand
           to achieve exceptional visibility and engagement in an increasingly competitive digital landscape.
       </p>
       <div class="row gy-5 py-5">
           <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
               <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
               <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
               <p class="text-white justify-text">{{
                       item.text
                   }}</p>
           </div>
       </div>
   </div>
</div>

<div class="container-fluid py-5">
   <div class="container">
       <div class="row py-5 gx-5">
           <div class="col-md-6 py-md-5">
               <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive
                   <span class="brand-primary-text-color">   Advertising Marketing Solutions </span>
               </h2>
               <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a
                   comprehensive suite of advertising marketing solutions designed to elevate your brand and drive
                   impactful results. Our services encompass a wide range of strategies, including search engine
                   advertising, social media campaigns, display advertising, and content marketing, ensuring a
                   well-rounded approach to your advertising needs. By leveraging data-driven insights and innovative
                   techniques, we create targeted campaigns that engage your audience and enhance brand visibility. Our
                   team of experts is dedicated to understanding your unique business goals, crafting tailored
                   solutions that resonate with your target market. Partner with us to unlock the full potential of
                   your advertising efforts and achieve sustained growth in the digital landscape.</p>
               <div class="row g-3 mt-5">
                   <div class="col-12 px-0 my-3 "
                        *ngFor="let item of offerings">
                       <span>
                           <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                       </span>
                       <span class="ms-2 fw-bold text-secondary">
                           {{ item.text }}
                       </span>
                   </div>
               </div>
           </div>
           <div class="col-md-6 pt-5  justify-content-end">
               <img src="assets/img/landing-new/content-marketing/explore-content-marketing.png"
                    class="img-fluid border-radius-10"
                    alt="Image">
           </div>
       </div>

   </div>
</div>

<div class="container-fluid">
   <div class="container">
       <div class="row">
           <div class="col-12 col-md-6">
               <div class="col-12">
                   <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                   <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                   <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                   <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                         class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                   </p>
                   <button type="submit"
                           class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                           (click)="goTo('/faq')">
                       View All Faqs
                   </button>
               </div>
           </div>
           <div class="col-12 col-md-6 mt-4">
               <div class="accordion" id="faqAccordion">
                   <div class="accordion-item shadow-lg border-0">
                       <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                               <span class="fs-6 fw-bold" style="color: #03738C;">
                                   <div class="row">
                                       <div class="col-1">
                                         <div class="encircled-icon float-start">
                                               <i class="fas fa-question fw-bold"></i>
                                         </div>
                                       </div>
                                       <div class="col-11">
                                       What types of advertising marketing services do you offer?
                                       </div>
                                   </div>
                               </span>
                           </button>
                       </h2>
                       <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#faqAccordion">
                           <div class="accordion-body fs-6 text-secondary justify-text">
                               We offer a wide range of advertising marketing services, including search engine
                               advertising, social media campaigns, display advertising, content marketing, email
                               marketing, and more. Our comprehensive approach ensures that we can meet all your
                               advertising needs under one roof.
                           </div>
                       </div>
                   </div>
                   <div class="accordion-item shadow-lg border-0">
                       <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                       <span
                           class="fs-6 fw-bold" style="color: #03738C;">
                            <div class="row">
                                       <div class="col-1">
                                           <div class="encircled-icon float-start">
                                               <i class="fas fa-question fw-bold"></i>
                                           </div>
                                       </div>
                                <div class="col-11">
                                  How do you determine the best advertising strategy for my business?
                                </div>
                            </div>
                           </span>
                           </button>
                       </h2>
                       <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#faqAccordion">
                           <div class="accordion-body fs-6 text-secondary justify-text">
                               Our process begins with a thorough analysis of your business goals, target audience, and
                               industry trends. We then leverage data-driven insights to create a tailored advertising
                               strategy that aligns with your specific objectives, ensuring maximum relevance and
                               effectiveness.
                           </div>
                       </div>
                   </div>
                   <div class="accordion-item shadow-lg border-0">
                       <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                               <span class="fs-6 fw-bold" style="color: #03738C;">
                                   <div class="row">
                                       <div class="col-1">
                                           <div class="encircled-icon float-start">
                                               <i class="fas fa-question fw-bold"></i>
                                           </div>
                                       </div>
                                       <div class="col-11">
                                          How will I know if my advertising campaigns are successful?
                                       </div>
                                   </div>
                               </span>
                           </button>
                       </h2>
                       <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#faqAccordion">
                           <div class="accordion-body fs-6 text-secondary justify-text">
                               We provide regular performance reports that outline key metrics such as engagement
                               rates, click-through rates, conversions, and return on investment (ROI). Our team will
                               also conduct ongoing analysis and optimization to ensure your campaigns are meeting
                               their goals.
                           </div>
                       </div>
                   </div>
                   <div class="accordion-item shadow-lg border-0">
                       <h2 class="accordion-header" id="headingFour">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                               <span class="fs-6 fw-bold" style="color: #03738C;">
                                   <div class="row">
                                       <div class="col-1">
                                           <div class="encircled-icon">
                                               <i class="fas fa-question fw-bold"></i>
                                           </div>
                                       </div>
                                       <div class="col-11">
                                          How quickly can I expect to see results from my advertising campaigns?
                                       </div>
                                   </div>
                               </span>
                           </button>
                       </h2>
                       <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                            data-bs-parent="#faqAccordion">
                           <div class="accordion-body fs-6 text-secondary justify-text">
                               The timeline for seeing results can vary based on the type of campaign and your specific
                               goals. However, many clients begin to see increased engagement and brand visibility
                               within the first few weeks of launching their campaigns. We focus on both short-term
                               wins and long-term growth strategies.
                           </div>
                       </div>
                   </div>
                   <div class="accordion-item shadow-lg border-0">
                       <h2 class="accordion-header" id="headingFive">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                               <span class="fs-6 fw-bold" style="color: #03738C;">
                                   <div class="row">
                                       <div class="col-1">
                                           <div class="encircled-icon">
                                       <i class="fas fa-question fw-bold"></i>
                                           </div>
                                       </div>
                                       <div class="col-11">
                                          What sets your advertising services apart from competitors?
                                       </div>
                                   </div>
                               </span>
                           </button>
                       </h2>
                       <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                            data-bs-parent="#faqAccordion">
                           <div class="accordion-body fs-6 text-secondary justify-text">
                               What distinguishes our advertising services is our holistic approach, combining diverse
                               strategies and data-driven insights to create customized campaigns. We prioritize
                               collaboration and communication, ensuring our solutions align with your vision. Our
                               commitment to continuous optimization and innovative techniques further enhances the
                               effectiveness of our advertising efforts.
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
   <div class="container">
       <div class="row align-items-center py-5">
           <div class="col-md-10 col-12">
               <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
           </div>
           <div class="col-md-2 col-12">
               <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                       (click)="goTo('/contact')">Contact Us
               </button>
           </div>
       </div>
   </div>
</div> -->

<section class="wave-section">
    <div class=" py-5 bg-sea-green ">
        <div class="container">
            <div class="row flex-column-reverse flex-md-row ">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56 d-none d-md-block"><span
                            class="text-primary"> Drive Your Brand Forward </span>
                            With Volmint’s Advertising Agency</h1>
                        <p class="my-4 fs-6 text-white text-wrap f-20 justify-text d-none d-md-block">
                            Connect, Inspire, and Grow your brand visibility, reach your target audience, and boost
                            conversions with Volmint’s advertising services that go beyond impressions. Craft campaigns
                            that inspire action, encourage meaningful engagement, and drive measurable results for your
                            business.

                        </p>
                        <h1 class="fw-bolder fs-3 d-md-none"><span
                            class="text-primary"> Drive Your Brand Forward </span>
                            With Volmint’s Advertising Agency</h1>
                        <p class=" text-white text-wrap f-20 justify-text d-md-none">Connect, Inspire, and Grow your
                            brand visibility, reach your target audience, and boost conversions with Volmint’s
                            advertising services that go beyond impressions. Craft campaigns that inspire action,
                            encourage meaningful engagement, and drive measurable results for your business.

                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">Get Started <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  py-5 d-flex ">
                    <div
                        class="py-5 position-relative d-flex justify-content-md-end justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/advertising-service/intro-advertising.jpg"
                             alt="Image"
                             class="img-fluid border-radius-10 d-none d-md-block w-75"/>
                        <img src="assets/img/landing-new/advertising-service/intro-advertising.jpg"
                             alt="Image"
                             class="img-fluid w-50 mx-auto d-md-none "/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wave">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="white" fill-opacity="1"
                  d="M0,32L17.1,53.3C34.3,75,69,117,103,144C137.1,171,171,181,206,197.3C240,213,274,235,309,224C342.9,213,377,171,411,154.7C445.7,139,480,149,514,138.7C548.6,128,583,96,617,69.3C651.4,43,686,21,720,32C754.3,43,789,85,823,128C857.1,171,891,213,926,213.3C960,213,994,171,1029,154.7C1062.9,139,1097,149,1131,144C1165.7,139,1200,117,1234,122.7C1268.6,128,1303,160,1337,192C1371.4,224,1406,256,1423,272L1440,288L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
        </svg>
    </div>
</section>
<section class="" style="overflow-x:hidden;">
    <div class="container pb-4">
        <div class="row g-5 justify-content-center">
            <div class=" align-self-center">
                <div class=" p-3 md-mt-5 h-100">
                    <h2 class="text-primary md-mt-5 fw-bold">Why Businesses Struggle Without Advertising</h2>
                    <p class=" fs-6 text-secondary text-wrap justify-text">Not having a solid SEO strategy can severely
                        limit your business's growth and visibility. SEO is no longer optional—it’s essential for
                        staying ahead in a competitive market. Without a reputed SEO company in India, your business
                        faces challenges that can lead to missed opportunities and reduced profitability. Let’s explore
                        the hurdles businesses encounter when they ignore SEO and how Volmint Technologies can help turn
                        the tide in your favor.
                    </p>
                    <div style="height:10px;" class="rounded border w-25 bg-gradient"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative  pink-box p-3 text-center d-flex align-items-center justify-content-center  ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class=""> Poor Online Visibility </span>
                        </h3>
                        <p class="text-secondary">
                            SEO is crucial for a website's visibility on search engines, preventing competitors from
                            capturing potential customers and enhancing its search engine ranking.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative blue-box p-3 text-center d-flex align-items-center justify-content-center ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-5.png" alt="">

                        <h3 class="fs-5 mt-3 fw-bolder">
                                            <span
                                                class="">Decrease in Website Traffic  </span></h3>
                        <p class="text-secondary">A website without SEO attracts minimal organic traffic, forcing
                            businesses to rely solely on paid ads or word-of-mouth marketing that reduces long-term
                            sustainability.</p>
                    </div>

                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center orange-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-64.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">Lack of Credibility</span></h3>
                        <p class="text-secondary">Search engines favor websites that adhere to SEO best practices,
                            making them appear more trustworthy. Without proper SEO, your website risks being perceived
                            as unreliable by users.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center green-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-2.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">  Missed Local Opportunities </span></h3>
                        <p class="text-secondary">Ignoring SEO can lead to missed connections with local customers, as
                            it's crucial for businesses targeting specific regions to increase their search
                            visibility.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative yellow-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-3.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">Bad User Experience (UX)  </span></h3>
                        <p class="text-secondary">
                            SEO isn’t just about keywords—it’s also about optimizing site speed, navigation, and mobile
                            responsiveness. Without SEO, users may experience a clunky website that frustrates them.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative red-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                                             <span
                                                 class="">Inability to Compete  </span></h3>
                        <p class="text-secondary">
                            Your competitors are likely leveraging SEO to attract customers, leaving you behind in the
                            digital race. Without SEO, it becomes harder to compete effectively in your industry.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="container-fluid pt-4">
    <div class="container rounded-5 mt-5">
        <div class="row ">
            <div class="col-md-5 align-self-md-center text-center">
                <img src="assets/img/landing-new/seo/business-growth-seo.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 py-5">
                <h2 class="text-center  fs-1 fw-bolder">
                    <span class="text-primary">About Volmint’s Advertising Services</span></h2>
                <p class=" text-secondary ">At Volmint, we don’t just do advertising – we do campaigns to connect. Our
                    advertising services are designed to help brands of all sizes increase their presence, attract the
                    right audience, and achieve their goals. From design to execution, we work closely to develop
                    customized solutions that align with your brand’s voice and values. Whether it’s digital
                    advertising, social media campaigns, or other strategies, Volmint is an advertising agency that
                    ensures your brand stands out in this ever-evolving marketing trend.

                </p>
                <p class="text-secondary"><strong>Enhanced Visibility</strong>Our strategic advertising campaigns ensure
                    your brand reaches the right audience at the right time.

                </p>
                <p class="text-secondary"><strong>Targeted Reach</strong>Our creative campaigns foster meaningful
                    connections with your audience, driving loyalty and growth.

                </p>
                <p class="text-secondary"><strong>Improved Engagement</strong>We use precise targeting to connect you
                    with potential customers who are most likely to engage.

                </p>
                <p class="text-secondary"><strong>Measurable Results</strong>With real-time monitoring and analytics, we
                    deliver campaigns that provide tangible outcomes.

                </p>

                <p class="text-secondary">Our team of experienced professionals creates customized strategies according
                    to your goals, ensuring high-quality advertising that resonates with your audience. Whether you’re a
                    startup or an established brand, we craft each campaign to make it really happen.


                </p>

            </div>

        </div>
    </div>
</div>


<div class="container-fluid my-5">
    <div class="container ">
        <div class="row g-4 pb-4 justify-content-center mx-auto">
            <div class=" align-self-center py-4">
                <h2 class="my-2 fs-1 fw-bolder">
                                             <span
                                                 class="text-primary">Advertising Solutions That Drive Impact and Deliver Results </span>
                </h2>
                <p class="text-secondary">Volmint, a well-known advertising agency offers a wide range of advertising
                    services from Search Engine Marketing to Paid Social Media Marketing that help you connect with your
                    audience and achieve your business goals. Our expertise ensures that every campaign is strategic,
                    creative, and results-driven.
                </p>
            </div>
            <div class="col-md-6 col-lg-5">
                <div class="rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/on-page-seo.png" alt="Search Engine Marketing Services">
                    <h4 class="fw-bold mt-2 text-center">Search Engine Marketing Services</h4>
                    <p class="text-secondary">
                        With an increasing number of online consumers, search engine marketing is
                        essential to boost reach and drive results for businesses. Through advertising on Google and
                        YouTube, we target potential customers who are already looking for the products or services.
                    </p>
                    <p class="text-secondary">
                        <span class="fw-bold">Our SEM Services Include:</span>
                    </p>
                    <ul class="text-secondary">
                        <li>Google Search Ads</li>
                        <li>Google Display Ads</li>
                        <li>Google Shopping Ads</li>
                        <li>YouTube (Video) Ads</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-5">
                <div class="rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/off-page-seo.png" alt="Social Media Advertising Services">
                    <h4 class="fw-bold mt-2 text-center">Social Media Advertising Services</h4>
                    <p class="text-secondary">
                        In this drastically increasing online world, grabbing attention has become harder.
                        With our Paid Social Media Marketing Services, we significantly boost brand engagement
                        and reach, and generate quality leads through targeted advertising on Facebook, Instagram,
                        and LinkedIn.
                    </p>
                    <p class="text-secondary">
                        <span class="fw-bold">Our Paid Social Media Marketing Services Include:</span>
                    </p>
                    <ul class="text-secondary">
                        <li>Facebook (Meta) Ads</li>
                        <li>Instagram Ads</li>
                        <li>LinkedIn and Twitter Ads</li>
                        <li>Retargeting Campaigns</li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</div>


<div class="container-fluid bg-gradient">
    <div class="container py-5">
        <h2 class="text-white text-center fw-bold justify-text">From Strategy to Success – How We Work
        </h2>
        <div class="row gy-5 py-3">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container ">
        <div class="row justify-content-center py-5">
            <h2 class=" fs-1 fw-bolder  text-center">
                                             <span
                                                 class="text-primary">  SEM</span> vs. SMM: Which Strategy Fits Your Business?</h2>
            <p class="text-secondary">Both SMM and SEM are valuable, but their applications depend on your business goals. SMM is ideal for brand building and engagement, while SEM is great for capturing intent-driven leads.
            </p>

            <div
                class="col-md-6  d-flex justify-content-md-end pe-md-5 justify-content-center align-self-center pe-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 seo py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center ps-5 fs-1 fw-bold text-white">
                        <div class="py-3 ps-3">
                            <p class="title fs-3 text-white me-5">SEM</p>
                        </div>
                        <div class="py-3 pe-2">
                            <i class="fa fa-thumbs-up fs-4 me-3"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4 t">Search Engine Marketing (SEM)</h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Targeting on Google, Yahoo, and Bing line search engines.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Generate potential leads, conversions, and drive traffic to the website

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Focuses on visitors based on search intent, location, and keywords

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Prioritize text-based content or ads such as Google ads and search engine results.



                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Provide instant results through paid ads targeting active searches.

                            </p>
                        </li>

                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                The charges are typically based on PPC (Pay-Per-Click) or CPM (Cost-per-impression)


                            </p>
                        </li>
                    </ul>

                </div>
            </div>
            <div
                class="col-md-6 d-flex align-self-center justify-content-center justify-content-md-start ps-md-5 ps-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 ppc py-5">


                    <div
                        class="flag-div d-flex justify-content-between align-items-center pe-2 fs-1 fw-bold text-white">
                        <div class="py-3 pe-2">
                            <p class="title fs-3 text-white me-5">PPC</p>
                        </div>
                        <div class="py-3">
                            <i class="fa fa-thumbs-up fs-4 me-5"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4">Social Media Marketing (SMM):
                    </h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Targeting on Facebook, LinkedIn, Instagram, and Twitter-like social media platforms

                            </p>
                        </li>
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Focus on building brand awareness, and audience engagement to foster relationships.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Focuses on audiences according to their demographics, behaviors, and interests.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Prioritize visual and interactive content such as videos, animated stories, and images.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Takes more time to provide organic traffic, and build engagement, through paid campaigns the process can be faster.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Takes more time to provide organic traffic, and build engagement, through paid campaigns the process can be faster.

                            </p>
                        </li>

                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                The charges may fluctuate with audience reach, impressions, and clicks on social platforms.

                            </p>
                        </li>


                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid ">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 col-md-6 align-self-center">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image"
                         class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="text-primary fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline text-primary">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 ">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                  <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                  </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                      What types of online advertising do you offer?
                                      </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We offer a range of advertising services, including ads on google (search engine ads), youtube (video ads), facebook, instagram, linkedin and twitter (social media ads). We further provide other PPC advertising services including search ads, display ads, shopping, ads and video ads.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span
                                                    class="fs-6 fw-bold">
                                                     <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                         <div class="col-11 text-primary">
                         How do I know which advertising agency is right for my business?

                                                         </div>
                                                     </div>
                                                    </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                There are a number of advertising agencies available, to choose the most appropriate one, follow some basic steps such as checking your budget and goal first and shortlist the agency accordingly. Moreover, go through their previous work, client reviews, area of expertise, market status, etc. Ensure your chosen advertising agency is genuine, has appropriate customer service, and communication, and offers customized solutions that fit your unique audience base.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon float-start">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                       How much does it cost to run an online ad campaign?

                                                                </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Usually, various SMM and SEM advertising agencies charge different fees. However, at Volmint, the charges depend on your budget, campaign goals, and platform. We provide customized plans to ensure effective results, within your budget.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon">
                                                                        <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                   How long does it take to see results from online ad campaigns?

                              </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Results vary by campaign type, budget and other factors, but many ad campaigns show measurable result within a few days to weeks with consistent growth over time.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                                        <span class="fs-6 fw-bold">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <div class="encircled-icon">
                                                                <i class="fas fa-question fw-bold"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-11 text-primary">
                     How can I increase my return on investment (ROI) from online ads?

                                                                </div>
                                                            </div>
                                                        </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Target the right audience, use compelling content and track performance. At Volmint - the best advertising agency, we continuously optimize campaigns to maximize ROI for your business.


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-3 p-lg-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to Take Your Business Growth to the Next Level?
            </div>
            <div class="col-md-6 align-self-center col-12 text-md-end">
                <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                        (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
