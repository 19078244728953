<div class="page-title-area pt-5 position-relative  item-bg1">
    <div class="container pt-md-5 pb-5 ">
        <div class="pt-5 gy-2 gy-md-0 d-sm-flex justify-content-around">
            <div *ngFor="let group of starGroups; let i = index" class="mx-2 text-center">
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                    <i class="fa fa-star text-warning mx-1"></i>
                </ng-container>
                <p class="star-text">{{ group.text }}</p>
            </div>
        </div>
        <h1 class="fw-bold color-white mt-4 mb-0 text-center glowing-heading">Join Us and Thrive Together!</h1>

    </div>
    <div class="container-fluid  d-flex justify-content-center">
        <div class="position-absolute container shadow bg-white px-5 pt-3  mx-auto">
            <div class="row">
                <div
                    class="col-lg-6 pb-lg-4 pb-xl-0 col-xl-5 text-center text-lg-start position-relative align-self-center">
                    <div class="earnings-container ">
                        <div class="text-center">
                            <p class="earn text-warning">Earn upto</p>
                            <p class="text-white my-0 fw-bold fs-3"> 25%</p>
                        </div>
                    </div>
                    <h2 class="mt-5 fw-bold w-100" style="font-size: 50px">Our business, powered by our products.</h2>
                    <p class="my-3 text-secondary">Unlock up to <span class="text-orange fw-bold">25% commission </span>
                        on every sale you refer. A straightforward affiliate program that lets you effortlessly monetize
                        your content!</p>
                    <button (click)="openModal()" class="px-lg-3 p-2 py-lg-2 landing-button border-0 fs-6 nav-link bg-grey fw-bold">Start Referring
                    </button>
                    <button class="px-lg-3 mx-2 p-2 py-lg-2 landing-button border-0 fs-6 nav-link bg-grey fw-bold" (click)="goTo(environment.adminWebUrl+'auth/login')">Partner Login</button>

                </div>
                <div class="col-lg-6 col-xl-7 justify-content-center justify-content-lg-end d-flex align-items-center ">
                    <img src="assets/img/banner/refrels.png" class=" w-75" alt="">
                </div>
            </div>
        </div>
    </div>

</div>
<div style="height: 200px" class="d-lg-block d-none"></div>
<div style="height: 430px" class="d-block d-lg-none"></div>

<section class="">
    <div class="container-fluid  pb-0 pt-6 mt-5">
        <div class="container   ">
            <div class="row mx-auto">
                <h2 class="text-center fs-1 fw-bold justify-text text-b mt-2">Sell our products under your own brand
                </h2>
                <div class="row pb-5 gy-0 gy-lg-1  mx-auto justify-content-center">
                    <div class="px-2 col-12 col-md-6 col-lg-4 py-4 cursor-pointer" [routerLink]="'/ums'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/ums.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">UMS</h3>
                            <p class="text-secondary">
                                The University Management System is crafted to simplify complex processes and enhance
                                overall management.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/cms'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/cms-logo.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">CMS</h3>
                            <p class="text-secondary">CMS software streamlines college tasks, including student
                                information, e-learning, attendance tracking, and resource allocation.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/cgms'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/cgms-no-bg.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">CGMS</h3>
                            <p class="text-secondary">The College Group Management System helps your institute to work
                                in a more advanced way.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/ims'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/ims.png " alt="" height="100px">
                            </div>
                            <h3 class="fs-3 fw-bold">IMS</h3>
                            <p class="text-secondary">Institute Management Software is a web-based tool that can help
                                with the administrative management chores and requirements of your institute.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/lms'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/lms-no-bg.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">LMS</h3>
                            <p class="text-secondary">LMS can easily develop, administer, deliver, and track online
                                courses using its user-friendly
                            </p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/enroll'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/enroll.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">Enroll</h3>
                            <p class="text-secondary">Enroll, an admission management system designed to streamline and
                                elevate your enrollment experience with cutting-edge technology. </p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'argus'">
                        <div class="border p-2 py-4 text-center h-100">
                            <div class="w-25 mx-auto">
                                <img src="assets/img/project/project-logo/argus.png " height="100px" alt="">
                            </div>
                            <h3 class="fs-3 fw-bold">Argus</h3>
                            <p class="text-secondary">Argus provides a comprehensive suite of tools for monitoring,
                                evaluating, and securing the online examination process.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor- py-4" [routerLink]="'/vcalling'">
                        <div class="border p-2 py-4 text-center h-100 ">
                            <img src="assets/img/project/project-logo/v-calling-black.png " height="100" alt="">
                            <h3 class="fs-3 fw-bold">V-Calling</h3>
                            <p class="text-secondary">V-Calling is your all-in-one solution for crystal-clear voice
                                calls, integrating cutting-edge technology with user-friendly features.</p>
                        </div>
                    </div>
                    <div class="px-2 col-12 col-md-6 col-lg-4 cursor-pointer py-4" [routerLink]="'/eclinic'">
                        <div class="border p-2 py-4 text-center h-100">
                            <img src="assets/img/project/project-logo/eclinic-logo.png " height="100" alt="">
                            <h3 class="fs-3 fw-bold">E-Clinic</h3>
                            <p class="text-secondary">The software helps you spend less time on administrative tasks,
                                whether you are a big healthcare chain or an individual practitioner. </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section>
    <div class="container  ">
        <h2 class="text-center  fs-1 fw-bold justify-text text-b ">It's your business. Let it shine brighter than
            ever!</h2>
        <div class="row text-center text-lg-start">
            <div class="col-12 text-center col-md-6">
                <img src="assets/img/banner/profit2.png" alt="">
            </div>
            <div class="col-12 col-md-6 align-self-center">
                <h3 class="fs-1 fw-bold text-center text-lg-start ">Let's talk about earnings.</h3>
                <p>Unlike other reseller programs that just talk about commission rates, ours gives you the freedom to
                    set your own margins. You decide how much you earn from each product. Feels great to have that kind
                    of control, doesn’t it?</p>
                <button (click)="openModal()" class="px-lg-3 p-2 py-lg-2 landing-button border-0 fs-6 nav-link bg-grey fw-bold">Start Referring
                </button>
                <button class="px-lg-3 mx-2 p-2 py-lg-2 landing-button border-0 fs-6 nav-link bg-grey fw-bold" (click)="goTo(environment.adminWebUrl+'auth/login')">Partner Login</button>

            </div>

        </div>
    </div>
</section>
<section class="container-fluid  pb-0">
    <div class="container pt-5  ">
        <div class="row mx-auto">
            <h2 class="text-center fs-1 fw-bold justify-text text-b mt-2">How to Become Our Partner
            </h2>
            <div class="row pb-5 gy-1 justify-content-center">
                <div class="px-2 col-12 col-md-6 col-lg-4 py-4">
                    <div class=" p-3 text-center h-100">
                        <i class="fa-solid fa-right-to-bracket fs-1 text-orange"></i>
                        <h3 class="fs-4 mt-2 fw-bold">Sign Up</h3>
                        <p class="text-secondary justify-text">Join hundreds of creators, publishers, and bloggers who
                            are successfully earning through Volmint's Referral Program. Whether you're a seasoned
                            professional or just starting out, our program offers the tools and opportunities to help
                            you grow your income.
                        </p>
                    </div>
                </div>
                <div class="px-2 col-12 col-md-6 col-lg-4 py-4">
                    <div class="p-3 text-center h-100">
                        <i class="fa-solid fa-comments-dollar fs-1 text-primary"></i>
                        <h3 class="fs-4 mt-2 fw-bold">Recommend</h3>
                        <p class="text-secondary justify-text">Share our products with your audience
                            and start earning effortlessly. We provide customized linking tools tailored for large
                            publishers, individual bloggers, and social media influencers, making it easier than ever to
                            connect with your followers and maximize your reach.</p>
                    </div>
                </div>
                <div class="px-2 col-12 col-md-6 col-lg-4 py-4">
                    <div class=" p-3 text-center h-100">
                        <i class="fa-solid fa-sack-dollar text-success fs-1"></i>
                        <h3 class="fs-4 mt-2 fw-bold">Earn</h3>
                        <p class="text-secondary justify-text">Earn up to 25% in referral fees from qualifying purchases
                            and programs, giving you the opportunity to boost your income significantly. With our
                            competitive conversion rates and user-friendly tools, you can easily turn your audience's
                            engagement into consistent earnings.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<div class="container-fluid ">
    <div class="container">
        <div class="row">
            <h2 class="fs-1 fw-bold text-center">Frequently Asked Questions</h2>
            <div class="accordion py-5" id="faqAccordion">
                <div class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #6752A9;">  <div class="encircled-icon">
    </div> 1. What additional benefits come with the reseller plan?</span>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show " aria-labelledby="headingOne"
                         data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text">
                            Free Volmint software, including email marketing tools, to support your business.
                        </div>
                    </div>
                </div>
                <div class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
    </div>2. What if I'm not happy with the reseller program</span>
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                         data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text">
                            We provide a money-back guarantee if you're not satisfied with the reseller program.
                        </div>
                    </div>
                </div>
                <div class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
    </div>3. &nbsp;Who sets the pricing on my referral storefront</span>
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                         data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text">
                            You have the flexibility to set individual pricing for products and services, run
                            special sales and promotions, and offer discounted prices to specific customers. We also
                            provide a global pricing option that allows you to adjust the pricing for all your products
                            at once.
                        </div>
                    </div>
                </div>
                <div class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
    </div>4. How does the commission payment process work?</span>
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                         data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text">
                            We provide several payout options for your commissions (UPI, Cash, Check). Commissions
                            earned in one month are typically paid at the end of the following month.
                            For example, if you earn commission from your reseller hosting sales in January, you'll
                            receive payment at the end of February.
                        </div>
                    </div>
                </div>
                <div class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
    </div>5. Who is responsible for providing customer service to clients?
</span>
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                         data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text">
                            We have a dedicated team available 24/7 via phone or email to support clients.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png'); background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 align-self-center text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" [routerLink]="'/contact'">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal-overlay" *ngIf="isModalVisible" (click)="closeModal()">
    <div class="modal-box " (click)="$event.stopPropagation()">
        <div>
            <div class="container px-0  ">
                <div class="w-100 rounded-top py-2 bg-153291">
                    <p class="fs-4 fw-bold text-center text-white"><i class="fa fa-handshake me-3"></i>Fill the form to become our partner</p>
                </div>
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
                    <div class="form-group px-5 mt-3">
                        <input id="name" type="text" formControlName="name" class="form-control py-2"
                               placeholder="Enter your name"/>
                        <div *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched" class="text-danger">
                            <small *ngIf="userForm.get('name')?.hasError('required')">Name is required.</small>
                            <small *ngIf="userForm.get('name')?.hasError('minlength')">Name must be at least 3
                          mt-1      characters.</small>
                        </div>
                    </div>
                    <div class="form-group px-5 mt-2">
                        <input id="email" type="email" formControlName="email" class="form-control py-2"
                               placeholder="Enter your email"/>
                        <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched"
                             class="text-danger">
                            <small *ngIf="userForm.get('email')?.hasError('required')">Email is required.</small>
                            <small *ngIf="userForm.get('email')?.hasError('email')">Invalid email format.</small>
                        </div>
                    </div>
                    <div class="form-group px-5 mt-2">
                        <input id="contact" formControlName="contact" class="form-control py-2" type="number"
                               placeholder="Enter your contact number"/>
                        <div *ngIf="userForm.get('contact')?.invalid && userForm.get('contact')?.touched"
                             class="text-danger">
                            <small *ngIf="userForm.get('contact')?.hasError('required')">Contact is required.</small>
                            <small *ngIf="userForm.get('contact')?.hasError('pattern')">Contact must be a 10-digit
                                number.</small>
                        </div>
                    </div>
                    <div class="form-group px-5 mt-2">
                        <input id="address" formControlName="address" class="form-control py-2" type="text"
                               placeholder="Enter your address"/>
                        <div *ngIf="userForm.get('address')?.invalid && userForm.get('address')?.touched"
                             class="text-danger">
                            <small *ngIf="userForm.get('address')?.hasError('required')">Address is required.</small>
                        </div>
                    </div>
                    <div class="form-group px-5 mt-2 text-center" *ngIf="userForm?.value?.referred_by_code">
                        <span><b>Referred By : </b> <span
                            class="text-decoration-underline text-success ls-2"><b>{{ userForm?.value?.referred_by_code }}</b></span></span>
                        <!--                        <input id="referred_by_code" formControlName="referred_by_code" class="not-allowed form-control py-2"-->
                        <!--                               placeholder="your referred code" name="referred_by_code" />-->
                    </div>

                    <div
                        class="form-group rounded-bottom  bg-grey py-3 d-flex justify-content-between px-4 mt-3 text-center mt-4">
                        <button class="btn rounded-0 px-4 border-danger py-1 text-danger" (click)="closeModal()">Close</button>
                        <button type="submit" [disabled]="userForm.invalid" class="btn landing-button rounded-0 fs-6 fw-bold py-1 btn-lg">
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
