import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-vsync',
    templateUrl: './vsync.component.html',
    styleUrls: ['./vsync.component.scss']
})
export class VsyncComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Empower Engagement'},
        {text: 'Foster Collaboration'},
        {text: 'Increase Productivity'},
        {text: 'Improved User Experience'},
        {text: 'Flexibility & Freedom'},
        {text: 'Fast Communication '},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Schedule Meetings'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Schedule Lectures'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Online Recording'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Download Chats'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'User-Centric Interface'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Multi-Device Facility'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Customizable Options'}


    ];
    whyChoose = [
        {
            header: '     Tailored Solutions:       ',
            text: ' To fulfill the unique business requirements, we have created highly customized products. For corporate industries to online education or personal use, ensures efficiency and convenience.',
        },
        {
            header: 'Seamless Integration:',
            text: '  Our products or services allow third-party integration to ensure a smooth transition without disrupting your current processes, making collaboration effortless and productive.'
        },
        {
            header: 'Multi-Device Support',
            text: ' We have designed our products that be easily accessible for any device – tablets, smartphones, or desktops, to provide flawless connectivity and enhance productivity.'
        },
        {
            header: 'Cost Efficiency',
            text: 'We have offered multiple packages as per your business demand that fit your budget along with premium features at a competitive price with no compromising on quality. '
        },
        {
            header: 'Safe and Secure:',
            text: ' We take your data safety and security as our priority. By using the best tools and support, we ensure end-to-end encryption safeguarding your data and communications from unauthorized access.'
        },
        {
            header: 'Exceptional Customer Service',
            text: ': Our dedicated support team is available to assist you at every step. Whether it’s setup, troubleshooting, or guidance, we ensure your experience with VSync is smooth and hassle-free.'
        },
    ];
    offerings = [
        {
            text: 'Meeting Management'
        },
        {
            text: 'Collaboration Tools'
        },
        {
            text: 'Analytics & Reporting'
        },
        {
            text: 'User Administration'
        },
        {
            text: 'Device Compatibility'
        },

    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title: Title,
        private meta: Meta,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags();
        this.schema();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "ArgusPage",
            "name": "Volmint Technology - Vsync",
            "url": "https://www.volmint.com/vsync",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",
            "description": 'VSync, the best online meeting and video conferencing system',


        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What is Volmint’s VSync?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " VSync is a video conferencing system designed and owned by Volmint Technologies. It provides seamless connectivity for remote or online video meetings and lectures. In addition to hearing what others are saying, it offers the advantage of allowing one to see their facial expressions and responses."
                    }
                },
                {
                    "@type": "Question",
                    "name": " Why VSync is essential for the educational institute?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " VSYNC transforms the way educational institutions operate by enabling seamless virtual communication and collaboration. With its secure, feature-rich platform, it addresses the evolving needs of modern education, making learning more accessible and efficient"
                    }
                },
                {
                    "@type": "Question",
                    "name": "What are the advantages of using VSync?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Apart from seamless communication, VSync allows numerous advantages such as customization options as per your need. Additionally, you can schedule meetings or lectures from the software and download them as well"
                    }
                },
                {
                    "@type": "Question",
                    "name": "What is the process to use VSync?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " It's simple to join a VSync. All you need is a gadget that has a camera, a microphone, and a video conferencing software. After that, simply use your device to join the video conference at the designated time when you receive an invitation"
                    }
                },
                {
                    "@type": "Question",
                    "name": " What are the charges of VSync?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Volmint’s products are very affordable with no compromise on quality. To know more about the charges and other details regarding VSync, get in touch with our customer team. "
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle(' VSync, the best online meeting and video conferencing system ');
        this.meta.updateTag({
            name: 'description',
            content: 'Experience seamless connectivity and communication with VSync - A Video Conferencing system by Volmint Technologies to ensure business productivity'
        });
        this.meta.updateTag({
            name: 'keyword',
            content: 'VSync, the best online meeting and video conferencing system'
        });
    }
}
