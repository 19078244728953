import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BlogService} from '../../../shared/services/blog.service';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    blogs: any;

    productCount = 0;
    maxProductCount = 10;
    productivyCount = 0;
    maxProductivityCount = 20;
    industries = 0;
    maxIndustriesCount = 30;
    intervalId: any;
    image1Src = 'assets/img/landing-new/all.png';
    image2Src = 'assets/img/landing-new/all1.png';
    activeCategory = 'all';
    slides = [];
    currentSlide = 0;
    interval: any;
    isBlurred = false;

    toggleBlur(state: boolean): void {
        this.isBlurred = state; // Ensures the blur effect is disabled
    }
    reviews = [
        {
            image: 'assets/img/partner/subharti.jpg',
            name: 'Subharti University',
            review: 'We have been enrolled in Volmint’s UMS since more than a year. We are using the software to manage our administrative tasks as well as faculty and student management. The UMS helps us to manage and oversee our student and faculty data with end-to-end security. It Automates all processes from student counseling to admission and results with zero error and complete authenticity.'
        },
        {
            image: 'assets/img/partner/sgvu-logo.png',
            name: 'Suresh Gyan Vihar University',
            review: 'After using numerous software, we were not satisfied and went to Volmint’s UMS to manage our university operations. With a single platform, we can manage administration, faculty, and students. Also, the software allows to oversee and manage admission, examination, consultation, fees, and social media leads for better outcomes. We are experiencing seamless internal operations with Volmint’s UMS.'
        },
        {
            image: 'assets/img/partner/svsu-logo-whiten.webp',
            name: 'Vivekananda Global University',
            review: 'Since adopting Volmint’s College Management System, we’ve experienced an amazing growth in operational efficiency. Managing our campus operations—student admissions, scheduling, and course management—has become much smoother. We’ve seen a significant improvement in overall performance.\n'
        },
        {
            image: 'assets/img/partner/arnilogo24.jpeg',
            name: 'ARNI University',
            review: 'We were facing challenges with manual data management and a lack of integration between departments. After implementing Volmint’s UMS, we now have a fully integrated system that streamlines admissions, attendance, and grading. We are also pleased with their quick support. '
        },
        {
            image: 'assets/img/partner/img3.png',
            name: 'Education Mitra',
            review: 'As a growing education consultancy, we needed a reliable solution for managing our training programs and student enrollments. Volmint’s software helped us automate our processes, from tracking student progress to generating certification. \n'
        },
        {
            image: 'assets/img/partner/img4.png',
            name: 'EduTantra',
            review: 'Being one of the fastest-growing educational consultants, we were looking for a support that helps us to manage and control our internal operations. Our need led us to Volmint’s UMS software which not only helps us to manage our faculty and student data but also helps us to manage social media leads, admissions, finance, and counselors with a single platform. '
        },
        {
            image: 'assets/img/partner/img5.png',
            name: 'MS Institute of Education',
            review: 'We have been working with Volmint Technologies for the past 2 years and using their software to automate our day-to-day processes. Since partnering with Volmint, we have witnessed significant improvements in operational efficiency, workflows, and enhanced communication across departments. Their intuitive system has greatly simplified our management tasks, allowing us to focus more on academic excellence.'
        },
        {
            image: 'assets/img/partner/img1.png',
            name: 'Axico Ayurveda',
            review: 'Volmint Technologies developed a comprehensive e-commerce platform for us, which has significantly boosted our online sales and customer engagement. The platform is not only aesthetically pleasing but also highly functional and easy to navigate. '
        },
        {
            image: 'assets/img/partner/img2.png',
            name: 'Dr. Axico',
            review: 'The software has significantly streamlined our clinic operations, improving efficiency and patient management. The user-friendly interface and seamless integration with our existing systems have made a noticeable difference in our day-to-day workflow. The team at Volmint was responsive!'
        }
    ];

    constructor(private blogData: BlogService,
                public router: Router,
                private meta: Meta,
                private titles: Title,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.get();
        this.slides = this.chunkArray(this.reviews, 3);
        this.startSlider();
        this.updateMetaTags();
        this.startCounter();
        this.schema();
 }


    schema() {
        const corporationSchema = {
            '@context': 'https://schema.org',
            '@type': 'HomePage',
            name: 'Volmint Technology',
            url: 'https://www.volmint.com/',
            logo: 'https://www.volmint.com/assets/img/white-volmint-logo.png',
            description: 'Volmint Technologies: Leading IT Software Company in India',
            contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+91 9310828501',
                contactType: 'technical support',
                areaServed: 'IN',
                availableLanguage: 'en'
            },
            sameAs: [
                'https://www.facebook.com/VolmintTechnologies',
                'https://x.com/TechVolmint',
                'https://www.instagram.com/volminttechnologies/',
                'https://www.youtube.com/@volminttechnologies/videos',
                'https://www.linkedin.com/company/volmint-technologies/'
            ]
        };

        // FAQ Schema
        const faqSchema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [
                {
                    '@type': 'Question',
                    name: 'What services does Volmint Technologies offer?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'Volmint Technologies is one of the most reliable giant IT hubs for various tech solutions. It offers services including website development, website design, market research, customized software, etc. Volmint’s cutting-edge solutions transformed business and automated internal processes to minimize human efforts and errors. Volmint has customized solutions for any kind of small, MSME, or large business related to any field, such as edtech, finance, etc.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' What products or software do Volmint Technologies offer?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'Being one of the most reliable IT software companies, Volmint has different kinds of products for every business. The company offers university management system, college group management system, college management system, learning management system, enroll- admission management system, e-clinic software, student management system, and ITR management software.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'Does Volmint provide customized solutions??',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'Absolutely, being the most preferred among the many is not ‘luck by chance’; it is the\n' +
                            '                        dedication and hard work of the well-experienced team that delivered not only software but\n' +
                            '                        satisfaction to the clients. Our motto is client gratification, which motivates us to bring the\n' +
                            '                        most suitable solution to their table. We customize our products to align with customer needs.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'What is Volmint’s UMS?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'University Management Systems is one of the best-known and most-buyable products by Volmint\n' +
                            '                        Technologies. Through the software, the universities can manage and control internal operations\n' +
                            '                        such as student enrollment, fee structure, accounts, and other processes with ease, which helps\n' +
                            '                        to decrease manpower and costs.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' Is Enroll a Volmint’s product?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'Yes, Enroll is designed and owned by Volmint Technologies. The product automates the student\n' +
                            '                        admission management process. It has various features, such as multi-university management,\n' +
                            '                        documentation management, multi-counselors and users management, effective lead management, etc.\n' +
                            '                        One can manage and control various student admission phases under one roof, avoiding any kind of\n' +
                            '                        miscommunication.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'How do Volmint Technologies Work?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' Deep market research involves understanding customer needs and desires to provide the best\n' +
                            '                        possible solution. It takes time and effort to complete. Designing the right software requires\n' +
                            '                        understanding the process and how to do it. Maintenance is crucial for maintaining the software\n' +
                            '                        system\'s functionality. Analyzing software performance and usage post-development is essential\n' +
                            '                        for ensuring its longevity. Our models use the latest technology and are robust and advanced.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' What kind of support will clients get after purchasing the software?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' Volmint believes in upgrading business growth, and it is impossible without mutual\n' +
                            '                        collaboration. Thus, we have a dedicated team of problem solvers to help our clients at any\n' +
                            '                        stage, from installing software to becoming professionals. We will assign a dedicated manager to\n' +
                            '                        the customers along with 24/7 chat or call support, a user manual, and video. Moreover, lifetime\n' +
                            '                        free maintenance, training sessions, a free server, third-party tool integration, and resolution\n' +
                            '                        in 48 hours are like perks for the clients.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'What is the cost of the software?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: '  To know more about our software or book a free consultation, get in touch with us via email or\n' +
                            '                        phone. We will discuss your business requirements and offer you a customized solution, along\n' +
                            '                        with the cost. Drop us a line at support@volmint.com or call us at +91 9310828501.'
                    }
                },



            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    chunkArray(array: any[], size: number): any[][] {
        const result: any[][] = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    startSlider() {
        this.interval = setInterval(() => {
            this.nextSlide();
        }, 10000);
    }

    nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
        this.updateSlider();
    }

    updateSlider() {
        const slider = document.getElementById('slider');
        if (slider) {
            slider.style.transform = `translateX(-${this.currentSlide * 100}%)`;
        }
        this.updateIndicators();
    }

    updateIndicators() {
        const indicators = document.querySelectorAll('.slider-indicators .indicator');
        indicators.forEach((indicator, index) => {
            indicator.classList.toggle('active', index === this.currentSlide);
        });
    }

    truncateText(text: string, wordLimit: number): string {
        if (!text) { return ''; }
        const words = text.split(' ');
        if (words.length <= wordLimit) { return text; }
        return words.slice(0, wordLimit).join(' ') + '...';
    }

    get(params: any = {}) {
        this.blogs = [];
        this.blogData.get(params).subscribe(data => {
            if (data && Array.isArray(data.data)) {

                this.blogs = data.data;
            }
        });
    }

    changeImages(category: string): void {
        this.activeCategory = category;
        switch (category) {
            case 'all':
                this.image1Src = 'assets/img/landing-new/all.png';
                this.image2Src = 'assets/img/landing-new/all1.png';
                break;
            case 'mobile':
                this.image1Src = 'assets/img/landing-new/mobile-app.jpg';
                this.image2Src = 'assets/img/landing-new/mobile-app1.jpg';
                break;
            case 'product':
                this.image1Src = 'assets/img/landing-new/product-design.jpg';
                this.image2Src = 'assets/img/landing-new/1.jpg';
                break;
            case 'web':
                this.image1Src = 'assets/img/landing-new/web-design1.jpg';
                this.image2Src = 'assets/img/landing-new/web-design2.jpg';
                break;
            default:
                this.image1Src = 'assets/img/landing-new/all.png';
                this.image2Src = 'assets/img/landing-new/all1.png';
        }
    }

    startCounter() {
        this.intervalId = setInterval(() => {
            if (this.productCount < this.maxProductCount) {
                this.productCount++;
            }
            if (this.productivyCount < this.maxProductivityCount) {
                this.productivyCount++;
            }
            if (this.industries < this.maxIndustriesCount) {
                this.industries++;
            } else {
                clearInterval();
            }
        }, 50);
    }


    playVideo(videoId: string, thumbnailId: string, playButtonClass: string) {
        const video = document.getElementById(videoId) as HTMLVideoElement;
        const thumbnail = document.getElementById(thumbnailId);
        const playButton = document.querySelector(`.${playButtonClass}`);

        if (video) {
            video.classList.remove('d-none');
            video.style.display = 'block';
            video.play();
        }
        thumbnail?.style.setProperty('display', 'none');
        playButton?.classList.add('d-none');
    }


    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.titles.setTitle('Volmint Technologies: Leading IT Software Company in India');
        this.meta.updateTag({
            name: 'description',
            content: `Volmint Technologies, a trusted and well-known IT software company in India offering innovative solutions for all types of business including education, E-commerce, and institutions.`
        });
        this.meta.updateTag({name: 'keyword', content: `IT Software Company`});
    }
}
