import {Component, Renderer2, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;

    constructor(private contactService: ContactUsService,
                public fb: FormBuilder,
                public alertService: AlertService,
                public modalService: NgbModal,
                private router: Router,
                private meta: Meta,
                private titles: Title,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            contact: ['', Validators.required],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
        this.updateMetaTags();
        this.schema();
    }

    schema() {
        const corporationSchema = {
            '@context': 'https://schema.org',
            '@type': 'ContactPage',
            name: 'Contact - Volmint Technology',
            url: 'https://www.volmint.com/contact',
            logo: 'https://www.volmint.com/assets/img/white-volmint-logo.png',
            description: 'Connect with Volmint Today',
            contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+91 9310828501',
                contactType: 'technical support',
                areaServed: 'IN',
                availableLanguage: 'en'
            },
            sameAs: [
                'https://www.facebook.com/VolmintTechnologies',
                'https://x.com/TechVolmint',
                'https://www.instagram.com/volminttechnologies/',
                'https://www.youtube.com/@volminttechnologies/videos',
                'https://www.linkedin.com/company/volmint-technologies/'
            ]
        };

        // FAQ Schema
        const faqSchema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [
                {
                    '@type': 'Question',
                    name: 'How can I get in touch with Volmint Technologies?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'You can reach us through multiple channels: fill out the contact form on our website,\n' +
                            '                                call us directly at +91 9310828501, or email us at support@volmint.com. Our team is\n' +
                            '                                ready to assist you Monday through Friday from 9:30 AM to 6:30 PM.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' What should I include in my message when contacting support?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: '  To help us assist you more effectively, please include a detailed description of your\n' +
                            '                                issue or inquiry, any relevant account or project information, and your preferred\n' +
                            '                                contact method. The more details you provide, the faster we can resolve your concerns.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'How long does it typically take to get a response?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: '  We strive to respond to all inquiries within 24 hours on business days. For more urgent matters, please indicate the urgency in your message, and we’ll prioritize accordingly.'
                    }
                },
                {
                    '@type': 'Question',
                    name: 'Can I schedule a consultation or demo?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: ' Absolutely! If you’re interested in learning more about our services or products, you can request a consultation or demo by contacting us through our form, mail, or by phone. Well arrange a convenient time to discuss your needs and demonstrate how we can help.'
                    }
                },
                {
                    '@type': 'Question',
                    name: ' What if I need support outside of regular business hours?',
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: 'For urgent issues that arise outside of our regular business hours, please reach out  through our support email, and we’ll make every effort to address your needs as quicklyas possible.'
                    }
                }



            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }



    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.titles.setTitle('Connect with Volmint Today');
        this.meta.updateTag({
            name: 'description',
            content: `Connect with Volmint to discuss your needs and explore how we can help your business grow. Contact us via mail, call, or in person for IT solutions and support `
        });
        this.meta.updateTag({name: 'keyword', content: `Contact Us, Connet With Volmint`});
    }

}
