<div class="page-title-area item-bg1">
    <div class="container">
        <app-background-animation></app-background-animation>
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">Blogs</p>
            <p class="fs-1 color-white text-center fw-bold glowing-sub-heading">Explore Our Latest Tech Trends and
                Innovations</p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="blog-details-area ptb-110">

    <div class="container">
        <div class="row">
            <!-- Left Sidebar with Headings -->
            <div class="col-12 col-md-3">
                <div class="nav flex-column nav-pills">
                    <a
                        class="nav-link"
                        *ngFor="let heading of headings; let i = index"
                        (click)="scrollToSection(i)"
                        [id]="'sidebar-link-' + i">
                        {{ heading.text }}
                    </a>
                </div>
            </div>

            <!-- Right Content Area -->
            <div class="col-12 col-md-9">
                <div *ngFor="let section of contentSections; let i = index" [id]="'section-' + i">
                    <!-- Render the content first -->



                   <div *ngIf="section.content">
          <div class="article-image" *ngIf="i==0">
                               <img src="{{selectedData.blog_banner.url}}" alt="image">
                           </div>


                        <strong>{{section.headingText}}</strong>
                        <div [innerHTML]="section.content"></div>
                    </div>

                    <!-- Render heading after the content -->
                    <div *ngIf="section.tag === 'h1'" (click)="scrollToSection(section.id)"><h1>{{ section.text }}</h1></div>
                    <div *ngIf="section.tag === 'h2'" (click)="scrollToSection(section.id)"><h2>{{ section.text }}</h2></div>
                    <div *ngIf="section.tag === 'h3'" (click)="scrollToSection(section.id)"><h3>{{ section.text }}</h3></div>
                    <div *ngIf="section.tag === 'h4'" (click)="scrollToSection(section.id)"><h4>{{ section.text }}</h4></div>
                    <div *ngIf="section.tag === 'h5'" (click)="scrollToSection(section.id)"><h5>{{ section.text }}</h5></div>
                    <div *ngIf="section.tag === 'h6'" (click)="scrollToSection(section.id)"><h6>{{ section.text }}</h6></div>
                </div>
            </div>

        </div>
    </div>


    <!--    <div class="container">
            <div class="row">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details">
                        <div class="article-image">
                            <img src="{{selectedData.blog_banner.url}}" alt="image">
                        </div>

                        <div class="article-content">

                            <h3>{{ selectedData.heading }}</h3>

                            <span [innerHTML]="selectedData.content" style="text-align: justify;"></span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                </div>
            </div>
        </div>-->


   <!-- <div class="row">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="blog-details">
                <div class="article-image">
                    <img src="{{selectedData?.blog_banner.url}}" alt="{{selectedData?.banner_alt}}">
                </div>
                <div class="article-content">
                    <h1>{{ selectedData?.heading }}</h1>
                    <div *ngFor="let group of contentData">
                        <div *ngIf="group.heading">
                            <h2>{{ group.heading }}</h2>
                        </div>
                        <div class="blog-details">
                            <div class="article-image">
                                <img src="{{selectedData.blog_banner.url}}" alt="image">
                            </div>

                            <div class="article-content">

                                <h3>{{ selectedData.heading }}</h3>

                                <span [innerHTML]="selectedData.content" style="text-align: justify;"></span>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
        </div>
        <div class="col-lg-2">
        </div>
    </div>-->
</section>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
