<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Comprehensive Cloud & DevOps Solutions </span>
                            by Volmint Technologies</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            offer comprehensive Cloud & DevOps solutions that cover every aspect of server management.
                            Our services include Managed Cloud Services, DevOps, and Containers, ensuring seamless
                            scalability, automation, and efficiency for your infrastructure. With our expert team, we
                            help businesses streamline operations, reduce costs, and accelerate deployment through
                            state-of-the-art technologies. Whether you are looking to optimize your cloud environment or
                            automate your workflows, Volmint Technologies provides the tools and expertise to drive
                            success in today's digital landscape.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="card banner-bg-color md:mr-0 position-relative h-100 w-100 border-0 shadow-lg d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/server-management/introduction-website-development.jpg"
                             alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid parallax1">
    <div class="container py-5">
        <div class="row animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/server-management/about-server-management.jpg"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Managed Cloud Services
                    <span class="brand-primary-text-color">Scalable and Secure Cloud Infrastructure</span></h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">At Volmint Technologies, our Managed Cloud
                    Services ensure your business leverages the full potential of cloud computing with maximum
                    efficiency and security. We provide end-to-end cloud management solutions, from initial migration to
                    ongoing optimization, enabling seamless scalability and cost-effective operations. Our expert team
                    handles cloud infrastructure setup, performance monitoring, and proactive maintenance, ensuring your
                    systems are always secure and performing at their peak. Whether you are expanding your cloud
                    environment or optimizing current resources, we tailor our services to meet your unique business
                    needs, allowing you to focus on growth and innovation.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service1">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service1"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Robust Managed Cloud Services for Seamless
            Operations</h1>
        <p class="text-white text-center">Our Managed Cloud Services are tailored to provide your organization with the
            comprehensive support it needs to thrive in the cloud environment. We begin with Architecting & Designing
            custom solutions that align with your specific business objectives, ensuring a solid foundation for your
            cloud strategy. Through detailed Cloud Assessment, we analyze your existing infrastructure, identifying
            opportunities for optimization and cost savings. Our seamless Integration services facilitate the smooth
            transition of your applications and data to the cloud, minimizing disruptions to your operations. With
            dedicated Administration, we handle the complexities of cloud management, allowing your team to focus on
            core business initiatives. Our commitment to excellence extends to providing 24/7 Support, ensuring that
            your systems are always monitored and maintained for optimal performance. In addition, we implement
            comprehensive Disaster Recovery and Backups solutions to safeguard your critical data, ensuring business
            continuity in the event of unforeseen incidents. Finally, our robust Security measures protect your cloud
            environment from potential threats, providing peace of mind as you leverage the power of the cloud. Partner
            with us to unlock the full potential of cloud technology and drive your business forward.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose1; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax2">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll-right">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder text-white">DevOps Services
                    <span class="brand-primary-text-color"> Accelerating Innovation with Seamless Automation</span></h2>
                <p class="fs-6 text-white text-wrap justify-text">Our DevOps Services at Volmint Technologies are
                    designed to transform the way your teams develop, deliver, and maintain applications. By integrating
                    development and operations, we streamline workflows, enabling faster releases, increased
                    collaboration, and improved efficiency. Our DevOps solutions cover everything from continuous
                    integration (CI) and continuous delivery (CD) pipelines to automated testing and infrastructure as
                    code (IaC), ensuring that your development cycles are shorter, more reliable, and highly scalable.
                    With a focus on automation and agility, we help you achieve faster time-to-market while maintaining
                    high-quality standards, driving innovation and business success.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service2">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service2"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/server-management/accelerate-business-server-management.jpg"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Streamlined DevOps Services for Accelerated
            Software Delivery</h1>
        <p class="text-white text-center">Our DevOps Services are designed to enhance collaboration and streamline
            workflows throughout the software development lifecycle. We focus on Architecting & Designing solutions that
            align with your business goals while ensuring scalability and flexibility. Our Extended Orchestration
            capabilities facilitate seamless coordination across development and operations teams, optimizing resource
            utilization and reducing time to market. With a robust Continuous Integration process, we enable frequent
            code updates, allowing for rapid feedback and early defect detection. Our Continuous Deployment practices
            ensure that your applications are consistently delivered to production, enhancing agility and responsiveness
            to market demands. We also integrate SAST (Static Application Security Testing) to proactively identify
            vulnerabilities in your codebase, while our Pipeline Integration ensures that all tools work together
            smoothly to automate processes. Finally, we emphasize rigorous Validation practices to confirm that each
            release meets quality standards, providing you with reliable software that meets customer expectations.
            Partner with us to transform your development processes and achieve superior operational efficiency.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose2; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax3">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/server-management/about-server-management.jpg"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Container Services
                    <span class="brand-primary-text-color"> Enhancing Flexibility with Scalable Deployments</span></h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">At Volmint Technologies, our Container
                    Services enable businesses to build, manage, and scale applications with unmatched efficiency and
                    flexibility. Containers allow your applications to run consistently across different environments,
                    ensuring seamless deployment and minimizing compatibility issues. We leverage industry-leading tools
                    like Docker and Kubernetes to create lightweight, portable, and scalable environments for your
                    applications. Our services include container orchestration, management, and monitoring, ensuring
                    optimal resource usage and high availability. By adopting containerized solutions, your organization
                    can reduce costs, improve agility, and accelerate time-to-market, all while maintaining secure,
                    stable, and predictable deployments.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service3">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service3"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Comprehensive Container Services for Scalable
            Application Management</h1>
        <p class="text-white text-center">
            In today's fast-paced digital landscape, leveraging containers is essential for developing, deploying, and
            managing applications efficiently. Our Comprehensive Container Services offer a robust suite of solutions
            designed to optimize your application lifecycle. With industry-leading technologies like Kubernetes, Docker,
            and OpenShift, we empower businesses to harness the power of containerization for enhanced scalability and
            flexibility. Whether you're looking to streamline your deployment processes, improve resource utilization,
            or ensure high availability, our expert team provides tailored strategies that align with your unique
            requirements. We enable seamless integration and management across various cloud environments, ensuring that
            your applications are not only resilient but also primed for future growth. Partner with us to unlock the
            full potential of container technology and elevate your application management to new heights.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose3; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Effortlessly <span
                    class="brand-primary-text-color"> Empowering Your Business with  </span>Cloud & DevOps Services</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Our Cloud & DevOps Services are designed to
                    empower your business with the agility, scalability, and efficiency required to thrive in today's
                    competitive landscape. Through our Managed Cloud Services, we provide expert guidance in
                    architecting and managing your cloud infrastructure, ensuring seamless integration and optimal
                    performance. This not only enhances operational efficiency but also allows for significant cost
                    savings. Complementing this, our DevOps solutions foster collaboration between development and
                    operations teams, streamlining the software development lifecycle and accelerating your time to
                    market. By implementing best practices in continuous integration and deployment, we enable you to
                    deliver high-quality software rapidly and reliably. Additionally, our expertise in Containers
                    facilitates the creation and management of portable applications, ensuring that your solutions run
                    consistently across various environments. Together, these services equip your organization with the
                    tools and strategies needed to innovate faster, respond to market changes, and enhance overall
                    productivity, driving sustainable growth and success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll">
                <img src="assets/img/landing-new/server-management/manage-server-management.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/server-management/why-server-management.jpg"
                     class="w-100" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Choose Us for<span
                    class="brand-primary-text-color"> Your Cloud & DevOps Services?</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Choosing the right partner for your Cloud &
                    DevOps Services is critical to achieving operational excellence and driving innovation. At Volmint
                    Technologies, we bring a wealth of experience and a proven track record, having successfully managed
                    over 500 cloud deployments and streamlined development processes for numerous clients across diverse
                    industries. Our team consists of certified cloud architects and DevOps engineers who are dedicated
                    to staying at the forefront of industry trends and best practices. We leverage cutting-edge
                    technologies, including Kubernetes and Docker, to deliver solutions that enhance scalability and
                    flexibility, reducing deployment times by up to 60%.
                </p>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    Our commitment to customer satisfaction is reflected in our 98% client retention rate, which speaks
                    volumes about the quality of our services and our ability to foster long-term partnerships. We
                    understand that every business has unique needs, and we offer customized solutions that are tailored
                    to your specific requirements, resulting in an average cost reduction of 30% in operational expenses
                    for our clients. With 24/7 support and a focus on security, our managed services ensure that your
                    infrastructure is always up and running, with 99.9% uptime guaranteed. By choosing us, you are not
                    just investing in technology; you are partnering with a team that is dedicated to your success and
                    committed to driving your business forward in a rapidly evolving digital landscape.
                </p>
            </div>
        </div>

    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What are Cloud & DevOps Services?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Cloud & DevOps Services encompass a set of practices and technologies that enable
                                organizations to automate and optimize their software development and deployment
                                processes while leveraging cloud infrastructure for improved scalability, flexibility,
                                and efficiency.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                     How can Managed Cloud Services benefit my organization?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Managed Cloud Services provide expert management of your cloud infrastructure, ensuring
                                optimal performance, security, and cost-effectiveness. This allows your team to focus on
                                core business activities while we handle cloud architecture, integration,
                                administration, and ongoing support.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What is the role of DevOps in software development?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                DevOps bridges the gap between development and operations teams, fostering collaboration
                                and continuous communication. This approach accelerates the software development
                                lifecycle, enhances product quality, and enables quicker release cycles, allowing
                                businesses to respond rapidly to market demands.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           What are containers, and why should we use them?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Containers are lightweight, portable units that package applications and their
                                dependencies, ensuring consistent performance across different environments. Using
                                container technologies like Docker and Kubernetes enables easier deployment,
                                scalability, and management of applications, streamlining your development processes.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How do you ensure the security of our cloud environment?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We implement robust security measures, including encryption, firewalls, and access
                                controls, to protect your cloud infrastructure. Our security protocols are continuously
                                updated to address emerging threats, ensuring the integrity and confidentiality of your
                                data.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
