<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">Innovative Website Development </span>
                            with Dynamic, Custom Admin Dashboards</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            craft stunning, high-performance websites using Angular, Node.js, HTML, CSS, Tailwind CSS,
                            and Material UI. Elevate your digital experience with dynamic, fully customized
                            Laravel-powered admin dashboards designed for effortless management and scalability.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="card banner-bg-color md:mr-0 position-relative h-100 w-100 border-0 shadow-lg d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/website-development/introduction-website-development.jpg"
                             alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/website-development/about-website-development.jpg"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> Website development</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we believe in
                    delivering more than just websites—we craft digital experiences that resonate with your brand and
                    drive results. With a commitment to excellence and transparency, our team combines the latest
                    technologies like Angular, Node.js, HTML, CSS, Tailwind CSS, and Material UI to build responsive,
                    high-performance websites tailored to your needs. We also specialize in dynamic solutions with
                    custom Laravel-powered admin dashboards, giving you full control and scalability. Our focus is
                    always on delivering honest, reliable service with a dedication to your success.</p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Accelerating Business Growth with Innovative
                    <span class="brand-primary-text-color"> Website Development Solutions</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we understand that a
                    strong online presence is crucial for driving business growth in today’s digital landscape. Our
                    innovative website development solutions are designed to elevate your brand, engage your audience,
                    and convert visitors into loyal customers. Utilizing the latest technologies and design principles,
                    we create responsive, user-friendly websites that not only reflect your unique vision but also
                    optimize performance and functionality. Partner with us to transform your online presence and unlock
                    new opportunities for success, ensuring that your business stands out in a competitive marketplace.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/website-development/growth-website-development.jpg"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features of</span> Website Development</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our website
                    development service offers customized solutions that align with your business goals, ensuring a
                    unique online presence. We leverage modern technologies like Angular, Node.js, HTML, CSS, Tailwind
                    CSS, and Material UI to build responsive, high-performance websites that deliver seamless user
                    experiences. For advanced functionality, we provide dynamic websites with custom Laravel-powered
                    admin dashboards for efficient content management. Our user-centric design approach enhances visitor
                    engagement, while best practices in search engine optimization (SEO) improve your website's
                    visibility and ranking. Additionally, we offer ongoing support and maintenance to keep your website
                    secure and performing optimally as your business evolves.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end">
                <img src="assets/img/landing-new/website-development/key-feature-website-development.jpg" alt="Image"
                     class="img-fluid border-radius-10">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Seamlessly Oversee <span
                    class="brand-primary-text-color"> Multiple Operations  </span>with an Integrated
                    and User-Friendly<span
                    class="brand-primary-text-color"> Admin Panel.</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">With our integrated and user-friendly admin
                    panel, you can seamlessly oversee multiple operations from a single platform, simplifying your
                    management tasks and enhancing productivity. This intuitive interface allows you to monitor key
                    metrics, manage content, and track performance effortlessly, empowering you to make informed
                    decisions with ease. Whether you’re updating website content, analyzing user data, or coordinating
                    various aspects of your business, our admin panel provides the tools you need for effective
                    oversight and streamlined operations, helping you focus on growth and innovation.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/website-development/multiple-operation-website-development.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/website-development/why-website-development.jpg"
                     class="ms-2 mt-2 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Do You Need <span
                    class="brand-primary-text-color"> a Website with a Custom Admin Dashboard</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">A website with a custom admin dashboard is
                    essential for businesses looking to enhance efficiency and streamline operations. This tailored
                    solution empowers you to manage your content, track user interactions, and analyze performance
                    metrics all in one place, eliminating the need for multiple tools. With an intuitive interface
                    designed specifically for your business needs, you can make real-time updates, monitor key data, and
                    respond swiftly to market changes. This level of control not only saves time and reduces errors but
                    also enables you to focus on strategic growth. Investing in a website with a custom admin dashboard
                    means equipping your business with the flexibility and insights needed to thrive in today's
                    competitive landscape.
                </p>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Makes Our Website Development Stand Out from
            the Competition?</h1>
        <p class="text-white text-center">What sets our website development apart from the competition is our commitment
            to delivering tailored solutions that reflect your unique brand identity and goals. Utilizing cutting-edge
            technologies like Angular, Node.js, and Tailwind CSS, we create high-performance, responsive websites that
            prioritize user experience through intuitive design. Our robust functionality includes advanced features
            tailored to your business needs, transforming your site into a powerful growth tool. We also offer
            comprehensive support, ensuring your website remains secure and up-to-date long after launch. With a proven
            track record of successful projects and satisfied clients, we continually strive to exceed expectations,
            making us a trusted partner in your digital journey.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-7 py-md-5">
                <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive
                    <span class="brand-primary-text-color"> Website development Modules </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a holistic
                    approach to mobile application development through our comprehensive modules designed to address
                    every aspect of the process. Our structured framework ensures seamless integration, enhanced
                    functionality, and a superior user experience.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/landing-new/website-development/slider-6.png" class="img-fluid"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                            What is a custom admin dashboard, and why do I need one?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                A custom admin dashboard is a tailored interface that allows you to manage your website
                                efficiently from a single platform. It provides you with the tools to update content,
                                monitor performance, and analyze user behavior easily. With a custom dashboard, you can
                                make real-time changes, access key metrics, and streamline various operations, giving
                                you full control over your online presence. This means less time managing your website
                                and more time focusing on growing your business.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                     How long does it take to develop a website with a custom admin dashboard?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The timeline for developing your website and custom admin dashboard can vary based on
                                the complexity of your requirements and the features you need. Typically, the
                                development process can take anywhere from a few weeks to a few months. Our team will
                                work closely with you to establish clear timelines and milestones, ensuring that we
                                deliver a high-quality product within a timeframe that suits your business needs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            Can I update the content on my website without technical expertise?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! One of the primary benefits of our custom admin dashboard is its
                                user-friendly design. You don’t need to be a tech expert to make updates. Our dashboard
                                allows you to easily edit text, upload images, and manage multimedia content with just a
                                few clicks. We also provide training and ongoing support to ensure you feel confident
                                navigating your new admin panel.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What kind of support do you offer after the website is launched?
                                        </div>
                                    </div>


                                </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                At Volmint Technologies, our commitment to your success continues long after your
                                website goes live. We offer ongoing support that includes maintenance, updates, and
                                troubleshooting. Whether you need assistance with new features, security updates, or
                                performance optimization, our dedicated support team is here to help. We also provide
                                documentation and resources to guide you through common tasks and challenges.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            How do you ensure my website is secure and performs optimally?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We take website security and performance very seriously. Our development process
                                includes implementing industry-standard security measures, such as SSL certificates,
                                secure coding practices, and regular backups to protect your data. For performance
                                optimization, we focus on techniques like caching, image compression, and responsive
                                design to ensure your site loads quickly and functions smoothly across all devices.
                                Additionally, we conduct thorough testing before launch to identify and address any
                                potential issues, giving you peace of mind.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
