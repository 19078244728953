import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
    selector: 'app-website-development',
    templateUrl: './website-development.component.html',
    styleUrls: ['./website-development.component.scss']
})
export class WebsiteDevelopmentComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Customized Solutions'},
        {text: 'Modern Technologies'},
        {text: 'User-Centric Design'},
        {text: 'SEO Optimization'},
        {text: 'Dynamic Functionality'},
        {text: 'Ongoing Support and Maintenance'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Tailored Designs'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Responsive Layouts'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Robust Functionality'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'User-Friendly Interface'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'SEO-Optimized'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Fast Loading Speeds'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Secure and Scalable'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Integrated Analytics'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Ongoing Support'}

    ];
    whyChoose = [
        {
            header: 'Tailored Solutions',
            text: 'We believe that every business is unique. Our website development process is fully customized to reflect your brand\'s identity and meet your specific goals, ensuring that you receive a solution that perfectly aligns with your vision.'
        },
        {
            header: 'Cutting-Edge Technologies',
            text: 'We utilize the latest frameworks and tools, such as Angular, Node.js, and Tailwind CSS, to build high-performance websites. This allows us to deliver modern, responsive designs that are not only visually appealing but also optimized for speed and efficiency.'
        },
        {
            header: 'User-Centric Design',
            text: 'Our approach focuses on creating intuitive, user-friendly interfaces that enhance the customer experience. We prioritize usability to ensure that visitors can easily navigate your site, find the information they need, and engage with your content effectively.'
        },
        {
            header: 'Robust Functionality',
            text: 'We go beyond aesthetics by integrating advanced features tailored to your business needs, including e-commerce capabilities, booking systems, and custom applications, transforming your website into a powerful tool for growth and engagement.'
        },
        {
            header: 'Comprehensive Support',
            text: 'Our commitment to our clients doesn’t end at launch. We provide ongoing maintenance, updates, and support to ensure your website remains secure, up-to-date, and functioning optimally, allowing you to focus on what you do best—running your business.'
        },
        {
            header: 'Proven Track Record',
            text: 'With a portfolio of successful projects and satisfied clients, we have established ourselves as a trusted partner in website development. Our dedication to quality and customer satisfaction drives us to continually innovate and exceed expectations, setting us apart from the competition.'
        },
    ];
    offerings = [
        {
            text: 'Custom Design and Branding'
        },
        {
            text: 'Content Management System (CMS)'
        },
        {
            text: 'E-commerce Functionality'
        },
        {
            text: 'SEO and Performance Optimization'
        },
        {
            text: 'Analytics and Reporting'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.schema();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "WebsiteDevelopmentPage",
            "name": "Volmint Technology - Website-Development",
            "url": "https://www.volmint.com/website-development",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": " What is a custom admin dashboard, and why do I need one?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  A custom admin dashboard is a tailored interface that allows you to manage your website  efficiently from a single platform. It provides you with the tools to update content, monitor performance, and analyze user behavior easily. With a custom dashboard, you can  make real-time changes, access key metrics, and streamline various operations, giving  you full control over your online presence. This means less time managing your website  and more time focusing on growing your business."
                    }
                },
                {
                    "@type": "Question",
                    "name": "  How long does it take to develop a website with a custom admin dashboard?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  The timeline for developing your website and custom admin dashboard can vary based on  the complexity of your requirements and the features you need. Typically, the  development process can take anywhere from a few weeks to a few months. Our team will  work closely with you to establish clear timelines and milestones, ensuring that we   deliver a high-quality product within a timeframe that suits your business needs."
                    }
                },
                {
                    "@type": "Question",
                    "name": " Can I update the content on my website without technical expertise?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Absolutely! One of the primary benefits of our custom admin dashboard is its user-friendly design. You don’t need to be a tech expert to make updates. Our dashboard allows you to easily edit text, upload images, and manage multimedia content with just a  few clicks. We also provide training and ongoing support to ensure you feel confident   navigating your new admin panel."
                    }
                },
                {
                    "@type": "Question",
                    "name": " What kind of support do you offer after the website is launched?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " At Volmint Technologies, our commitment to your success continues long after your  website goes live. We offer ongoing support that includes maintenance, updates, and  troubleshooting. Whether you need assistance with new features, security updates, or  performance optimization, our dedicated support team is here to help. We also provide   documentation and resources to guide you through common tasks and challenges."
                    }
                },
                {
                    "@type": "Question",
                    "name": "  How do you ensure my website is secure and performs optimally?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " We take website security and performance very seriously. Our development process\n" +
                            "                                includes implementing industry-standard security measures, such as SSL certificates,\n" +
                            "                                secure coding practices, and regular backups to protect your data. For performance\n" +
                            "                                optimization, we focus on techniques like caching, image compression, and responsive\n" +
                            "                                design to ensure your site loads quickly and functions smoothly across all devices.\n" +
                            "                                Additionally, we conduct thorough testing before launch to identify and address any\n" +
                            "                                potential issues, giving you peace of mind."
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


}
