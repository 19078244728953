<!--<section class="banner">-->
<!--    <div class="container-fluid bg-sea-green mt-5">-->
<!--        <div class="container mt-5 pt-5">-->
<!--            <div class="row g-5">-->
<!--                <div class="col-md-6 d-flex text-center text-md-start align-items-center">-->
<!--                    <div>-->
<!--                        <h1 class="fw-bolder font-56"><span-->
<!--                            class="brand-primary-text-color"> Unleash Your Brand’s Potential </span>-->
<!--                            Comprehensive Social Media Marketing Solutions</h1>-->
<!--                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we-->
<!--                            believe that effective social media marketing is the key to unlocking your brand's full-->
<!--                            potential. Our comprehensive SMM services cover every aspect of digital marketing, ensuring-->
<!--                            that your brand gains enhanced visibility and recognition across all platforms. From-->
<!--                            targeted advertising that reaches your ideal audience to strategies designed to improve-->
<!--                            customer engagement, we tailor our approach to meet your unique needs. Our team provides-->
<!--                            valuable insights and analytics, enabling you to make data-driven decisions that drive-->
<!--                            growth. Let us help you elevate your online presence and transform your brand into a-->
<!--                            powerful force in the digital landscape.-->
<!--                        </p>-->
<!--                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"-->
<!--                                (click)="openModal()">View Demo <i-->
<!--                            class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                        </button>-->
<!--                        <ng-template #contactFormModal>-->
<!--                            <app-contact-form [isModalOpen]="isModalOpen"-->
<!--                                              (closeModal)="closeModal()"></app-contact-form>-->
<!--                        </ng-template>-->
<!--                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">-->
<!--                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">-->
<!--                    <div-->
<!--                        class=" md:mr-0 position-relative h-100 w-100 border-0  d-flex justify-content-center border-radius-10">-->
<!--                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"-->
<!--                             alt="Image"-->
<!--                             class="img-fluid border-radius-10 w-100"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<!--<div class="container-fluid">-->
<!--    <div class="container py-5">-->
<!--        <div class="row">-->
<!--            <div class="col-md-6 m-0 p-0 text-right animate-on-scroll">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/about-social-media.jpg"-->
<!--                     class="img-fluid border-radius-10"-->
<!--                     alt="Image">-->
<!--            </div>-->
<!--            <div class="col-md-6 ps-md-5 py-md-5 py-4 animate-on-scroll-right">-->
<!--                <h2 class=" fs-1 fw-bolder"> Elevate Your Brand-->
<!--                    <span class="brand-primary-text-color"> Comprehensive Social Media Marketing Services</span></h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In today's digital age, having a strong-->
<!--                    social media presence is essential for any business looking to thrive. At Volmint Technologies, we-->
<!--                    specialize in Social Media Marketing (SMM) that empowers your brand to stand out in a crowded-->
<!--                    marketplace. Our all-encompassing SMM services are designed to enhance brand awareness, ensuring-->
<!--                    your message reaches the right audience at the right time. We utilize targeted advertising-->
<!--                    strategies that maximize your reach and return on investment, driving meaningful engagement with-->
<!--                    your customers.</p>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">-->
<!--                    Our expert team harnesses the power of social media to foster improved customer relationships,-->
<!--                    providing you with the tools to connect and interact with your audience effectively. With our-->
<!--                    in-depth insights and analytics, we equip you with the knowledge needed to refine your marketing-->
<!--                    efforts and achieve sustained growth. Let us partner with you to create a compelling social media-->
<!--                    presence that not only captivates your audience but also elevates your brand to new heights.-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="row mt-md-5">-->
<!--            <div class="col-md-6 py-md-5 animate-on-scroll-right">-->
<!--                <h2 class=" fs-1 fw-bolder">Ignite Your Business Growth with-->
<!--                    <span class="brand-primary-text-color">  Powerful Social Media Marketing Solutions!</span></h2>-->
<!--                <p class="fs-6 text-secondary text-wrap justify-text">In today’s digital landscape, harnessing the power-->
<!--                    of social media marketing is crucial for driving business growth and achieving long-term success.-->
<!--                    Studies show that 73% of marketers believe that their efforts through social media marketing have-->
<!--                    been “somewhat effective” or “very effective” for their business. With over 4.7 billion active-->
<!--                    social media users worldwide, your brand has the potential to reach a vast audience, connect with-->
<!--                    customers, and cultivate lasting relationships. At Volmint Technologies, we leverage data-driven-->
<!--                    strategies to create tailored social media campaigns that not only enhance brand awareness but also-->
<!--                    drive engagement and conversions. Businesses that actively engage with their audience on social-->
<!--                    media can see a 20-40% increase in customer retention rates. Let us help you capitalize on these-->
<!--                    opportunities, supercharge your marketing efforts, and propel your business toward unprecedented-->
<!--                    growth and success.-->
<!--                </p>-->
<!--                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">-->
<!--                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"-->
<!--                         *ngFor="let item of powerfullToUse">-->
<!--                        {{ item.text }}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12 d-block d-lg-none mt-4">-->
<!--                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img-->
<!--                        src="assets/img/project/check.png"-->
<!--                        alt="Image"-->
<!--                        class="img-fluid"> {{ item.text }}-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 animate-on-scroll">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/business-social-media.png"-->
<!--                     class="img-fluid w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid bg-purple">-->
<!--    <div class="container">-->
<!--        <div class="row py-5">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                    Key Features of <span class="brand-primary-text-color">Social Media Marketing</span></h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Social media marketing encompasses several-->
<!--                    key features designed to enhance brand presence and engagement. It begins with the creation and-->
<!--                    curation of engaging content tailored to the audience's interests, coupled with advanced audience-->
<!--                    targeting to reach specific demographics. Effective ad campaign management ensures maximum-->
<!--                    visibility and return on investment. Additionally, social listening allows brands to monitor-->
<!--                    conversations and trends, gaining valuable insights into customer sentiment. Performance analytics-->
<!--                    provide actionable insights for continuous improvement, while community management fosters-->
<!--                    engagement by responding to audience interactions. Collaborating with influencers amplifies brand-->
<!--                    messages, and cross-platform integration ensures a cohesive brand experience. Furthermore,-->
<!--                    reputation management maintains a positive image by addressing negative feedback, and staying-->
<!--                    abreast of trends enables brands to adapt their strategies for maximum relevance and impact.-->
<!--                </p>-->
<!--                <div class="row g-3">-->
<!--                    <div class="col-sm-4 col-6 px-2 my-2"-->
<!--                         *ngFor="let item of features">-->
<!--                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">-->
<!--                               <span>-->
<!--                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">-->
<!--                        </span>-->
<!--                            <span class="ms-2 text-secondary pt-2">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6  justify-content-md-end">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/key-feature-social-media.jpg" alt="Image"-->
<!--                     class="img-fluid border-radius-10">-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5 animate-on-scroll">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                    Boost Your<span class="brand-primary-text-color"> Brand Visibility</span></h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">-->
<!--                    In the competitive digital landscape, boosting your brand visibility is essential for standing out-->
<!--                    and capturing your audience's attention. At Volmint Technologies, our social media marketing-->
<!--                    services are designed to elevate your brand's presence across multiple platforms, ensuring that your-->
<!--                    message reaches a wider audience. By leveraging targeted advertising, engaging content, and-->
<!--                    strategic influencer partnerships, we create campaigns that resonate with your ideal customers. Our-->
<!--                    comprehensive approach not only increases awareness but also fosters trust and credibility,-->
<!--                    positioning your brand as a leader in your industry. With our expertise, you can transform your-->
<!--                    social media channels into powerful tools for brand growth, driving both engagement and loyalty-->
<!--                    while enhancing your overall online visibility.-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/Enhanced-Brand-Awareness.png"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->

<!--            <div class="col-md-6  text-right animate-on-scroll-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"-->
<!--                     class="w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--            <div class="col-md-6  ps-md-5 animate-on-scroll">-->
<!--                <h2 class="fs-1 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Precision Targeting </span>for Maximum Impact</h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In today's dynamic social media landscape,-->
<!--                    precision targeting is key to maximizing the impact of your advertising campaigns. At Volmint-->
<!--                    Technologies, we utilize advanced targeting techniques to ensure your ads reach the right audience-->
<!--                    at the right time. By analyzing user demographics, interests, and behaviors, we craft tailored-->
<!--                    campaigns that resonate with potential customers, significantly improving engagement and conversion-->
<!--                    rates. Our data-driven approach allows us to optimize ad spend effectively, ensuring that every-->
<!--                    dollar is invested wisely. Whether you aim to generate leads, drive traffic, or boost sales, our-->
<!--                    precision targeting strategies empower your brand to connect authentically with your audience,-->
<!--                    ultimately driving meaningful results and fostering lasting relationships.-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5 animate-on-scroll">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color"> Elevating </span>-->
<!--                    Customer Engagement</h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">-->
<!--                    Elevating customer engagement is at the heart of effective social media marketing, and at Volmint-->
<!--                    Technologies, we excel in creating authentic connections between your brand and its audience. Our-->
<!--                    strategic approach involves crafting compelling content that encourages interaction, from-->
<!--                    thought-provoking posts to engaging polls and contests. By actively responding to comments,-->
<!--                    messages, and reviews, we foster a sense of community and belonging, making your customers feel-->
<!--                    valued and heard. We also leverage insights from audience interactions to tailor our strategies-->
<!--                    further, ensuring that our messaging resonates deeply with your target demographic. With our focus-->
<!--                    on elevating customer engagement, we not only enhance brand loyalty but also create advocates who-->
<!--                    are eager to share their positive experiences with others, ultimately driving sustainable growth for-->
<!--                    your business.-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/customer-engagment.jpg"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->

<!--            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/valuable-insights.jpg"-->
<!--                     class="w-100 border-radius-10" alt="">-->
<!--            </div>-->
<!--            <div class="col-md-6 py-5 ps-md-5 animate-on-scroll">-->
<!--                <h2 class="fs-1 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Actionable Insights  </span> and Analytics-->
<!--                </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In the realm of social media marketing,-->
<!--                    actionable insights and analytics are crucial for informed decision-making and strategy refinement.-->
<!--                    At Volmint Technologies, we utilize advanced analytics tools to track and measure the performance of-->
<!--                    your social media campaigns in real time. By analyzing key metrics such as engagement rates,-->
<!--                    audience demographics, and conversion statistics, we provide you with a comprehensive understanding-->
<!--                    of what works and what doesn’t. These insights empower you to make data-driven adjustments, optimize-->
<!--                    your marketing strategies, and allocate resources more effectively. With our focus on delivering-->
<!--                    actionable insights, we help you stay ahead of trends, capitalize on emerging opportunities, and-->
<!--                    ensure that your social media efforts yield maximum results, ultimately driving sustained growth for-->
<!--                    your brand.-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row gx-4">-->
<!--            <div class="col-md-6 py-5">-->
<!--                <h2 class=" fs-1 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color">  The Importance of Social Media Marketing</span>-->
<!--                    for Your Business </h2>-->
<!--                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Social media marketing (SMM) has become an-->
<!--                    indispensable component of any successful business strategy in today's digital world. With billions-->
<!--                    of active users across platforms like Facebook, Instagram, and Twitter, SMM provides unparalleled-->
<!--                    access to a vast audience, allowing brands to connect with potential customers where they spend much-->
<!--                    of their time. By leveraging social media, businesses can enhance brand awareness, foster customer-->
<!--                    loyalty, and drive website traffic, all while engaging directly with their audience through-->
<!--                    meaningful interactions. Furthermore, SMM enables businesses to gather valuable insights into-->
<!--                    customer preferences and behaviors, informing product development and marketing strategies. In a-->
<!--                    landscape where competition is fierce, integrating social media marketing into your business model-->
<!--                    not only boosts visibility but also cultivates a community of loyal customers who advocate for your-->
<!--                    brand, ultimately leading to sustainable growth and success.-->
<!--                </p>-->
<!--                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"-->
<!--                        (click)="openModal()">Book Appointment-->
<!--                    <i-->
<!--                        class="ms-3 fa-solid fa-arrow-right"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 text-right">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"-->
<!--                     class="ms-2 img-fluid border-radius-10" alt="Image"/>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid brand-primary-bg mt-5">-->
<!--    <div class="container py-5">-->
<!--        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Sets Our Social Media Marketing Services-->
<!--            Apart?</h1>-->
<!--        <p class="text-white text-center">At Volmint Technologies, our social media marketing services stand out from-->
<!--            the competition due to our commitment to delivering tailored, results-driven strategies that align with your-->
<!--            unique business goals. We take the time to understand your brand, audience, and industry landscape, enabling-->
<!--            us to create personalized campaigns that resonate deeply with your target market. Our team of experienced-->
<!--            professionals employs cutting-edge tools and analytics to track performance and optimize campaigns in real-->
<!--            time, ensuring maximum impact. Additionally, we emphasize transparent communication and collaboration,-->
<!--            keeping you informed every step of the way. With a focus on creativity, innovation, and measurable results,-->
<!--            we not only elevate your brand’s online presence but also foster genuine engagement and loyalty among your-->
<!--            customers, setting us apart as a leader in the social media marketing space.-->
<!--        </p>-->
<!--        <div class="row gy-5 py-5">-->
<!--            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">-->
<!--                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>-->
<!--                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>-->
<!--                <p class="text-white justify-text">{{-->
<!--                        item.text-->
<!--                    }}</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid py-5">-->
<!--    <div class="container">-->
<!--        <div class="row py-5 gx-5">-->
<!--            <div class="col-md-6 py-md-5">-->
<!--                <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive-->
<!--                    <span class="brand-primary-text-color"> Social Media Marketing Services </span>-->
<!--                </h2>-->
<!--                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a-->
<!--                    comprehensive suite of social media marketing services designed to elevate your brand and drive-->
<!--                    meaningful engagement. Our approach encompasses everything from strategic planning and content-->
<!--                    creation to targeted advertising and community management, ensuring that every aspect of your social-->
<!--                    media presence is optimized for success. We leverage the latest tools and trends to develop-->
<!--                    data-driven campaigns that resonate with your audience, enhancing brand visibility and fostering-->
<!--                    loyalty. With our expert team by your side, you’ll have access to personalized strategies that align-->
<!--                    with your unique business goals, delivering measurable results across multiple platforms. Discover-->
<!--                    how our all-encompassing SMM services can transform your online presence and propel your business-->
<!--                    toward sustained growth and success.</p>-->
<!--                <div class="row g-3 mt-5">-->
<!--                    <div class="col-12 px-0 my-3 "-->
<!--                         *ngFor="let item of offerings">-->
<!--                        <span>-->
<!--                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">-->
<!--                        </span>-->
<!--                        <span class="ms-2 fw-bold text-secondary">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 pt-5 d-flex justify-content-end">-->
<!--                <img src="assets/img/landing-new/social-media-marketing/discover-social-media.jpg" class="img-fluid"-->
<!--                     alt="Image">-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-md-6">-->
<!--                <div class="col-12">-->
<!--                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>-->
<!--                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>-->
<!--                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>-->
<!--                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"-->
<!--                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>-->
<!--                    </p>-->
<!--                    <button type="submit"-->
<!--                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"-->
<!--                            (click)="goTo('/faq')">-->
<!--                        View All Faqs-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6 mt-4 animate-on-scroll-right">-->
<!--                <div class="accordion" id="faqAccordion">-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingOne">-->
<!--                            <button class="accordion-button" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                          <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                          </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                        What types of businesses can benefit from your social media marketing services?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                Our social media marketing services are designed for businesses of all sizes and-->
<!--                                industries. Whether you’re a small local business or a large corporation, we tailor our-->
<!--                                strategies to meet your unique goals and target audience.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingTwo">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">-->
<!--                        <span-->
<!--                            class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                             <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                 <div class="col-11">-->
<!--                                   How do you measure the success of social media marketing campaigns?-->
<!--                                 </div>-->
<!--                             </div>-->
<!--                            </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                We measure success through key performance indicators (KPIs) such as engagement rates,-->
<!--                                reach, conversions, and return on investment (ROI). Our analytics tools provide detailed-->
<!--                                reports that help you understand the effectiveness of our campaigns and guide future-->
<!--                                strategies.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingThree">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon float-start">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                            How often will you post on our social media channels?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                The frequency of posts will depend on your specific goals and audience engagement-->
<!--                                levels. We work with you to develop a content calendar that outlines posting schedules,-->
<!--                                ensuring consistent and relevant content while optimizing engagement.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingFour">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon">-->
<!--                                                <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                          Can you help us with paid advertising on social media?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                Absolutely! We specialize in creating and managing targeted advertising campaigns on-->
<!--                                various social media platforms. Our team will design ads that align with your business-->
<!--                                objectives and track their performance for optimal results.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="accordion-item shadow-lg border-0">-->
<!--                        <h2 class="accordion-header" id="headingFive">-->
<!--                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"-->
<!--                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">-->
<!--                                <span class="fs-6 fw-bold" style="color: #03738C;">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="encircled-icon">-->
<!--                                        <i class="fas fa-question fw-bold"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-11">-->
<!--                                            What kind of content will you create for our brand?-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </h2>-->
<!--                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"-->
<!--                             data-bs-parent="#faqAccordion">-->
<!--                            <div class="accordion-body fs-6 text-secondary justify-text">-->
<!--                                We focus on developing a diverse range of content tailored to your audience, including-->
<!--                                engaging graphics, informative articles, videos, polls, and more. Our goal is to create-->
<!--                                content that not only informs but also entertains and encourages interaction with your-->
<!--                                brand.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="container-fluid ums-bottom-bg ps-5 mt-5">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center py-5">-->
<!--            <div class="col-md-10 col-12">-->
<!--                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>-->
<!--            </div>-->
<!--            <div class="col-md-2 col-12">-->
<!--                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"-->
<!--                        (click)="goTo('/contact')">Contact Us-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<section class="wave-section">
    <div class=" py-5 bg-sea-green ">
        <div class="container">
            <div class="row flex-column-reverse flex-md-row ">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56 d-none d-md-block"><span
                            class="text-primary">Elevate Your Brand Online </span>
                             with Social Media Management Services
                        </h1>

                        <p class="my-4 fs-6 text-white text-wrap f-20 justify-text d-none d-md-block">Engage, grow, and connect with your audience effortlessly. Move beyond likes—turn your audience into customers with Social Media Management services that craft impactful strategies tailored to your brand, driving visibility and results.

                        </p>
                        <h1 class="fw-bolder fs-3 d-md-none"><span
                            class="text-primary">Unleash Your Brand’s Potential </span>
                            Comprehensive Social Media Marketing Solutions</h1>
                        <p class=" text-white text-wrap f-20 justify-text d-md-none">At Volmint Technologies, we believe
                            that effective social media marketing is the key to unlocking your brand's full potential.
                            Our comprehensive SMM services cover every aspect of digital marketing, ensuring that your
                            brand gains enhanced visibility and recognition across all platforms.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">Get Started <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  py-5 d-flex ">
                    <div
                        class="py-5 position-relative d-flex justify-content-md-end justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"
                             alt="Image"
                             class="img-fluid border-radius-10 d-none d-md-block w-75"/>
                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"
                             alt="Image"
                             class="img-fluid w-50 mx-auto d-md-none "/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wave">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="white" fill-opacity="1"
                  d="M0,32L17.1,53.3C34.3,75,69,117,103,144C137.1,171,171,181,206,197.3C240,213,274,235,309,224C342.9,213,377,171,411,154.7C445.7,139,480,149,514,138.7C548.6,128,583,96,617,69.3C651.4,43,686,21,720,32C754.3,43,789,85,823,128C857.1,171,891,213,926,213.3C960,213,994,171,1029,154.7C1062.9,139,1097,149,1131,144C1165.7,139,1200,117,1234,122.7C1268.6,128,1303,160,1337,192C1371.4,224,1406,256,1423,272L1440,288L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
        </svg>
    </div>
</section>
<section class="" style="overflow-x:hidden;">
    <div class="container pb-4">
        <div class="row g-5 justify-content-center">
            <div class=" align-self-center">
                <div class=" p-3 md-mt-5 h-100">
                    <h2 class="text-primary md-mt-5 fw-bold">Achieve your business goals with Volmint
                    </h2>
                    <p class=" fs-6 text-secondary text-wrap justify-text">Reach Your Business Goals with Volmint Technologies

                    </p>
                    <div style="height:10px;" class="rounded border w-25 bg-gradient"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative  pink-box p-3 text-center d-flex align-items-center justify-content-center  ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class=""> Understanding Your Brand </span>
                        </h3>
                        <p class="text-secondary">
                            We begin by thoroughly examining your brand’s vision, target audience, and business goals. This deep analysis helps us craft a tailored roadmap that aligns with your objectives, values, and growth aspirations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative blue-box p-3 text-center d-flex align-items-center justify-content-center ">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-5.png" alt="">

                        <h3 class="fs-5 mt-3 fw-bolder">
                    <span
                        class="">Planning and Strategy  </span></h3>
                        <p class="text-secondary">Our experts craft a customized strategy that outlines clear objectives, key performance indicators, content themes, and timelines. This ensures every step is purpose-driven, measurable, and aligned with your brand’s unique identity.
                        </p>
                    </div>

                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center orange-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-64.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Content Creation</span></h3>
                        <p class="text-secondary"> From compelling copywriting to stunning visuals and videos, we design engaging, high-quality content that resonates with your audience, reflects your brand identity, and drives meaningful results while fostering stronger connections.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center green-box">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-2.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">  Execution </span></h3>
                        <p class="text-secondary">We strategically schedule and publish posts, ensuring maximum reach and consistent visibility across all platforms. Our team ensures timely delivery, brand alignment, and seamless execution for a strong online presence.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative yellow-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-3.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Engagement and Monitoring  </span></h3>
                        <p class="text-secondary">
                              We actively manage your profiles, respond promptly to queries, address customer concerns, and foster meaningful interactions. Our team monitors activity, analyzes trends, and builds trust and loyalty.

                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative red-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/marketing-4.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Review and Optimize  </span></h3>
                        <p class="text-secondary">
                             With an analytics-driven approach, we assess campaign performance, identify gaps, and uncover opportunities for improvement. By analyzing data trends, and audience behavior, keep your brand ahead of the competition.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="container-fluid pt-4">
    <div class="container rounded-5 mt-5">
        <div class="row ">
            <div class="col-md-5 align-self-md-center text-center">
                <img src="assets/img/landing-new/seo/business-growth-seo.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 py-5">
                <h2 class="text-center  fs-1 fw-bolder">
                    <span class="text-primary">About Volmint’s Social Media Management Services
</span></h2>
                <p class=" text-secondary ">Social media has reshaped how businesses connect with customers, driving engagement and building trust. At Volmint, we don’t just manage your profiles; we transform them into powerful tools for growth. We believe that a strong social media marketing strategy is one of the important factors for your brand’s full potential. Our comprehensive SMM services cover every aspect of digital marketing, ensuring your brand gains the visibility and recognition it deserves across all platforms.
                </p>
                <p class="text-secondary">
                    We offer comprehensive SEO strategies based on business needs and goals. We make sure that every
                    element of your website is optimized to rank higher on search engines, from technical SEO and link
                    building to keyword research and on-page optimization. Our data-driven strategy helps you find
                    important possibilities, increase the exposure of your website, and attract more targeted visitors.
                </p>
                <p class="text-secondary">
                    We are not only improving your rankings, we are helping you to achieve sustainable growth and
                    success in this challenging online world. Let us handle your SEO while you are craving your business
                    credibility.

                </p>
            </div>

        </div>
    </div>
</div>


<div class="container-fluid my-5">
    <div class="container ">
        <div class="row g-4 pb-4 justify-content-center mx-auto">
            <div class=" align-self-center py-4">
                <h2 class="my-2 fs-1 fw-bolder">
                     <span
                         class="text-primary">Expand Your Digital Reach with Services we Offer
 </span>
                </h2>
                <p class="text-secondary">As a well-known social media management consultant, Volmint Technologies provides end-to-end solutions that transform your online visibility and engagement. Following in-depth research of your business and requirements, we craft tailored strategies to amplify your brand’s reach, enhance customer engagement, and drive meaningful results across all platforms.
                </p>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/on-page-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Strategy Development</h4>
                    <p class="text-secondary"> We design customized social media strategies that align with your business objectives, target audience, and industry trends to maximize results.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/off-page-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Content Creation </h4>
                    <p class="text-secondary"> Our creative team delivers engaging content—including posts, videos, infographics, and stories—that captures attention and reflects your brand identity.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/local-seo.png" alt="">

                    <h4 class="fw-bold mt-2 text-center ">Social Media Posting & Scheduling</h4>
                    <p class="text-secondary"> We ensure your content reaches the right audience at the right time with a well-planned content calendar tailored to each platform.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/technical-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Community Management</h4>
                    <p class="text-secondary"> We foster meaningful conversations by responding to comments, messages, and reviews, ensuring your audience feels valued and connected.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/technical-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Paid Advertising</h4>
                    <p class="text-secondary">Our targeted ad campaigns on platforms like Facebook, Instagram, and LinkedIn drive traffic, leads, and conversions cost-effectively. </p>
                </div>
            </div>


            <div class="col-md-6 col-lg-4">
                <div class=" rounded-4 p-3 h-100 shadow">
                    <img src="assets/img/landing-new/seo/technical-seo.png" alt="">
                    <h4 class="fw-bold mt-2 text-center ">Analytics & Reporting</h4>
                    <p class="text-secondary">  We provide detailed reports with actionable insights, helping you measure performance, understand trends, and refine your social media strategy.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg-gradient">
    <div class="container py-5">
        <h2 class="text-white text-center fw-bold justify-text">Why SMM is Essential for Your Business?
        </h2>
        <p class="text-secondary text-white text-justify">Social media management is key for your business’s growth and visibility. A strong social media presence helps you stay relevant, attract new customers, increase profit, and build trust. Social media strategies are crucial for connecting with your audience, building brand awareness, and driving engagement. <br>
       Without the helping hand of a social media management company, you could be missing opportunities to boost your brand and stand out in today’s competitive market. Let’s look at the challenges businesses face when ignoring SMM and how Volmint Technologies can help boost your success.
        </p>
        <div class="row gy-5 py-3">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}sdcdgv</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container ">
        <div class="row justify-content-center py-5">
            <h2 class=" fs-1 fw-bolder  text-center">
                     <span class="text-primary"> Social Media Management</span>  Vs. Social Media Marketing: Major Differences
            </h2>
            <p class="text-secondary">While we often overlap, Social Media Management (SMM) and Social Media Marketing serve distinct purposes. Here’s how they differ:</p>
            <div
                class="col-md-6  d-flex justify-content-md-end pe-md-5 justify-content-center align-self-center pe-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 seo py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center ps-5 fs-1 fw-bold text-white">
                        <div class="py-3 ps-3">
                            <p class="title fs-3 text-white me-5">SMM</p>
                        </div>
                        <div class="py-3 pe-2">
                            <i class="fa fa-thumbs-up fs-4 me-3"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4 t">Social Media Management - Smm
                    </h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                SMM focuses on maintaining your brand’s online presence

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Focus on consistent posting and engagement

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                SMM fosters organic relationships with followers

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Management typically involves minimal costs


                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Management builds long-term loyalty and trust


                            </p>
                        </li>
                    </ul>

                </div>
            </div>
            <div
                class="col-md-6 d-flex align-self-center justify-content-center justify-content-md-start ps-md-5 ps-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 ppc py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center pe-2 fs-1 fw-bold text-white">
                        <div class="py-3 pe-2">
                            <p class="title fs-3 text-white me-5">SMM</p>
                        </div>
                        <div class="py-3">
                            <i class="fa fa-thumbs-up fs-4 me-5"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4">Social Media Marketing
                    </h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                	Social Media Marketing drives campaigns to drive sales or leads
                             </p>
                        </li>
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Emphasizes promotional content tailored for campaigns
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Uses ads to reach and convert a broader audience
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Marketing requires investment in paid campaigns
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Marketing focuses on immediate results and measurable ROI
                            </p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid ">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 col-md-6 align-self-center">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image"
                         class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="text-primary fw-bold">Email: <a href="mailto:support@volmint.com"
                                                         class="text-decoration-underline text-primary">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 ">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11 text-primary">
How is content created for social media marketing?                                      </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Social media marketing content is crafted based on the target audience, business goals, and platform requirements. A social media management company or consultant often conducts research, designs engaging visuals, and writes compelling captions to resonate with your audience while optimizing posts for visibility and engagement.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11 text-primary">
How much does social media management cost?

                                 </div>
                             </div>
                            </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The cost for social media management services may vary as per the business recruitment and goals. Usually, the cost for basic to advanced SMM may range between INR 10,000 - 70,000 PM.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
How long does it take to see results from social media marketing?

                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Results can vary depending on your goals and strategy. Organic growth often takes several months, while paid ads can deliver quicker results. A social media management consultant can guide you on the timeline based on your specific business needs.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
Is it better to focus on organic growth or paid social media ads?
                                     </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Both approaches have value. Organic growth builds trust and long-term engagement, while paid ads drive immediate visibility and conversions. A balanced strategy crafted by a social media management company can help you achieve the best results.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
How do I choose the right social media strategy for my business?

                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The right strategy depends on your target audience, industry, and goals. Consulting with a social media management agency or expert can help you analyze your needs, identify key platforms, and create a customized plan for success.


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-3 p-lg-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to Take Your Business Growth to the Next Level?
            </div>
            <div class="col-md-6 align-self-center col-12 text-md-end">
                <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                        (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
