import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
    selector: 'app-mobile-application-development',
    templateUrl: './mobile-application-development.component.html',
    styleUrls: ['./mobile-application-development.component.scss']
})
export class MobileApplicationDevelopmentComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Custom Solutions Tailored to Your Needs'},
        {text: 'Scalable and Future-Ready Development'},
        {text: 'Cross-Platform Expertise'},
        {text: 'Enhanced User Experience (UX)'},
        {text: 'Advanced Security Protocols'},
        {text: 'Ongoing Support and Maintenance'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Custom App Design'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Cross-Platform Compatibility'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'User-Friendly Interface'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'High Performance'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Scalability'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Security Features'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Push Notifications'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Integration with Business Tools'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Ongoing Support and Updates'}

    ];
    whyChoose = [
        {
            header: 'User-Centric Design',
            text: 'Our applications are crafted with a focus on user experience, ensuring intuitive navigation and engaging interfaces that resonate with your audience.'
        },
        {
            header: 'High Performance',
            text: 'Optimized for speed and efficiency, our apps deliver seamless functionality, enhancing user satisfaction and driving higher engagement rates.'
        },
        {
            header: 'Robust Security',
            text: 'We prioritize the safety of your data with advanced security protocols, ensuring that user information is protected against potential threats.'
        },
        {
            header: 'Scalability and Flexibility',
            text: 'Our mobile applications are built on scalable architectures, allowing for easy integration of new features and enhancements as your business grows.'
        },
        {
            header: 'Cross-Platform Compatibility',
            text: 'We develop applications that perform flawlessly across both iOS and Android platforms, maximizing your reach and impact in the mobile market.'
        },
        {
            header: 'Ongoing Support and Maintenance',
            text: 'We provide continuous support and regular updates, ensuring your application remains up-to-date, secure, and aligned with evolving industry standards.'
        },
    ];
    offerings = [
        {
            text: 'Requirements Analysis'
        },
        {
            text: 'UI/UX Design'
        },
        {
            text: 'Development and Integration'
        },
        {
            text: 'Quality Assurance and Testing'
        },
        {
            text: 'Deployment and Launch'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
        this.schema();
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "Mobile-Application-DevelopmentPage",
            "name": "Volmint Technology - Mobile-Application-Development",
            "url": "https://www.volmint.com/mobile-application-development",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What platforms do you develop mobile applications for?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "We develop mobile applications for both iOS and Android platforms, ensuring your app  reaches a wide audience and provides a seamless experience across all devices."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How long does it take to develop a mobile application?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   The development timeline varies based on the complexity and features of the application. On average, a basic mobile app can take between 3 to 6 months, while more complex  applications may require additional time for design, development, and testing."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Will my mobile application be secure?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Absolutely! We prioritize security throughout the development process by implementing the latest security protocols and best practices to protect your app and user data from  potential threats."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Can I update my mobile application after it’s launched?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, you can! We provide ongoing support and maintenance services, allowing you to  update your app with new features, enhancements, and security patches as needed to keep  it current and effective."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How much does it cost to develop a mobile application?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "The cost of developing a mobile application depends on various factors, including\n" + "                                complexity, design, features, and the platforms chosen. We offer customized solutions\n" +
                            "                                tailored to your budget and requirements, and we're happy to provide a detailed proposal\n" +
                            "                                after discussing your specific needs."
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
