import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-content-marketing',
    templateUrl: './content-marketing.component.html',
    styleUrls: ['./content-marketing.component.scss']
})
export class ContentMarketingComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [

        {text: 'Tailored Content Creation'},
        {text: 'SEO Optimization'},
        {text: 'Diverse Content Formats'},
        {text: 'Audience-Centric Approach'},
        {text: 'Performance Analytics'},
        {text: 'Measurable Growth'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Custom-Tailored Content'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'SEO-Driven Results'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Multi-Format Expertise'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Focused on Your Audience'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Data-Backed Insights'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Boost Engagement & Conversions'},

    ];
    whyChoose = [
        {
            header: 'Understand Your Brand',
            text: 'We start by diving deep into your brand’s identity, audience preferences, and goals. This helps us craft a content strategy that aligns perfectly with your vision and drives measurable outcomes.'
        },
        {
            header: 'Strategic Planning',
            text: 'Our experts develop a content roadmap, detailing key themes, formats, and timelines. This ensures every piece of content serves a purpose and aligns with your overall marketing strategy.'
        },
        {
            header: 'Content Creation ',
            text: 'From blog posts to social media updates, we create high-quality, engaging, and audience-specific content. Every word and visual is tailored to inspire, inform, and spark action.\n'
        },
        {
            header: 'Multi-Channel Distribution',
            text: 'We distribute your content across the most effective platforms, ensuring it reaches your target audience wherever they spend their time online, boosting visibility and engagement.'
        },
        {
            header: 'Engagement and Interaction',
            text: 'By monitoring responses and sparking conversations, we keep your audience engaged. Active interactions help build trust, nurture relationships, and amplify your brand’s voice.'
        },
        {
            header: 'Performance Analysis and Optimization',
            text: ' We track the impact of your content, identifying what works and where there’s room for improvement. This data-driven approach keeps strategy evolving and results-focused'
        },
    ];
    offerings = [
        {
            text: 'Holistic Approach'
        },
        {
            text: 'Tailored Content Creation'
        },
        {
            text: 'Strategic SEO Optimization'
        },
        {
            text: 'Diverse Content Formats'
        },
        {
            text: 'Data-Driven Insights'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title : Title,
        private meta : Meta,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.updateMetaTags()
        this.refresh();
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
        this.schema();
        this.updateMetaTags();
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "content-marketingPage",
            "name": "Volmint Technology - Content-Marketing",
            "url": "https://www.volmint.com/content-marketing",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": " What types of content are most effective for small businesses?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Content including SEO-friendly blog posts, videos, and infographics performs well. Small businesses often find that informative, relatable, and engaging content resonates best with their audience."
                    }
                },
                {
                    "@type": "Question",
                    "name": "  How is content marketing different from traditional advertising?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Content marketing focuses on creating valuable, informative content to attract and engage audiences, while traditional advertising promotes products directly."
                    }
                },
                {
                    "@type": "Question",
                    "name": " Why is content marketing important for SEO?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Content marketing helps improve SEO by creating relevant, high-quality content that attracts organic traffic. It targets specific keywords, enhances search rankings, and increases visibility, driving more users to your website."
                    }
                },
                {
                    "@type": "Question",
                    "name": "  How often should I publish new content?\n",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Consistency is key. Depending on your audience and content marketing strategy, publishing 2-3 times a week ensures regular engagement without overwhelming your audience."
                    }
                },



            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('Drive Engagement With Best Content Marketing Company in India\n');
        this.meta.updateTag({name: 'description', content: 'Promote your brand with Content Marketing Company in India. We will assist you create engaging content that brings traffic, builds authority, and enhances customer loyalty.'});
        this.meta.updateTag({name: 'keyword', content: 'Content Marketing Company In India'});
    }

}
