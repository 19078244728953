import {Component, ElementRef, OnInit, TemplateRef, Renderer2, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-search-engine-optimization-service',
    templateUrl: './search-engine-optimization-service.component.html',
    styleUrls: ['./search-engine-optimization-service.component.scss']
})
export class SearchEngineOptimizationServiceComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Enhanced Online Visibility'},
        {text: 'Targeted Traffic Acquisition'},
        {text: 'Comprehensive Keyword Research'},
        {text: 'Optimized Website Structure'},
        {text: 'High-Quality Content Creation'},
        {text: 'Measurable Results and Analytics'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Comprehensive Keyword Research'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'On-Page Optimization'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'High-Quality Content Creation'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Off-Page SEO Strategies'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Technical SEO'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Local SEO Optimization'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'User Experience (UX) Enhancement'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Data-Driven Analytics and Reporting'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Continuous Strategy Adaptation'}

    ];
    whyChoose = [
        {
            header: 'Website Evaluation',
            text: 'The first step we follow is website evaluation and requirements by the client that help our SEO Experts start work on the required sections and employ methods to increase website ranking.'
        },
        {
            header: 'Strategy Development',
            text: 'The right strategy is the key to Success. Following deep research, we use the best keywords and tactics that suit your unique business needs and drive organic traffic to your website. '
        },
        {
            header: 'Analyze and Research',
            text: 'A detailed report and analytics is the foremost assert for right decision-making. We practice thorough research about competitors, keywords, backlinks, and other important factors to increase visibility. '
        },
        {
            header: 'Content Planning',
            text: ' Content plays a pivotal role in spreading your voice most appropriately. We identify key milestones, set realistic timelines, produce great ideas, and resources to ensure a smooth user experience'
        },
        {
            header: 'Content Implementation ',
            text: 'A strong content implementation is the foundation of a successful website. We have carefully implemented the material in line with SEO best practices to enhance the site\'s credibility. '
        },
        {
            header: 'Execution Engagement',
            text: ' With the strategy in place, our SEO team puts their knowledge into practice by putting on-page and off-page optimization strategies into practice and producing excellent content to raise your search engine ranks.'
        },
    ];
    offerings = [
        {
            text: 'In-Depth Website Analysis'
        },
        {
            text: 'Customized SEO Strategies'
        },
        {
            text: 'On-Page and Technical Optimization'
        },
        {
            text: 'High-Quality Content Creation'
        },
        {
            text: 'Off-Page SEO Tactics'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title: Title,
        private meta: Meta,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.updateMetaTags();
        this.schema();
        this.refresh();
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "SearchEngineOptimizationServicePage",
            "name": "Volmint Technology - search-engine-optimization-service",
            "url": "https://www.volmint.com/search-engine-optimization-service",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What are the key benefits of SEO for my business?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   SEO benefits any business in numerous ways such as helping to increase website ranking  on Google Analytics drive leads and increase sales. Moreover, it improves website credibility, user experience, and brand awareness."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How can I determine which type of SEO services is right for my website?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Before targeting SEO services, consider your budget. Then, compare SEO packages to  ensure they fulfill your requirements. Compare your needs and choose among the many SEO  available such as local SEO, technical SEO, Keyword Optimization, Off-Page, and On-Page."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Are you able to conduct an SEO audit for my website?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Absolutely, we have a team of experts who conduct thorough research on your website based on your business and competitors. Additionally, working with the Volmint - An  Indian SEO agency, offers you the personalized packages that suit your requirements."
                    }
                },
                {
                    "@type": "Question",
                    "name": "What’s the difference between on-page, off-page, and technical SEO?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  <ul><li><p>Being one of the fastest-growing SEO services providers in India, its our  responsibility to provide the right information to our visitors. While talking about On-page SEO, its strategies elements like keyword research, content, images, and internal linking, ensure search-engine and user-friendly sites, setting a perfect stage  for audience and SEO.</p></li>  <li><p>Off-page SEO services focus on building quality backlinks, monitoring and strengthening them, and improving brand credibility, ensuring your website'sultimate success and impact on the web.</p></li><li> <p>Technical SEO services ensure your site is fast, secure, and bug-free, optimizing website and server settings to improve search engine rankings and  provide a seamless experience.</p></li></ul>"
                    }
                },
                {
                    "@type": "Question",
                    "name": " Can local SEO help my business rank in nearby searches?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Of Course, local SEO is the best way to increase your business ranking in nearby\n" +
                            "                                searches. This helps to increase visibility on the Google, that drives potential\n" +
                            "                                customers from the nearby region."
                    }
                },

                {
                    "@type": "Question",
                    "name": " How can keyword research improve my website's search visibility?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " Keywords plays a crucial role to improve the visibility of your business and content. The right keywords help your content rank on the Google and expend your reach to the  right audience. When anyone use the keyword for their search, the algorithm promotes your content to the viewer."
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle('Boost Traffic & Rankings with Volmint\'s Top SEO Services in India');
        this.meta.updateTag({
            name: 'description',
            content: 'Looking for the best SEO services in India? Volmint Technologies delivers proven strategies to increase traffic and maximize online growth.\n'
        });
        this.meta.updateTag({
            name: 'keyword',
            content: 'SEO Company In India'
        });
    }

}
