<section class="banner">
    <div class="container-fluid bg mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-12 col-lg-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56">
                            <span class="brand-primary-text-color">IMS- </span>
                            Streamline Your Institution's Administration Effectively</h1>
                        <p class="my-4 fs-6 text-secondary f-20 justify-text">Volmint’s Institute Management System
                            is created to enhance
                            the
                            efficiency of administrative tasks within educational institutions. With our software,
                            effortlessly manage student data, attendance, fee collection, academic records, and other
                            key
                            operations. Automate routine processes such as report generation, reminders, and meeting
                            scheduling, all through our comprehensive online Institute Management System.</p>
                        <button class="btn bg-white border-0 fw-bold px-5 rounded-0 hover-effect" (click)="openModal()">
                            View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/ims-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Banner Institution Management System" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/ims/ims.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row ">
            <div class="col-md-6 ps-md-5 py-5">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> Institute Management System</span></h2>
                <p class="my-2 fs-6 text-secondary justify-text">Institute Management Software is a web-based
                    tool that can help with
                    the administrative management chores and requirements of your institute. IMS organizes and unifies
                    the Institute's operational procedures and information flows to synchronize its resources—students,
                    staff, supplies, funds, and books—through information.
                    Volmint’s IMS integrates all key functions of your institute, including student management,
                    admissions, fee collection, attendance, and book inventory. It handles employee records, question
                    papers, salaries, expenses, and reporting. The system also manages library records, timetables, and
                    security features.
                </p>
                <button class="btn brand-primary-border bg-light-pink px-5 rounded-0 my-2 hover-white"
                        (click)="goTo('/contact')">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/ims/ims1.jpg" class="mt-2" alt="Institution Management System">
            </div>

        </div>


        <div class="row mt-5 g-5">
            <div class="col-md-6 text-right d-flex align-items-center">
                <img src="assets/img/landing-new/ims/ims-2.png" class="mt-2" alt="Institution Management System">
            </div>
            <div class="col-md-6">
                <h2 class="fs-1 fw-bolder">Explore Our Comprehensive
                    <span class="brand-primary-text-color">IMS Modules</span></h2>
                <p class="my-2 fs-6 text-secondary justify-text">Discover the full potential of Volmint
                    Technologies' Institute
                    Management System (IMS) with our robust suite of modules designed to streamline and elevate every
                    aspect of institutional administration.</p>
                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of modules">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="Icon" class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 py-5 mt-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Centralized Panel </span>To Manage Your Institute</h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">IMS software is an intuitive,
                    all-in-one cloud-based
                    software system that assists
                    you in managing your educational establishment efficiently and effectively.
                </p>
                <div class="row g-3">
                    <div class="col-sm-6 col-12 px-0 my-3"
                         *ngFor="let item of centralized">
                        <span>
                            <img src="assets/img/project/check.png" alt="Institution Management System" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5 mt-5 px-0 d-flex justify-content-md-end">
                <div>
                    <img src="assets/img/landing-new/ims/ims-3.png" class="mt-2 img-fluid" alt="Institution Management System">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container py-5">
        <h2 class="fs-1 text-white text-center mt-5 fw-bold">What Makes Our Institute Management System Stand Out</h2>
        <p class="text-white text-center text-secondary fw-bold justify-text">Volmint Technologies excels in delivering
            advanced solutions
            across sectors like healthcare, education, and finance. Our Institute Management System is user-friendly and
            customizable, designed to meet each institute’s unique needs. It not only monitors and performance reports
            but also enhances communication with parents and students. Optimize productivity and reduce costs with our
            efficient online software.</p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div
                    class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}
                </div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                    item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container py-5">
        <h2 class="text-center fs-1 mb-5 fw-bolder">
            A Simple, Yet
            <span class="brand-primary-text-color">Powerful & Efficient</span> Process</h2>
        <div class="row ">
            <div class="col-md-4">
                <div class=" text-center">
                    <img src="assets/img/projects/ims-card-1.png" alt="Institution Management System">
                </div>
            </div>
            <div class="col-md-4">
                <div class=" text-center">
                    <img src="assets/img/projects/ims-card-2.png" alt="Institution Management System">
                </div>
            </div>
            <div class="col-md-4">
                <div class=" text-center">
                    <img src="assets/img/projects/ims-card-3.png" alt="Institution Management System">
                </div>
            </div>
        </div>
    </div>
    <div class="container bg p-0 card border-0 shadow-lg">
        <div class="container">
            <div class="bg-light-pink opacity-50 row p-4">
                <div class="col-md-8">
                    <h2 class="fs-1">Want to boost growth of your Institutes?</h2>
                    <button class="btn bg-color-blue color-white fw-bold border px-5 rounded-0 my-5 fw-bold"
                            (click)="openModal()">View Demo <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                    </button>
                </div>
                <div class="col-md-4 d-flex justify-content-end">
                    <img src="assets/img/landing-new/ims/ims4.png" alt="Institution Management System" class="img-fluid"/>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row py-4 g-5">
            <div class="col-md-6 col-12">
                <h2 class="fs-1 mb-3 fw-bolder">
                    <span class="brand-primary-text-color">Key Features</span> Of Institute Management System</h2>
                <p class="fs-6 text-secondary justify-text">With IMS's unique features, Institutes can increase
                    production, save
                    administrative expenses, and give parents and students better services as a consequence.</p>


                <div class="d-flex justify-content-between flex-wrap g-2">
                    <div class="my-2  px-5 py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                         *ngFor="let item of features">
                       <h4 class="fs-5 fw-bold text-white">{{ item.text }}</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/ims/ims5.png" alt="Institution Management System" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container py-4">
        <h2 class="fs-1 mb-2 fw-bolder text-center">
            <span class="brand-primary-text-color">Perks</span> we Offer with our Institute Management System</h2>
        <p class="text-secondary fs-6 text-center justify-text">Experience a transformative approach to
            institute management with
            Volmint Technologies' IMS, designed to boost productivity and streamline your administrative processes.</p>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="row g-1">
                    <div class="col-md-4 col-12 p-4 mt-md-5 pt-md-5 d-flex justify-content-center"
                         *ngFor="let item of benefits">
                        <div class="card bg-white text-center py-2 fixed-width border-0 shadow-lg">
                               <span>
                            <img src="{{item.icon}}" alt="Institution Management System" class="img-fluid pt-4">
                        </span>
                            <span class="ms-2 p-2 text-secondary fw-bold">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>

                     <div class="w-50 text-center mx-auto">
                         <button class="btn view-demo brand-primary-bg text-white border-0 fw-bold px-5 rounded-0 " (click)="openModal()">
                             View Demo
                         </button>
                     </div>

                </div>
            </div>
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/ims/ims6.png" alt="Institution Management System" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row gx-5">
            <div class="col-md-7 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Complimentary Offerings </span> to Strengthen your IMS
                    Experience from Volmint Technologies
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Volmint Technology aims to empower
                    the future with the
                    power of technology by
                    streamlining the educational system through innovative convenience. In keeping with our mission and
                    vision, we are providing our customers and clients with additional services that enhance efficiency
                    and user experience.</p>

                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="Institution Management System" class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/IMS.jpg" class="img-fluid" alt="Institution Management System">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Institution Management System" class="img-fluid"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline primary-blue">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What features does Volmint’s Institute Management System include?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our IMS offers comprehensive modules for managing student data, admissions, fee
                                collection, attendance, academic records, and more. It also automates routine tasks like
                                report generation and scheduling, ensuring a smooth administrative workflow.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Is the IMS software accessible from any location?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, our IMS is cloud-based, allowing you to access it securely from any computer or
                                mobile device, anywhere with an internet connection.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does Volmint’s IMS enhance communication within the institution?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The IMS facilitates seamless communication with parents and students through integrated
                                messaging features and real-time performance reporting, keeping all stakeholders
                                informed and engaged.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What security measures are in place for the IMS?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our IMS is protected by advanced firewalls and secure servers, with robust cloud-based
                                technologies ensuring data security and reliability.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Are there any additional services or support provided with the IMS?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, we offer free lifetime maintenance, tailored assistance, regular updates, and
                                discounts on extra modules. Our support includes quick responses, training, and
                                integration of external tools to enhance your experience.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 ums-bottom-bg ps-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12">
                <h1 class="text-white">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-6 col-12 text-center">
                <span class="fs-4 fw-bold mb text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class="bg-light-pink shadow py-5 mt-2 px-4">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3" id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="bg-color-blue border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

