<ng-template #contactFormModal>
    <app-contact-form [isModalOpen]="isModalOpen" (closeModal)="closeModal()"></app-contact-form>
</ng-template>
<div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
    <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
</div>

<section class="banner" xmlns="http://www.w3.org/1999/html">
    <div class="container-fluid bg-pink mt-5">
        <div class="container mt-2 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">Enroll-</span>
                            Cutting-Edge Technology, Effortless Admissions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap justify-text f-20">Enroll, an admission
                            management system
                            designed to
                            streamline and elevate your enrollment
                            experience with cutting-edge technology. Discover features like multi-institution
                            management,
                            session-wise tracking, real-time data sharing, comprehensive financial management, social
                            media integration, intuitive lead management, IVR integration, and in-depth reporting.
                        </p>
                        <a href="https://enroll.volmint.com/">
                            <button class="btn bg-white border-0 shadow-lg px-5 rounded-0 fw-bold hover-effect">View
                                Demo <i
                                    class="ms-3 fa-solid fa-arrow-right"></i>
                            </button>
                        </a>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/enroll-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Enroll- Admission Management System" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/enroll/enroll.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row ">
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/enroll/enroll1.png" class="mt-2" alt="Admission Management System">
            </div>
            <div class="col-md-6 ps-md-5 py-5">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> Admission Management System</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint Technologies is a leading
                    innovator,
                    focusing on empowering institutions to streamline and elevate their admission processes with their
                    flagship product, ‘Enroll.’ It is a comprehensive platform that simplifies the enrollment process
                    from application to admission, making it easier for institutions to manage student data, track
                    progress, and ensure a smooth experience for applicants. Our team of visionary developers and
                    industry experts collaborates tirelessly to ensure that ‘Enroll’ not only meets but exceeds the
                    expectations of our clients to manage admission flawlessly.
                </p>
                <img src="assets/img/project/cms-two.png" alt="Admission Management System">
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">Streamline Admission Processes with the Power of
                    <span class="brand-primary-text-color">Enroll</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">Admission Management System (AMS) is
                    created to
                    automate and simplify the process of managing student admissions into educational institutions. Here
                    are the main features of the software.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none mt-4">
                    <div
                        class="my-2 px-5 py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                        *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 " *ngFor="let item of powerfullToUse">
                        <img src="assets/img/project/check.png"
                             alt="Admission Management System"
                             class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/project/ums-2.png" class="mt-2" alt="Admission Management System">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row  g-5">
            <div class="col-md-6 pb-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features</span> Admission Management System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">An Admission management system has
                    countless
                    collaborative tools for managing admissions. It automates several processes, reduces the manual
                    workload, and improves the admission experience.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2 "
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width border-0 shadow-lg">
                               <span>
                            <img src="{{item.icon}}" alt="Admission Management System" class="img-fluid">
                        </span>
                            <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 pe-0  d-flex align-items-center">
                <img src="assets/img/landing-new/enroll/enroll2.png" alt="Admission Management System" class=""/>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row g-5">
            <div class="col-md-6 py-5">
                <h2 class="fs-1 fw-bolder">
                    Manage <span class="brand-primary-text-color">Multiple Admissions </span> From Single Dashboard</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Admission management system is an
                    automated
                    software in
                    which you could perform various operations from a single dashboard like adding multiple institutions
                    and also manage the admissions for them. Even, the leads could also be retrieved and managed through
                    our software and many other functionalities also.</p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-3 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-end text-right">
                <img src="assets/img/landing-new/enroll/enroll3.gif" class=" " alt="Admission Management System">
            </div>
            <div class="col-md-6   text-right">
                <img src="assets/img/project/cgms-5.png" class="ms-2 mt-2 " alt="">
            </div>
            <div class="col-md-6 ps-md-5">
                <h2 class=" fs-1 fw-bolder">
                    Why Do You Need <span class="brand-primary-text-color">Enroll</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Admission Management System is a
                    must-have
                    software to run an organization smoothly due to the various advantages and features it provides. It
                    helps to streamline the admission and enrollment process effectively along with lead management,
                    accounts management, and document management. </p>
                <!--                <div class="row g-3 mt-5 ">-->
                <!--                    <div class="col-sm-6 col-12 px-0 my-3 "-->
                <!--                         *ngFor="let item of offerings">-->
                <!--                        <span>-->
                <!--                            <img src="assets/img/project/check.png" alt="Admission Management System" class="img-fluid">-->
                <!--                        </span>-->
                <!--                        <span class="ms-2 text-secondary">-->
                <!--                            {{ item.text }}-->
                <!--                        </span>-->
                <!--                    </div>-->
                <!--                </div>-->

                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">
        <img src="assets/img/project/check.png" alt="Icon" class="img-fluid">
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h2 class="text-white fs-1 text-center mt-5 fw-bold">What Makes Our Admission Management System Stand Out</h2>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <p class="text-white text-center text-wrap fw-bold justify-text">Volmint's Enroll offers several
                    advanced
                    features
                    including a
                    secured platform, advanced analytics, and powerful automation. The software offers online
                    application management, document management, applicant tracking system, user management, analytics
                    and reporting, and communication management.
                </p>
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div
                    class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}
                </div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Admission Management System"
                         class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #6752A9;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is Volmint’s Enroll?</span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint’s Enroll is an Admission Management System, that offers comprehensive admissions
                                strategy services, including admission management, social media lead handling, and
                                integrated sales processes, tailored to meet institutional needs with excellence and
                                precision.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How does Enroll work?</span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                It automates application submission, document management, and communication, ensuring
                                efficiency and transparency. With intuitive features for administrators, applicants, and
                                reviewers, Enroll simplifies complex workflows, enhances collaboration, and provides
                                real-time insights to optimize admissions decisions.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Is Enroll safe for data security?</span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely. Enroll prioritizes data security with robust encryption protocols and secure
                                cloud infrastructure. We adhere to industry standards to protect sensitive information
                                throughout the admission process, ensuring confidentiality, integrity, and peace of mind
                                for all users.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div>  What are the features of Enroll?</span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Enroll offers several advanced features including a secured platform, advanced
                                analytics, and powerful automation. the software offers online application management,
                                document management, applicant tracking system, user management, analytics and
                                reporting, and communication management.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #6752A9;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Can we customize the product/service offered by Enroll?</span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, you can choose among the various plans and get customization as per your
                                requirements. Being one of the leading IT software companies, we are committed to
                                providing the best solution to our customers at the best price.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12 fs-2 color-white fw-bold mb-3 mb-md-0">
                Ready to elevate your university management to next level?
            </div>
            <div class="col-md-6 col-12 text-center">
                      <span class="fs-4 fw-bold  text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class="bg-light-pink shadow py-5 px-4">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3"
                                   id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="bg-color-blue border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
